.service-provider-profile-detail-box {
  display: flex;
  flex-direction: column;
  //   width: 75rem;

  .bottom-box {
    padding-top: 3rem;
    margin-top: 2rem;
    border-top: 0.1rem solid rgba(69, 93, 95, 0.25);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 2rem;

    .box {
      display: flex;
      flex-direction: column;
      font-size: 1.25rem;

      .light {
        opacity: 0.5;
        margin-bottom: 0.5rem;
      }

      .bold {
        font-weight: 700;
      }
    }
  }

  .middle-box {
    display: flex;
    margin: 4rem 0rem;

    .box {
      display: flex;
      flex-direction: column;
      font-size: 1.25rem;

      .light {
        opacity: 0.5;
        margin-bottom: 0.5rem;
      }

      .bold {
        font-weight: 700;
        font-size: 1.2rem;
      }

      .green {
        color: #00c013;
      }
    }

    .left-box {
      flex: 0 0 55%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 3rem;
      border-right: unset;
    }

    .right-box {
      flex: 1;
      .box {
        & > *:not(:last-child) {
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  .top-box {
    display: flex;
    align-items: center;

    .img-wrap {
      width: 15rem;
      height: 15rem;
      border-radius: 50%;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 3rem;
    }

    .text-wrap {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 3rem;

      .rate-clinic-box {
        display: flex;
        align-items: center;

        .clinic {
          padding: 0.7rem 1.5rem;
          border-radius: 2rem;
          background-color: rgba(255, 167, 44, 0.15);
          margin-right: 1.5rem;
          color: #ffa72c;
          font-weight: 700;
          font-size: 1.25rem;
        }

        .rate-box {
          display: flex;
          align-items: center;

          .text {
            font-size: 1.3rem;
            font-weight: 700;
          }
        }
      }

      .box {
        display: flex;
        flex-direction: column;
        font-size: 1.25rem;

        .light {
          opacity: 0.5;
          margin-bottom: 0.5rem;
        }

        .bold {
          font-weight: 700;
        }
      }
    }
  }
}

.service-provider-document-wrap-box {
  height: 50rem;
  width: 100%;
  overflow-y: scroll;

  .documents-box{
    display: flex;
    flex-direction: column;

    &>*:not(:last-child){
        margin-bottom: 3rem;
        padding-bottom: 2rem;
        border-bottom: 0.1rem solid rgba(69, 93, 95, 0.25);
    }

    .child-wrap{
    display: flex;
    flex-direction: column;

    .name{
        margin-bottom: 1rem;
        font-size: 1.3rem;
    }

    .bottom-box{
        display: grid;
        grid-template-columns: max-content 1fr;
        grid-column-gap: 3rem;

        .img-wrap{
            width: 25rem;
            height: 25rem;
            background-position: left center;
            background-size: contain;
            background-repeat: no-repeat;
            border-radius: 1rem;
        }

        .right-box{
            // flex: 1;
            display: flex;
            flex-direction: column;

            &>*:not(:last-child){
                margin-bottom: 2rem
            }

            .btn-box{
                display: flex;
                align-items: center;
                margin-top: auto;
                margin-bottom: auto;

                .btn-one{
                    margin-right: 3rem;
                }
            }

            .box{
                font-size: 1.2rem;

                .light{
                    color: #424242;
                    margin-bottom: .7rem;
                    opacity: .5;
                }

                .bold{
                    font-weight: 700;
                }

                .green{
                    color: #00C013;
                }

                .red{
                    color: #DE0B0B;
                }

                .yellow{
                    color: #FFA72C;
                }
            }
        }
    }
    }
  }

}
