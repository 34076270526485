.auth-layout-wrap {
  width: 100vw;
  min-height: 100vh;
  display: flex;
}
.auth-layout-wrap .left-box {
  flex: 0 0 45%;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.auth-layout-wrap .left-box .yellow-circle {
  top: 0;
  left: 0;
  width: 40rem;
  height: 40rem;
  border-radius: 50%;
  background-color: var(--dark-yellow-color);
  opacity: 0.15;
  filter: blur(9rem);
  transform: translateX(-10%);
}
.auth-layout-wrap .left-box .blue-circle {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40rem;
  height: 40rem;
  border-radius: 50%;
  background-color: #84bfcd;
  opacity: 0.25;
  filter: blur(5rem);
  transform: translateX(-10%);
}
.auth-layout-wrap .left-box .content-box {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-53%);
  width: 70%;
  height: 75%;
  display: flex;
  flex-direction: column;
}
.auth-layout-wrap .left-box .content-box .logo-text-box {
  padding-bottom: 1.5rem;
  display: flex;
  align-items: center;
}
.auth-layout-wrap .left-box .content-box .logo-text-box .img-box {
  width: 10rem;
  height: 3rem;
  margin-right: 2rem;
}
.auth-layout-wrap .left-box .content-box .logo-text-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.auth-layout-wrap .left-box .content-box .logo-text-box .text {
  background-color: rgba(69, 93, 95, 0.25);
  padding: 0.5rem 1rem;
  border-radius: 0.7rem;
  font-weight: 700;
  font-size: 1.2rem;
  text-align: center;
  color: #455d5f;
  transform: translateY(0.4rem);
}
.auth-layout-wrap .left-box .content-box .img-bg-box {
  flex: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  box-shadow: var(--box-shadow-two);
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.auth-layout-wrap .left-box .content-box-slide {
  animation: moveLeft 1s linear backwards;
}
.auth-layout-wrap .right-box {
  flex: 1;
  position: relative;
  z-index: 2;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  display: grid;
  place-items: center;
}
.auth-layout-wrap .right-box .content-box {
  width: 50%;
  min-height: 50%;
  display: flex;
  flex-direction: column;
}
.auth-layout-wrap .right-box .content-box .title {
  font-size: 3rem;
  font-weight: 700;
  color: var(--black-color);
  text-transform: capitalize;
  margin-bottom: 1rem;
}
.auth-layout-wrap .right-box .content-box .big-text {
  font-size: 1.4rem;
  color: var(--black-color);
  margin-bottom: 0.8rem;
}
.auth-layout-wrap .right-box .content-box .big-text span {
  font-weight: 700;
  margin: 0rem 0.3rem;
}
.auth-layout-wrap .right-box .content-box .small-text {
  font-size: 1.3rem;
  font-weight: 300;
  opacity: 0.5;
}

@keyframes moveLeft {
  0% {
    transform: translateY(-53%) translateX(100%);
  }
  100% {
    transform: translateY(-53%) translateX(0%);
  }
}/*# sourceMappingURL=AuthLayout.css.map */