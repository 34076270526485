.user-admin-wrap-box{

    .shop-common-header-wrap {
        // height: 6rem;
        // background-color: red;
        margin-top: 2rem;
        margin-bottom: 2rem;
        display: flex;
        align-items: center;
      
        .link-box{
          flex: 0 0 50%;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          // justify-items: center;
      
      
          .child{
              color: #BBBBBB;
              font-size: 1.35rem;
              text-align: center;
              padding-bottom: 1.5rem;
              border-bottom: .1rem solid #BBBBBB;
              cursor: pointer;
      
              span{
                  &:hover{
                      color: var(--dark-yellow-color);
                  }
              }
          }
      
          .child-active{
              color: var(--dark-yellow-color);
              font-weight: 700;
              position: relative;
      
              &::after{
                  content: "";
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 100%;
                  height: .35rem;
                  background-color: var(--dark-yellow-color);
                  border-radius: 1rem;
                  transform: translateY(50%);
              }
          }
        }
      
        .btn-search-box {
          margin-left: auto;
          display: flex;
          align-items: center;
      
          .form-group {
            display: flex;
            align-items: center;
            border: 0.1rem solid #BBBBBB;
            border-radius: 3rem;
            padding: 0.5rem 1rem;
            color: #BBBBBB;
            margin-right: 3rem;
      
            .img-box{
              // margin-right: 1rem;
              width: 2rem;
              height: 2rem;
              cursor: pointer;
      
              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                display: block;
              }
           }
      
      
            .input {
              border: none;
              outline: none;
              padding: .7rem 1.2rem;
             
              &::placeholder{
                  color: #BBBBBB;
              }
      
              &:focus{
                  border: none;
                  outline: none;
              }
            }
          }
      
          .btn-wrap{
               .img-box{
                  margin-right: 1rem;
                  width: 2rem;
                  height: 2rem;
                  cursor: pointer;
      
                  .img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    display: block;
                  }
               }
          }
        }
      }
      
}