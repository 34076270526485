.dashboard-home-wrap {
  width: 100%;
  height: calc(100vh - 9rem);
  display: flex;
  position: relative;
  padding-right: 0rem !important;
  padding-bottom: 0rem !important;
}
.dashboard-home-wrap .left-box {
  flex: 0 0 70%;
  margin-right: 4rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding-bottom: 5rem;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.dashboard-home-wrap .left-box > *:not(:last-child) {
  margin-bottom: 3rem;
}
.dashboard-home-wrap .left-box::-webkit-scrollbar {
  display: none;
}
.dashboard-home-wrap .left-box .card-wrap-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 4rem;
}
.dashboard-home-wrap .left-box .card-wrap-box .card {
  min-height: 22rem;
  border-radius: 1.5rem;
  background-repeat: no-repeat;
  background-position: right 8.5rem;
  background-size: 60%;
  color: #ffffff;
}
.dashboard-home-wrap .left-box .card-wrap-box .card .wrap {
  width: 100%;
  height: 100%;
  padding: 2rem 3rem;
  position: relative;
  display: flex;
  flex-direction: column;
}
.dashboard-home-wrap .left-box .card-wrap-box .card .value {
  font-size: 3.2vw;
  font-weight: 700;
}
.dashboard-home-wrap .left-box .card-wrap-box .card .label {
  font-size: 1.6rem;
}
.dashboard-home-wrap .left-box .card-wrap-box .card .btn {
  margin-top: auto;
  align-self: flex-start;
  padding: 0.9rem 1.1rem;
  border-radius: 1.5rem;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #ffffff;
  justify-content: center;
  transform: translateX(-1rem);
}
.dashboard-home-wrap .left-box .card-wrap-box .card .btn:hover .icon {
  transform: translateX(0.5rem);
}
.dashboard-home-wrap .left-box .card-wrap-box .card .btn span {
  font-size: 1.25rem;
}
.dashboard-home-wrap .left-box .card-wrap-box .card .btn .icon {
  width: 1.2rem;
  height: 1.2rem;
  transition: all 0.5s;
  margin-left: 0.7rem;
}
.dashboard-home-wrap .left-box .card-wrap-box .green {
  background-color: #a7e888;
  background-image: url("../../../assets/home-wallet-balance-bg-img.png");
  background-position: right 10rem;
}
.dashboard-home-wrap .left-box .card-wrap-box .green .wrap .btn {
  background-color: #5c9f3b;
}
.dashboard-home-wrap .left-box .card-wrap-box .blue {
  background-color: #abc3f3;
  background-image: url("../../../assets/home-service-provider-bg-img.png");
  background-size: 75%;
  background-position: right 10.7rem;
}
.dashboard-home-wrap .left-box .card-wrap-box .blue .wrap .btn {
  background-color: #3d80ff;
}
.dashboard-home-wrap .left-box .card-wrap-box .purple {
  background-color: #b398ff;
  background-image: url("../../../assets/home-pet-bg-img.png");
}
.dashboard-home-wrap .left-box .card-wrap-box .purple .wrap .btn {
  background-color: #7849fe;
}
.dashboard-home-wrap .left-box .appointment-stat-box {
  display: flex;
  flex-direction: column;
  padding-right: 0.5rem;
  margin-top: 3rem;
}
.dashboard-home-wrap .left-box .appointment-stat-box .appointment-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}
.dashboard-home-wrap .left-box .appointment-stat-box .chart-detail-box {
  height: 30rem;
  width: 100%;
  display: flex;
  padding-left: 0.5rem;
}
.dashboard-home-wrap .left-box .appointment-stat-box .chart-detail-box .chart-wrap {
  flex: 0 0 80%;
  background-color: #ffffff;
  box-shadow: 0.1rem 0rem 2rem 0.2rem rgba(0, 0, 0, 0.1);
  border-radius: 1.5rem;
  margin-right: 3rem;
  padding: 1rem 0rem;
  background-image: url("../../../assets/chart-line-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.dashboard-home-wrap .left-box .appointment-stat-box .chart-detail-box .detail-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dashboard-home-wrap .left-box .appointment-stat-box .chart-detail-box .detail-wrap .select-input > *:nth-child(3) {
  box-shadow: 0rem 0.4rem 1rem rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  border-radius: 0.6rem;
  cursor: pointer;
}
.dashboard-home-wrap .left-box .appointment-stat-box .chart-detail-box .detail-wrap .box {
  color: #424242;
  text-align: center;
}
.dashboard-home-wrap .left-box .appointment-stat-box .chart-detail-box .detail-wrap .box .bold {
  font-weight: 700;
  font-size: 3.5rem;
  margin-bottom: 0.7rem;
}
.dashboard-home-wrap .left-box .appointment-stat-box .chart-detail-box .detail-wrap .box .light {
  opacity: 0.45;
  font-size: 1.6rem;
}
.dashboard-home-wrap .left-box .service-transaction-box {
  display: flex;
  margin-top: 3rem;
  align-items: stretch;
}
.dashboard-home-wrap .left-box .service-transaction-box .service-box {
  flex: 0 0 50%;
  margin-right: 3rem;
  display: flex;
  flex-direction: column;
}
.dashboard-home-wrap .left-box .service-transaction-box .service-box .service-title {
  color: #424242;
  font-size: 1.5rem;
  font-weight: 700;
}
.dashboard-home-wrap .left-box .service-transaction-box .service-box .see-all-request-box {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  align-self: center;
  color: #424242;
  cursor: pointer;
}
.dashboard-home-wrap .left-box .service-transaction-box .service-box .see-all-request-box:hover .icon {
  transform: translateX(0.7rem);
}
.dashboard-home-wrap .left-box .service-transaction-box .service-box .see-all-request-box .text {
  font-size: 1.35rem;
  color: #424242;
}
.dashboard-home-wrap .left-box .service-transaction-box .service-box .see-all-request-box .text span {
  font-weight: 700;
}
.dashboard-home-wrap .left-box .service-transaction-box .service-box .see-all-request-box .icon {
  margin-left: 0.4rem;
  width: 1.5rem;
  height: 1.5rem;
  transition: all 0.4s;
}
.dashboard-home-wrap .left-box .service-transaction-box .service-box .detail-box {
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
  background-color: #f5f7f7;
  border-radius: 1.5rem;
  padding: 3rem 2.5rem;
  flex: 1;
}
.dashboard-home-wrap .left-box .service-transaction-box .service-box .detail-box > *:not(:last-child) {
  margin-bottom: 2.5rem;
}
.dashboard-home-wrap .left-box .service-transaction-box .service-box .detail-box .card-box {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  border-radius: 1rem;
}
.dashboard-home-wrap .left-box .service-transaction-box .service-box .detail-box .card-box .name-text-box {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}
.dashboard-home-wrap .left-box .service-transaction-box .service-box .detail-box .card-box .name-text-box > *:not(:last-child) {
  margin-bottom: 0.8rem;
}
.dashboard-home-wrap .left-box .service-transaction-box .service-box .detail-box .card-box .name-text-box .name {
  font-weight: 700;
  font-size: 1.55rem;
}
.dashboard-home-wrap .left-box .service-transaction-box .service-box .detail-box .card-box .name-text-box .appointment {
  font-size: 1.25rem;
}
.dashboard-home-wrap .left-box .service-transaction-box .service-box .detail-box .card-box .name-text-box .appointment span {
  font-weight: 700;
}
.dashboard-home-wrap .left-box .service-transaction-box .service-box .detail-box .card-box .rate-stat-box {
  margin-left: auto;
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.dashboard-home-wrap .left-box .service-transaction-box .service-box .detail-box .card-box .rate-stat-box .rate-box {
  display: flex;
  align-items: center;
}
.dashboard-home-wrap .left-box .service-transaction-box .service-box .detail-box .card-box .rate-stat-box .rate-box span {
  font-weight: 700;
  font-size: 1.25rem;
  margin-left: 0.4rem;
  display: inline-block;
}
.dashboard-home-wrap .left-box .service-transaction-box .service-box .detail-box .card-box .rate-stat-box .status-box {
  margin-bottom: auto;
  padding: 0.8rem 1rem;
  border-radius: 0.6rem;
  background-color: rgba(0, 192, 19, 0.15);
  color: #00c013;
  font-weight: 700;
  font-size: 1rem;
  display: grid;
  place-items: center;
}
.dashboard-home-wrap .left-box .service-transaction-box .service-box .detail-box .card-box .rate-stat-box .status-box .status {
  text-transform: capitalize;
}
.dashboard-home-wrap .left-box .service-transaction-box .service-box .detail-box .card-box .rate-stat-box .status-busy {
  background-color: rgba(222, 11, 11, 0.15);
  color: #de0b0b;
}
.dashboard-home-wrap .left-box .service-transaction-box .service-box .detail-box .card-box .img-wrap {
  margin-right: 1rem;
}
.dashboard-home-wrap .left-box .service-transaction-box .service-box .detail-box .card-box .img-wrap .img-box {
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 50%;
  overflow: hidden;
}
.dashboard-home-wrap .left-box .service-transaction-box .transaction-box {
  flex: 1;
}
.dashboard-home-wrap .left-box .service-transaction-box .transaction-box .transaction-title {
  color: #424242;
  font-size: 1.5rem;
  font-weight: 700;
}
.dashboard-home-wrap .left-box .service-transaction-box .transaction-box .detail-box {
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
  background-color: #f5f7f7;
  border-radius: 1.5rem;
  padding: 3rem 2.5rem;
}
.dashboard-home-wrap .left-box .service-transaction-box .transaction-box .detail-box > *:not(:last-child) {
  margin-bottom: 2.5rem;
}
.dashboard-home-wrap .left-box .service-transaction-box .transaction-box .detail-box .see-all-request-box {
  display: flex;
  align-items: center;
  margin-top: 3rem;
  align-self: center;
  color: #424242;
  cursor: pointer;
}
.dashboard-home-wrap .left-box .service-transaction-box .transaction-box .detail-box .see-all-request-box:hover .icon {
  transform: translateX(0.7rem);
}
.dashboard-home-wrap .left-box .service-transaction-box .transaction-box .detail-box .see-all-request-box .text {
  font-size: 1.5rem;
  color: #424242;
}
.dashboard-home-wrap .left-box .service-transaction-box .transaction-box .detail-box .see-all-request-box .text span {
  font-weight: 700;
}
.dashboard-home-wrap .left-box .service-transaction-box .transaction-box .detail-box .see-all-request-box .icon {
  margin-left: 0.4rem;
  width: 1.5rem;
  height: 1.5rem;
  transition: all 0.4s;
}
.dashboard-home-wrap .left-box .service-transaction-box .transaction-box .detail-box .card-wrap {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  border-radius: 1rem;
}
.dashboard-home-wrap .left-box .service-transaction-box .transaction-box .detail-box .card-wrap .status-view-box {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1.2rem;
}
.dashboard-home-wrap .left-box .service-transaction-box .transaction-box .detail-box .card-wrap .status-view-box .view {
  cursor: pointer;
  padding: 0.8rem 1rem;
  border-radius: 0.6rem;
  background-color: rgba(69, 93, 95, 0.15);
  color: #455d5f;
  font-weight: 700;
  font-size: 1.15rem;
  display: grid;
  place-items: center;
  transition: all 0.3s;
}
.dashboard-home-wrap .left-box .service-transaction-box .transaction-box .detail-box .card-wrap .status-view-box .view:hover {
  transform: translateY(-0.1rem);
}
.dashboard-home-wrap .left-box .service-transaction-box .transaction-box .detail-box .card-wrap .status-view-box .view p {
  text-transform: capitalize;
}
.dashboard-home-wrap .left-box .service-transaction-box .transaction-box .detail-box .card-wrap .status-view-box .status {
  margin-bottom: auto;
  padding: 0.8rem 1rem;
  border-radius: 0.6rem;
  background-color: rgba(0, 192, 19, 0.15);
  color: #00c013;
  font-weight: 700;
  font-size: 1rem;
  display: grid;
  place-items: center;
}
.dashboard-home-wrap .left-box .service-transaction-box .transaction-box .detail-box .card-wrap .status-view-box .status .stat {
  text-transform: capitalize;
}
.dashboard-home-wrap .left-box .service-transaction-box .transaction-box .detail-box .card-wrap .status-view-box .status-debit {
  background-color: rgba(222, 11, 11, 0.15);
  color: #de0b0b;
}
.dashboard-home-wrap .left-box .service-transaction-box .transaction-box .detail-box .card-wrap .text-box {
  display: flex;
  flex-direction: column;
  margin-right: auto;
}
.dashboard-home-wrap .left-box .service-transaction-box .transaction-box .detail-box .card-wrap .text-box > *:not(:last-child) {
  margin-bottom: 1rem;
}
.dashboard-home-wrap .left-box .service-transaction-box .transaction-box .detail-box .card-wrap .text-box p {
  font-size: 1rem;
  color: #424242;
}
.dashboard-home-wrap .left-box .service-transaction-box .transaction-box .detail-box .card-wrap .text-box p span {
  font-weight: 700;
}
.dashboard-home-wrap .right-box {
  flex: 1;
  position: relative;
}
.dashboard-home-wrap .right-box .content-box {
  width: 100%;
  position: absolute;
  background-color: rgba(69, 93, 95, 0.05);
  border-top-left-radius: 2rem;
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
}
.dashboard-home-wrap .right-box .content-title {
  font-size: 1.65rem;
  font-weight: 700;
  color: #424242;
}
.dashboard-home-wrap .right-box .see-all-request-box {
  display: flex;
  align-items: center;
  margin-top: 3rem;
  align-self: center;
  color: #424242;
  cursor: pointer;
}
.dashboard-home-wrap .right-box .see-all-request-box:hover .icon {
  transform: translateX(0.7rem);
}
.dashboard-home-wrap .right-box .see-all-request-box .text {
  font-size: 1.5rem;
  color: #424242;
}
.dashboard-home-wrap .right-box .see-all-request-box .text span {
  font-weight: 700;
}
.dashboard-home-wrap .right-box .see-all-request-box .icon {
  margin-left: 0.4rem;
  width: 1.5rem;
  height: 1.5rem;
  transition: all 0.4s;
}
.dashboard-home-wrap .right-box .detail-wrap-box {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2.5rem;
  margin-top: 3.5rem;
}
.dashboard-home-wrap .right-box .detail-wrap-box .card-box {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 3rem 2rem;
  padding-bottom: 1.5rem;
  color: #424242;
}
.dashboard-home-wrap .right-box .detail-wrap-box .card-box .btn {
  align-self: center;
  padding: 0.8rem 1.3rem;
  border-radius: 1rem;
  border: none;
  outline: none;
  color: #00c013;
  background-color: rgba(0, 192, 19, 0.15);
  cursor: pointer;
  font-size: 1.3rem;
  margin-top: 2rem;
  transition: all 0.3s;
}
.dashboard-home-wrap .right-box .detail-wrap-box .card-box .btn:hover {
  transform: translateY(-0.2rem);
}
.dashboard-home-wrap .right-box .detail-wrap-box .card-box .date {
  font-size: 1.25rem;
  color: #424242;
  opacity: 0.45;
  margin-bottom: 1.5rem;
}
.dashboard-home-wrap .right-box .detail-wrap-box .card-box .clinic-name {
  margin-bottom: 1.5rem;
  font-size: 1.3rem;
}
.dashboard-home-wrap .right-box .detail-wrap-box .card-box .amount {
  font-weight: 700;
  font-size: 1.6rem;
  margin-bottom: 1.4rem;
}
.dashboard-home-wrap .right-box .detail-wrap-box .card-box .underline {
  width: 100%;
  height: 0.1rem;
  background-color: rgba(66, 66, 66, 0.25);
  margin-bottom: 1.5rem;
}
.dashboard-home-wrap .right-box .detail-wrap-box .card-box .bank-details {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  margin-bottom: 0.7rem;
}
.dashboard-home-wrap .right-box .detail-wrap-box .card-box .bank-details .bank-num {
  opacity: 0.45;
}
.dashboard-home-wrap .right-box .detail-wrap-box .card-box .bank-details .bank-name {
  margin-right: 1rem;
}
.dashboard-home-wrap .right-box .detail-wrap-box .card-box .small-name {
  opacity: 0.45;
  font-size: 1.25rem;
}/*# sourceMappingURL=DashboardHome.css.map */