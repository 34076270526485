.deworm-vaccination-diet-wrap {
  min-width: 65rem;
  min-height: 45rem;
  background-color: #ffffff;
  position: relative;
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  padding-bottom: 5rem;

  .close-icon {
    position: absolute;
    top: 2rem;
    right: 2rem;
    // background-color: red;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 0.1rem solid var(--black-color);
    display: grid;
    place-items: center;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      transform: scale(1.04);
    }

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .modal-title {
    margin-bottom: 3rem;
    align-self: center;
    font-size: 1.6rem;
  }

  .no-content-box {
    width: 100%;
    display: grid;
    place-items: center;

    .wrap {
      display: flex;
      flex-direction: column;
      align-items: center;

      .img-box {
        width: 15rem;
        height: 15rem;
      }

      .text {
        color: #bbbbbb;
        font-size: 1.3rem;
        font-weight: 500;
      }
    }
  }

  .content-box {
    min-height: 25rem;
    width: 75%;
    // background-color: red;
    flex-direction: column;
    display: flex;
    margin: 0rem auto;

    .small-title {
      align-self: center;
      color: #bbbbbb;
      font-weight: 500;
      margin-bottom: 3rem;
    }

    .row-wrap {
      flex-direction: column;
      display: flex;
      width: 100%;

      & > *:not(:last-child) {
        border-bottom: 0.1rem solid rgba(66, 66, 66, 0.25);
      }

      .row {
        display: flex;
        align-items: center;
        padding: 2rem 0rem;
        justify-content: space-between;
        font-size: 1.25rem;
      }
    }

    .bottom-box {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 3rem;

      .box {
        display: flex;
        flex-direction: column;
        font-size: 1.25rem;

        .light {
          opacity: 0.5;
          margin-bottom: 0.5rem;
        }

        .bold {
          font-weight: 700;
        }
      }
    }

    .top-box {
      display: grid;
      grid-template-columns: 3fr 2fr;
      grid-gap: 3rem;
      margin-bottom: 3rem;
      padding-bottom: 2rem;
      border-bottom: 0.1rem solid rgba(66, 66, 66, 0.25);

      .box {
        display: flex;
        flex-direction: column;
        font-size: 1.25rem;

        .light {
          opacity: 0.5;
          margin-bottom: 0.5rem;
        }

        .bold {
          font-weight: 700;
        }
      }
    }
  }

  .btn-reuse {
    margin-top: 5rem;
    align-self: center;
  }
}
