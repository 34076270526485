.create-blog-post-wrap {
  display: flex;
  flex-direction: column;
  padding-bottom: 7rem;

  .title-back-box {
    display: flex;
    align-self: flex-start;
    align-items: center;
    color: #bbbbbb;
    cursor: pointer;
    margin-bottom: 2rem;

    .text {
      font-size: 1.6rem;
    }

    // .icon{

    // }

    .text-active {
      color: #424242;
      font-weight: 500;
      margin-left: 0.5rem;
    }
  }

  .blog-detail-box {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-column-gap: 2rem;
    align-items: center;
    width: 70%;
    margin: 2rem 0rem 4rem 0rem;

    .text {
      color: #424242;
      opacity: 0.75;
    }

    span {
      display: inline-block;
      height: 0.1rem;
      background-color: #455d5f;
      opacity: 0.25;
    }
  }

  .form {
    width: 65%;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 75em){
        width: 80%;
    }

    & > *:not(:last-child) {
      margin-bottom: 2rem;
    }

    .quill-editor{
        width: 100% !important;
        height: 35rem !important;

        &>*:nth-child(2){
            border-radius: 1rem !important;
            border-top-left-radius: unset !important;
            border-top-right-radius: unset !important;
            background-color: #f5f7f7 !important;
        }

        &>*:first-child{
            border-top-left-radius: 1rem !important;
            border-top-right-radius: 1rem !important;
        }
    }

    .btn{
        margin-top: 7rem;
        align-self: flex-start;
    }

    .dna-wrapper{
        margin-top: 7rem;
        align-self: flex-start;
    }

    .form-submit{
        background-color: #F5F7F7;
        padding: 1.3rem 3.5rem;
        border: none;
        outline: none;
        border-radius: .7rem;
         color: var(--black-color);
         cursor: pointer;
         transition: all 0.3s;
         display: flex;
         align-items: center;
         justify-content: center;
         text-transform: capitalize;
    }

    .form-submit-active{
        background-color: #84BFCD;
        color: var(--white-color);
       
        &:hover {
          transform: translateY(-0.12rem);
        }
    }

    .two-groups {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 3rem;
      grid-row-gap: 3rem;
    }

    .form-group {
      display: flex;
      flex-direction: column;

      .form-label {
        font-size: 1.45rem;
        color: #424242;
        opacity: 0.75;
        margin-bottom: 1rem;
      }

      .input-group {
        padding: 0.5rem 0.7rem;
        border-radius: 0.9rem;
        border: 0.1rem solid #bbbbbb;
        display: flex;
        align-items: center;
        width: 100%;

        .img-article{
            margin-right: 1rem;
        }

        .input-border {
          // display: none !important;
          flex: 1;

          & > *:nth-child(3) {
            background-color: #f5f7f7 !important;
            border-radius: 0.6rem !important;
            border: none !important;

            & > *:nth-child(2) {
              display: none;
            }

            // &>*:nth-child(3){
            //     border: none !important;

            //     & > *:nth-child(2) {
            //         display: none;
            //       }
            // }
          }

          & > * {
            border: none !important;
          }
        }

        .img-wrap {
          flex: 0 0 10%;
          display: grid;
          place-items: center;
          // background-color: red;

          .img-box {
            width: 1.3rem;
            height: 1.3rem;
            cursor: pointer;
          }
        }

        .form-input {
          background-color: #f5f7f7;
          padding: 1.3rem;
          border-radius: 0.6rem;
          border: none;
          outline: none;
          flex: 1;

          &:focus {
            border: none;
            outline: none;
          }
        }
      }
    }
  }
}


.view-blog-article{
    .title-back-box {
        display: flex;
        align-self: flex-start;
        align-items: center;
        color: #bbbbbb;
        cursor: pointer;
        margin-bottom: 2rem;
    
        .text {
          font-size: 1.6rem;
        }
    
        // .icon{
    
        // }
    
        .text-active {
          color: #424242;
          font-weight: 500;
          margin-left: 0.5rem;
        }
      }

      .content-box{
        display: flex;
        padding-bottom: 6rem;

        .left-box{
            flex: 0 0 65%;
            border-right: .1rem solid rgba(69, 93, 95, .25);
            display: flex;
            flex-direction: column;
            padding: 0rem  3rem 0rem 0rem;

            .img-wrap-box{
                height: 35rem;
                width: 100%;
                position: relative;
                background-image: url("../../../assets/article-dog-img.png");
                background-size: cover;
                background-repeat: no-repeat;
                border-radius: 1.5rem;
                margin: 2rem 0rem;
                
                .heading{
                    position: absolute;
                    bottom: 2rem;
                    left: 2rem;
                    background-color: rgba(255, 167, 44, 0.25);
                    color: #FFA72C;
                    padding: 1rem 2rem;
                    border-radius: .7rem;
                    font-weight: 700;
                }
            }

            .title{
                color: #424242;
                font-weight: 700;font-size: 2.5rem;
            }

            .like-view-box{
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                margin: 2rem 0rem;

                .box{
                    display: flex;
                    align-items: center;

                    .img-box{
                        width: 1.5rem;
                        height: 1.5rem;
                        margin-right: 1rem;
                    }

                    .text{
                        color: #bbbbbb;
                        font-size: 1.2rem;
                    }
                }
            }

            .article-text{
                color: #424242;
                text-align: justify;
                font-size: 1.6rem;
            }
        }

        .right-box{
            flex: 1;
            display: flex;
            flex-direction: column;
            padding-left: 3rem;

            .btn-view{
                align-self: center;
                margin-top: 5rem;
                color: #424242;
            }

            .title{
                font-weight: 700;
                color: #424242;
                font-size: 1.6rem;
                margin-bottom: 2rem;
            }

            .comment-wrap{
                display: flex;
                flex-direction: column;

                &>*:not(:last-child){
                    margin-bottom: 2.5rem;
                }

                .comment-box{
                    .name-date-box{
                        display: flex;
                        align-items: center;
                        color: #424242;

                        .name{
                            font-weight: 500;
                            font-size: 1.6rem;
                        }

                        .date{
                            font-weight: 300;
                            margin-left: auto;
                            font-size: 1.2rem;
                        }
                    }

                    .comment{
                        text-align: justify;
                        font-size: 1.5rem;
                        margin-top: 1.5rem;
                        color: #bbbbbb;
                    }
                }
            }
        }
      }
}