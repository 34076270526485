.create-pet-detail-wrap {
  display: flex;
  flex-direction: column;
  width: 80%;
  padding-bottom: 7rem;

  .title-box {
    margin: 3rem 0rem;
    display: grid;
    align-items: center;
    grid-template-columns: max-content 1fr;
    grid-column-gap: 2rem;
    .text {
      font-size: 1.5rem;
    }

    span {
      display: inline-block;
      height: 0.1rem;
      background-color: rgba(69, 93, 95, 0.15);
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;

    & > *:not(:last-child) {
      margin-bottom: 2.5rem;
    }

    .btn-box-wrap {
      margin-top: 5rem;
      display: grid;
      grid-template-columns: max-content min-content;
      align-items: center;

      .btn-reuse {
        margin-right: 3rem;
        justify-self: flex-start;
        min-width: 20rem;
      }

      .cancel-text {
        cursor: pointer;
      }
    }

    .input-border-select {
      padding: 0.7rem 1rem;
      border: 0.1rem solid var(--border-color);
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;

      & > * {
        border: none !important;
        background-color: #f5f7f7;
      }
    }

    .three-group-boxes {
      display: grid;
      grid-template-columns: 2fr 2fr 1fr;
      grid-column-gap: 4rem;
    }

    .two-group-boxes {
      display: grid;
      grid-template-columns: 3fr 2fr;
      grid-column-gap: 4rem;
    }

    .btn-reuse {
      padding: 1.4rem 3rem;
      width: 70%;
      align-self: center;
    }

    .picture-detail-box {
      width: 100%;
      // min-height: 20rem;
      // background-color: red;
      display: flex;

      .picture-box {
        flex: 0 0 30%;
        .form-group {
          display: flex;
          flex-direction: column;

          .text {
            font-size: 1.45rem;
          }

          .file-input {
            display: none;
          }

          .file-label {
            width: 12rem;
            height: 12rem;
            margin-top: 1rem;
            border-radius: 50%;
            background-image: url("../../../assets/create-pet-img-default.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            cursor: pointer;
          }
        }
      }
    }

    .form-group {
      display: flex;
      //   width: 70%;

      .input-wrap {
        border: 0.1rem solid var(--border-color);
        border-radius: 0.5rem;
        padding: 0.3rem 1rem;
      }

      .input {
        margin: 1rem 0rem 3rem 0rem;
        background-color: #f5f7f7;
        border: none;
        outline: none;
        padding: 1rem 1.5rem;
        border-radius: 1rem;
        width: 100%;

        &::placeholder {
          color: #bbbbbb;
        }

        &:focus {
          border: none;
          outline: none;
        }
      }

      .label {
        margin-right: 0.7rem;
        cursor: pointer;
      }

      .label-calendar {
        margin-left: 0.7rem;
        margin-right: unset;
      }

      .input-calender {
        border: unset;
        padding: 1.2rem 0rem;
        color: rgba(66, 66, 66, 0.5);
        background-color: #f5f7f7;
        order: -1;

        &:focus {
          border: none;
          outline: none;
        }
      }
    }

    .form-group-text-area {
      width: 100%;
    }

    .form-group {
      display: flex;
      flex-direction: column;

      .input-wrap {
        border: 0.1rem solid var(--border-color);
        border-radius: 0.8rem;
        padding: 0.5rem 1rem;

        .input {
          margin: unset;
        }
      }

      .label {
        margin-bottom: 0.5rem;
      }

      .group-two {
        display: flex;
        flex-direction: row;
        border: 0.1rem solid var(--border-color);
        border-radius: 0.5rem;
        padding: 0.3rem 1rem;
        align-items: center;

        & > :last-child {
          flex: 1;
        }
      }

      .input-border {
        border: 0.1rem solid var(--border-color);
        padding: 1rem 1rem;
        width: 70%;
        border-radius: 0.5rem;

        &:focus {
          border: 0.1rem solid var(--border-color);
          outline: none;
        }
      }
    }

    .distinguish-box {
      // background-color: red;
      .form-group {
        display: flex;
        flex-direction: column;
        // background-color: red;
        border: 0.1rem solid var(--border-color);
        padding: 0.8rem 1rem;
        border-radius: 0.8rem;
        margin-top: 1rem;

        .input {
          margin: unset;
        }

        .input-border-describtion {
          border: 0.1rem solid var(--border-color);
          padding: 0.8rem 1rem;
          border-radius: 0.5rem;

          &:focus {
            border: 0.1rem solid var(--border-color);
            outline: none;
          }
        }

        .label {
          margin-bottom: 0.5rem;
          font-size: 1.2rem;
        }

        .input-border {
          border: 0.1rem solid var(--border-color);
          padding: 0.8rem 2rem;
          border-radius: 0.5rem;

          &:focus {
            border: 0.1rem solid var(--border-color);
            outline: none;
          }
        }
      }
    }

    .form-group {
      display: flex;
      flex-direction: column;

      .input-border-describtion {
        border: 0.1rem solid var(--border-color);
        padding: 0.8rem 1rem;
        border-radius: 0.5rem;

        &:focus {
          border: 0.1rem solid var(--border-color);
          outline: none;
        }
      }

      .label {
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
      }

      .input-border {
        border: 0.1rem solid var(--border-color);
        padding: 0.8rem 2rem;
        border-radius: 0.5rem;

        &:focus {
          border: 0.1rem solid var(--border-color);
          outline: none;
        }
      }
    }
  }
}
