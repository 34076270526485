.auth-form-wrap {
  flex: 1;
  // background-color: red;
  margin-top: 3rem;

  .form {
    display: flex;
    flex-direction: column;

    & > *:not(:last-child) {
      margin-bottom: 2rem;
    }

    .error{
      margin-top: -1.5rem;
      color: #DE0B0B;
      text-transform: capitalize;
      font-size: 1.45rem;
      height: 2rem;
      // background-color: red;
    }

    .progress-box {
      // background-color: green
      display: grid;
      grid-template-columns: min-content 1fr;
      // grid-column-gap: 1rem;

      .text {
        font-size: 1.2rem;
        width: 13rem;
      }
    }

    .rember-login-box {
      // background-color: red;
      // margin-top: -1rem;
      align-self: center;

      .text {
        font-size: 1.3rem;

        span {
          margin-left: 1rem;
          cursor: pointer;
          color: var(--dark-yellow-color);
          font-weight: 500;
        }
      }
    }

    .form-group {
      display: flex;
      align-items: center;
      position: relative;
      border: 0.1rem solid rgba(66, 66, 66, 0.25);
      border-radius: 0.8rem;
      padding: 0rem 1rem;

    

      .show-hide {
        // background-color: red;
        // flex: 0 0 10%;
        display: grid;
        place-items: center;
        padding-right: 1.5rem;

        .icon {
          color: rgba(66, 66, 66, 0.35);
          cursor: pointer;
          width: 2rem;
          height: 2rem;
        }
      }

      .placeholder {
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 10%;
        color: rgba(166, 177, 189, 0.75);
        font-size: 1.3rem;
        text-transform: uppercase;
        background-color: var(--white-color);
        padding: 0rem 1rem;
        transition: transform 0.5s;
      }

      .placeholder-active {
        visibility: visible;
        transform: translateY(-50%);
      }

      .form-label {
        margin-right: 0.2rem;
        flex: 0 0 7%;
        cursor: pointer;

        .img-box {
          width: 2rem;
          height: 2rem;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: block;
          }
        }
      }

      .form-input {
        border: none;
        flex: 1;
        padding: 1.5rem 1rem;
        outline: none;
        border-radius: 1.3rem;
        background-color: transparent;
        font-size: 1.5rem;

        //   &:-webkit-autofill{
        //     background-color: transparent;
        //   }

        &::placeholder {
          color: rgba(0, 0, 0, 0.25);
          font-size: 1.3rem;
          text-transform: uppercase;
          // transition: all .3s;
        }

        &:focus {
          &::placeholder {
            visibility: hidden;
          }
        }

        &:focus ~ .placeholder {
          visibility: visible;
          transform: translateY(-50%);
        }
      }

      .phone-input {
        // border: none;
        padding: 0.5rem;
        color: #a5b1bd;
        // background-color:red;
      }

      .input-class {
        border: none;
        // background-color:red;
        color: #a5b1bd;

        @media only screen and (max-width: 38em) {
          width: 100%;
        }
      }

      .drop-down-class {
        background-color: transparent;
        border: unset;

        // &:hover{
        // background-color:  transparent !important;
        // }
      }

      //   pin field styles
      .pin-field {
        // background-color: var(--white-color);
        border-radius: 1.5rem;
        font-size: 2rem;
        height: 5rem;
        outline: none;
        text-align: center;
        transition-duration: 250ms;
        transition-property: background, color, border, box-shadow, transform;
        width: calc(100% / 6);
        border: 0.05rem solid #a5b1bd;

        @media only screen and (max-width: 24em) {
          height: 4rem;
        }
      }

      .pin-field:not(:last-child) {
        margin-right: 1rem;
      }

      .pin-field:focus {
        border-color: #0069ff;
        outline: none;
        transform: scale(1.05);
      }

      .pin-field:invalid {
        animation: shake 3 linear 75ms;
        border-color: rgb(220, 53, 69);
        box-shadow: 0 0 0.25rem rgba(220, 53, 69, 0.5);
      }

      .pin-field:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }

      swd-pin-field[completed] .pin-field {
        border-color: blue;
        background-color: white;
      }
    }

    .form-submit {
      margin-top: 3rem;
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.2rem 3rem;
      width: 40%;
      border-radius: 0.8rem;
      background-color: #84bfcd;
      border: none;
      outline: none;
      cursor: pointer;
      transition: all 0.3s;
      color: var(--white-color);
      font-weight: 600;
      font-size: 1.3rem;

      &:hover {
        transform: translateY(-0.15rem);
      }
    }

    .forget-rem-password-box {
      //   background-color: red;
      margin-top: -1rem;
      display: flex;
      align-items: center;

      .rem-box {
        display: flex;
        align-items: center;

        .check {
          display: none;

          &:checked ~ .label span::after {
            visibility: visible;
          }
        }

        .label {
          display: flex;
          align-items: center;
          font-size: 1.25rem;
          cursor: pointer;
          font-weight: 300;
          opacity: 0.75;

          span {
            display: inline-block;
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 1rem;
            border: 0.15rem solid var(--black-color);
            border-radius: 50%;
            position: relative;

            &::after {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background-color: var(--black-color);
              width: 70%;
              height: 70%;
              border-radius: 50%;
              visibility: hidden;
              // transition: all .3s;
            }
          }
        }
      }

      .link {
        color: var(--dark-yellow-color);
        margin-left: auto;
        font-size: 1.25rem;
        // font-weight: 500;
        text-decoration: none;
      }
    }
  }
}
