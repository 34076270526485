

.support-input-wrap{
    width: 100%;
    height: 100%;
    // background-color: red;
    display: flex;
    align-items: center;
    padding: 0rem 1rem;
    justify-content: space-evenly;

    .input-wrap{
        flex: 0 0 80%;
        &>*:first-child{
           &>*:first-child{
            order: 1;
            background-color: #F7F7F7;
            color: #bbbbbb;
           }
        }
    }

    .img-box{
        width: 2.5rem;
        height: 2.5rem;
        cursor: pointer;
    }
}