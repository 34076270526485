.sidebar-menu-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 100;
  background-color: var(--dark-yellow-color);
  display: flex;
  flex-direction: column;
  padding: 2rem 0rem;
  border-radius: 1rem;
  // overflow-y: scroll;

  .logo-box {
    width: 100%;
    //  background-color: red;
    display: flex;
    flex-direction: column;
    align-items: center;

    .img-box {
      width: 12rem;
      height: 3rem;
      margin-bottom: 1rem;
      cursor: pointer;

      .img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        display: block;
      }
    }

    .text {
      color: var(--white-color);
      font-weight: 500;
      background-color: rgba(69, 93, 95, 0.25);
      padding: 0.4rem 1rem;
      border-radius: 0.7rem;
      text-align: center;
      cursor: pointer;
      text-transform: capitalize;
      font-size: 1.3rem;
    }
  }

  .list-box {
    flex: 1;
    // height: 250rem;
    // overflow-y: scroll;
    padding-bottom: 2rem;

    .content-box {
      width: 100%;
      margin-top: 3rem;
      display: flex;
      flex-direction: column;
      padding-bottom: 3rem;
      background-color: var(--dark-yellow-color);
      border-radius: 1rem;

      .box {
        // background-color: red;
        display: flex;
        flex-direction: column;

        .title-box {
            //  background-color:red;
          margin-bottom: 1.5rem;
          display: grid;
          grid-template-columns: min-content 1fr;
          padding: 0rem 3rem;
          grid-column-gap: 1rem;
          align-items: center;

          .title {
            color: var(--white-color);
            font-size: 1.5rem;
          }

          span {
            height: 0.15rem;
            background-color: var(--white-color);
            display: inline-block;
            transform: translateY(0.1rem);
          }
        }

        .dash {
          margin: 1rem 0rem 1.5rem 0rem;
          display: grid;
          grid-template-columns: repeat(15, 1fr);
          grid-gap: 0.7rem;
          overflow-x: hidden;

          span {
            height: 0.2rem;
            background-color: var(--white-color);
            justify-content: space-between;
          }
        }

        .link-box {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: var(--white-color);
          // background-color: red;
          margin-bottom: 1rem;
          padding: 1rem 0rem 1rem 3rem;
          opacity: .5;
          cursor: pointer;
          position: relative;
          transition: all 0.3s;

          &>*{
            position: relative;
            z-index: 3;
          }

          &:hover{
            opacity: 1;
          }

          &:hover::after{
            transform: translateY(-50%) scaleY(1);
          }


          &::after{
            content: "";
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 0;
            width: .9rem;
            height: 70%;
            transform: translateY(-50%) scaleY(0);
            background-color: var(--white-color);
            border-top-right-radius: .4rem;
            border-bottom-right-radius: .4rem;
            transform-origin: bottom;
            transition: transform .3s;
          }

          .img-box {
            width: 2.5rem;
            height: 2.5rem;
            cursor: pointer;
            margin-right: 1.5rem;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              display: block;
            }
          }

          .name{
            font-weight: 700;
            font-size: 1.5rem;
            cursor: pointer;
          }
        }

        .link-box-active{
            opacity: 1;

            &::after{
                transform: translateY(-50%) scaleY(1);
              }
        }
      }
    }
  }
}
