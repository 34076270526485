.order-shop-modal-wrap {
  width: 55rem;
  min-height: 30rem;
  background-color: var(--white-color);
  border-radius: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem 5rem 4rem;
}
.order-shop-modal-wrap .btn-wrap {
  align-self: center;
  margin-top: 4rem;
}
.order-shop-modal-wrap .close-icon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 0.1rem solid var(--black-color);
  display: grid;
  place-items: center;
  transition: all 0.3s;
  cursor: pointer;
}
.order-shop-modal-wrap .close-icon:hover {
  transform: scale(1.04);
}
.order-shop-modal-wrap .close-icon .icon {
  width: 1.5rem;
  height: 1.5rem;
}
.order-shop-modal-wrap .title {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 2rem;
  align-self: center;
}
.order-shop-modal-wrap .switch-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 80%;
  align-self: center;
}
.order-shop-modal-wrap .switch-box .item {
  color: #bbbbbb;
  font-size: 1.35rem;
  text-align: center;
  padding-bottom: 1.5rem;
  border-bottom: 0.1rem solid #bbbbbb;
  cursor: pointer;
}
.order-shop-modal-wrap .switch-box .item span:hover {
  color: var(--dark-yellow-color);
}
.order-shop-modal-wrap .switch-box .item-active {
  color: var(--dark-yellow-color);
  font-weight: 700;
  position: relative;
}
.order-shop-modal-wrap .switch-box .item-active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.35rem;
  background-color: var(--dark-yellow-color);
  border-radius: 1rem;
  transform: translateY(50%);
}
.order-shop-modal-wrap .detail-wrap {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}
.order-shop-modal-wrap .detail-wrap .top-box {
  border-bottom: 0.07rem solid var(--border-color);
  padding-bottom: 2.5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 2rem;
  grid-row-gap: 3rem;
}
.order-shop-modal-wrap .detail-wrap .top-box .key {
  font-size: 1.25rem;
  opacity: 0.5;
  margin-bottom: 0.3rem;
}
.order-shop-modal-wrap .detail-wrap .top-box .value {
  font-size: 1.45rem;
  font-weight: 500;
}
.order-shop-modal-wrap .detail-wrap .top-box > *:last-child {
  grid-row: 2/-1;
  grid-column: 1/-1;
}
.order-shop-modal-wrap .detail-wrap .bottom-box {
  grid-row-gap: 3rem;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 2rem;
}
.order-shop-modal-wrap .detail-wrap .bottom-box .key {
  font-size: 1.25rem;
  opacity: 0.5;
  margin-bottom: 0.3rem;
}
.order-shop-modal-wrap .detail-wrap .bottom-box .value {
  font-size: 1.45rem;
  font-weight: 500;
}
.order-shop-modal-wrap .detail-wrap .bottom-box > *:last-child {
  grid-row: 2/-1;
  grid-column: 1/-1;
}
.order-shop-modal-wrap .detail-wrap .bottom-box .status {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
.order-shop-modal-wrap .detail-wrap .bottom-box .status span {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(0%, -50%);
  padding: 0.8rem 1.5rem;
  border-radius: 0.8rem;
  font-size: 1.25rem;
  z-index: 1;
}
.order-shop-modal-wrap .detail-wrap .bottom-box .status-pending span {
  background-color: rgba(255, 167, 44, 0.15);
  color: var(--dark-yellow-color);
}
.order-shop-modal-wrap .detail-wrap .bottom-box .status-deliver span {
  background-color: rgba(0, 192, 19, 0.15);
  color: #00c013;
}
.order-shop-modal-wrap .item-list-box {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
}
.order-shop-modal-wrap .item-list-box .content-box {
  display: flex;
  flex-direction: column;
  height: 35rem;
  overflow-y: scroll;
  padding-bottom: 2rem;
}
.order-shop-modal-wrap .item-list-box .content-box > *:not(:last-child) {
  margin-bottom: 1.5rem;
}
.order-shop-modal-wrap .item-list-box .content-box .box {
  display: flex;
  align-items: center;
  background-color: #f5f7f7;
  border-radius: 1rem;
  padding: 1.5rem 2rem;
}
.order-shop-modal-wrap .item-list-box .content-box .box .img-box {
  width: 8rem;
  height: 7rem;
}
.order-shop-modal-wrap .item-list-box .content-box .box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.order-shop-modal-wrap .item-list-box .content-box .box .text-box {
  display: flex;
  flex-direction: column;
  margin: 0rem 2rem;
  border-right: 0.1rem solid #bbbbbb;
  width: 23rem;
}
.order-shop-modal-wrap .item-list-box .content-box .box .text-box .key {
  font-size: 1.25rem;
  opacity: 0.5;
}
.order-shop-modal-wrap .item-list-box .content-box .box .text-box .value {
  font-size: 1.45rem;
  margin-bottom: 0.3rem;
  font-weight: 500;
}
.order-shop-modal-wrap .item-list-box .content-box .box .amount {
  font-size: 1.5rem;
  font-weight: 600;
}

.accept-reject-modal-wrap {
  width: 45rem;
  min-height: 35rem;
  border-radius: 1rem;
  background-color: var(--white-color);
  padding: 3rem;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: space-evenly;
}
.accept-reject-modal-wrap .close-icon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 0.1rem solid var(--black-color);
  display: grid;
  place-items: center;
  transition: all 0.3s;
  cursor: pointer;
}
.accept-reject-modal-wrap .close-icon:hover {
  transform: scale(1.04);
}
.accept-reject-modal-wrap .close-icon .icon {
  width: 1.5rem;
  height: 1.5rem;
}
.accept-reject-modal-wrap .img-box {
  width: 8rem;
  height: 6rem;
  margin: 2rem 0rem;
}
.accept-reject-modal-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.accept-reject-modal-wrap .big-text {
  text-align: center;
  font-size: 1.45rem;
  font-weight: 500;
}
.accept-reject-modal-wrap .small-text {
  color: rgba(66, 66, 66, 0.5);
  text-align: center;
  margin-top: 0.8rem;
}
.accept-reject-modal-wrap .input {
  margin: 2rem 0rem;
  background-color: #f5f7f7;
  border: none;
  outline: none;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  width: 100%;
}
.accept-reject-modal-wrap .input::-moz-placeholder {
  color: #bbbbbb;
}
.accept-reject-modal-wrap .input::placeholder {
  color: #bbbbbb;
}
.accept-reject-modal-wrap .input:focus {
  border: none;
  outline: none;
}
.accept-reject-modal-wrap .btn-wrap {
  padding: 1.2rem 3rem;
  margin-top: 1.5rem;
  font-size: 1.25rem;
}

.order-history-modal-wrap {
  width: 55rem;
  min-height: 30rem;
  background-color: var(--white-color);
  border-radius: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem 5rem 4rem;
  align-items: center;
}
.order-history-modal-wrap .close-icon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 0.1rem solid var(--black-color);
  display: grid;
  place-items: center;
  transition: all 0.3s;
  cursor: pointer;
}
.order-history-modal-wrap .close-icon:hover {
  transform: scale(1.04);
}
.order-history-modal-wrap .close-icon .icon {
  width: 1.5rem;
  height: 1.5rem;
}
.order-history-modal-wrap .title {
  align-self: center;
  font-weight: 600;
  font-size: 1.8rem;
  margin-bottom: 4rem;
  text-transform: capitalize;
}
.order-history-modal-wrap .text {
  color: #424242;
  margin-bottom: 3rem;
  font-size: 1.25rem;
}
.order-history-modal-wrap .calender-box {
  display: flex;
  align-items: center;
}
.order-history-modal-wrap .calender-box .text {
  margin-right: 1.3rem;
  margin-bottom: unset;
}
.order-history-modal-wrap .calender-box .form-group {
  display: flex;
  align-items: center;
  padding: 0.2rem 1rem;
  border: 0.1rem solid rgba(66, 66, 66, 0.5);
  border-radius: 0.8rem;
}
.order-history-modal-wrap .calender-box .form-group .label {
  margin-right: 0.7rem;
  cursor: pointer;
}
.order-history-modal-wrap .calender-box .form-group .input-calender {
  border: unset;
  padding: 1rem 0rem;
  color: rgba(66, 66, 66, 0.5);
}
.order-history-modal-wrap .calender-box .form-group .input-calender:focus {
  border: none;
  outline: none;
}
.order-history-modal-wrap .btn-wrap {
  margin-top: 4rem;
  padding: 1.2rem 5rem;
}
.order-history-modal-wrap .title-box {
  font-size: 1.2rem;
  display: flex;
  width: 100%;
  align-items: center;
}
.order-history-modal-wrap .title-box .export {
  margin-left: auto;
  color: var(--dark-yellow-color);
  cursor: pointer;
}
.order-history-modal-wrap .title-box .text-one span {
  font-weight: 1.9rem;
  font-weight: 700;
  margin: 0rem 0.4rem;
}
.order-history-modal-wrap .table-box {
  height: 35rem;
  overflow-y: scroll;
  align-self: flex-start;
}
.add-discount-modal-wrap {
  position: relative;
  min-width: 45rem;
  min-height: 35rem;
  background-color: var(--white-color);
  position: relative;
  display: flex;
  padding: 3rem 4rem 5rem 4rem;
  border-radius: 1rem;
  flex-direction: column;
  align-items: center;
}
.add-discount-modal-wrap .close-icon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 0.1rem solid var(--black-color);
  display: grid;
  place-items: center;
  transition: all 0.3s;
  cursor: pointer;
}
.add-discount-modal-wrap .close-icon:hover {
  transform: scale(1.04);
}
.add-discount-modal-wrap .close-icon .icon {
  width: 1.5rem;
  height: 1.5rem;
}
.add-discount-modal-wrap .title {
  align-self: center;
  font-weight: 600;
  font-size: 1.8rem;
  margin-bottom: 2rem;
  text-transform: capitalize;
}
.add-discount-modal-wrap .text {
  color: rgba(66, 66, 66, 0.5);
  margin-bottom: 2rem;
  width: 70%;
  font-size: 1.5rem;
  text-align: center;
}
.add-discount-modal-wrap .two-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 2rem;
}
.add-discount-modal-wrap .two-group .box {
  display: flex;
  flex-direction: column;
}
.add-discount-modal-wrap .two-group .box .key {
  font-size: 1.45rem;
  opacity: 0.5;
  margin-bottom: 0.3rem;
}
.add-discount-modal-wrap .two-group .box .value {
  font-size: 1.6rem;
  font-weight: 500;
}
.add-discount-modal-wrap .two-group .form-group {
  display: flex;
  flex-direction: column;
}
.add-discount-modal-wrap .two-group .form-group label {
  margin-bottom: 0.5rem;
}
.add-discount-modal-wrap .two-group .form-group .wrap {
  display: flex;
  align-items: center;
  border: 0.1rem solid #bbbbbb;
  border-radius: 0.7rem;
  padding: 0.4rem 1rem;
  background-color: #f5f7f7;
  width: 14rem;
}
.add-discount-modal-wrap .two-group .form-group .wrap input {
  flex: 0 0 50%;
  width: 50%;
  padding: 1rem 1rem;
  border: none;
  outline: none;
}
.add-discount-modal-wrap .two-group .form-group .wrap input:focus {
  border: none;
  outline: none;
}
.add-discount-modal-wrap .two-group .form-group .wrap .unit {
  display: flex;
  justify-content: center;
  flex: 0 0 50%;
  background-color: #f5f7f7;
  color: #bbbbbb;
}
.add-discount-modal-wrap .two-group-extra {
  padding-bottom: 1.5rem;
  border-bottom: 0.1rem solid var(--border-color);
  margin-bottom: 2rem;
}
.add-discount-modal-wrap .two-group-bottom {
  margin-bottom: 2rem;
}
.add-discount-modal-wrap .btn-wrap {
  margin-top: 4rem;
}

.view-shop-item-modal {
  position: relative;
  min-height: 35rem;
  background-color: var(--white-color);
  position: relative;
  display: flex;
  border-radius: 1rem;
}
.view-shop-item-modal .close-icon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 0.1rem solid var(--black-color);
  display: grid;
  place-items: center;
  transition: all 0.3s;
  cursor: pointer;
}
.view-shop-item-modal .close-icon:hover {
  transform: scale(1.04);
}
.view-shop-item-modal .close-icon .icon {
  width: 1.5rem;
  height: 1.5rem;
}
.view-shop-item-modal .left-box {
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem 5rem 4rem;
  overflow-x: scroll;
}
.view-shop-item-modal .left-box .img-scroll-wrap {
  width: 100%;
  height: 20rem;
  display: grid;
  overflow: scroll;
  grid-gap: 3rem;
}
.view-shop-item-modal .left-box .img-scroll-wrap::-webkit-scrollbar {
  display: none;
}
.view-shop-item-modal .left-box .img-scroll-wrap .img-box {
  width: 15rem;
  height: 20rem;
}
.view-shop-item-modal .left-box .img-scroll-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.view-shop-item-modal .left-box .detail-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 2rem;
}
.view-shop-item-modal .left-box .detail-box .box {
  display: flex;
  flex-direction: column;
}
.view-shop-item-modal .left-box .detail-box .box .key {
  font-size: 1.1rem;
  opacity: 0.5;
}
.view-shop-item-modal .left-box .detail-box .box .value {
  font-size: 1.25rem;
  margin-bottom: 0.3rem;
  font-weight: 500;
}
.view-shop-item-modal .left-box .upload-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.view-shop-item-modal .left-box .upload-wrap .title {
  margin-bottom: 0.7rem;
  align-self: flex-start;
  text-transform: capitalize;
}
.view-shop-item-modal .left-box .upload-wrap .input-upload {
  display: none;
}
.view-shop-item-modal .left-box .upload-wrap .label {
  margin-bottom: 2rem;
  cursor: pointer;
}
.view-shop-item-modal .left-box .upload-wrap .label .img-box {
  width: 20rem;
  height: 20rem;
  padding: 2rem;
  border-radius: 1rem;
  border: 0.1rem solid var(--border-color);
}
.view-shop-item-modal .left-box .upload-wrap .label .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.view-shop-item-modal .left-box .upload-wrap .switch-upload-wrap {
  display: flex;
  align-items: center;
}
.view-shop-item-modal .left-box .upload-wrap .switch-upload-wrap > *:not(:last-child) {
  margin-right: 1.5rem;
}
.view-shop-item-modal .left-box .upload-wrap .switch-upload-wrap .img-box {
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 0.1rem solid var(--border-color);
  opacity: 0.35;
}
.view-shop-item-modal .left-box .upload-wrap .switch-upload-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.view-shop-item-modal .left-box .upload-wrap .switch-upload-wrap .img-box-active {
  opacity: 1;
}
.view-shop-item-modal .right-box {
  flex: 1;
  background-color: rgba(255, 167, 44, 0.05);
  display: flex;
  flex-direction: column;
  padding: 3rem;
}
.view-shop-item-modal .right-box > *:not(:last-child) {
  margin-bottom: 1.5rem;
}
.view-shop-item-modal .right-box .btn {
  color: #00c013;
  background-color: rgba(0, 192, 19, 0.15);
  padding: 0.6rem 1.5rem;
  border-radius: 0.6rem;
  font-weight: 500;
  align-self: flex-start;
}
.view-shop-item-modal .right-box .status-pending {
  background-color: rgba(255, 167, 44, 0.15);
  color: var(--dark-yellow-color);
}
.view-shop-item-modal .right-box .title {
  font-weight: 500;
  color: #424242;
  font-size: 1.6rem;
}
.view-shop-item-modal .right-box .rate-box .number {
  color: #424242;
  opacity: 0.5;
  margin-left: 0.5rem;
}
.view-shop-item-modal .right-box .btn-wrap {
  align-self: center;
  border-radius: 1.5rem;
  margin-top: 3.5rem;
  width: 13rem;
}
.view-shop-item-modal .right-box .detail-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 2rem;
}
.view-shop-item-modal .right-box .detail-box .box {
  display: flex;
  flex-direction: column;
}
.view-shop-item-modal .right-box .detail-box .box .key {
  font-size: 1.3rem;
  opacity: 0.5;
}
.view-shop-item-modal .right-box .detail-box .box .value {
  font-size: 1.45rem;
  margin-bottom: 0.3rem;
  font-weight: 500;
}
.view-shop-item-modal .right-box .description {
  color: #bbbbbb;
  font-size: 1.6rem;
}
.view-shop-item-modal .right-box .text {
  font-size: 1.2rem;
  margin-top: -1rem;
}
.view-shop-item-modal .right-box-edit-item {
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
}
.view-shop-item-modal .right-box-edit-item .btn-wrap {
  align-self: center;
  border-radius: 0.5rem;
  margin-top: 2rem;
}
.view-shop-item-modal .right-box-edit-item > *:not(:last-child) {
  margin-bottom: 1.5rem;
}
.view-shop-item-modal .right-box-edit-item .form-group {
  display: flex;
  flex-direction: column;
}
.view-shop-item-modal .right-box-edit-item .form-group .input-border-describtion {
  border: 0.1rem solid var(--border-color);
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
}
.view-shop-item-modal .right-box-edit-item .form-group .input-border-describtion:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}
.view-shop-item-modal .right-box-edit-item .form-group .label {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}
.view-shop-item-modal .right-box-edit-item .form-group .input-border {
  border: 0.1rem solid var(--border-color);
  padding: 0.8rem 2rem;
  border-radius: 0.5rem;
}
.view-shop-item-modal .right-box-edit-item .form-group .input-border:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}
.view-shop-item-modal .right-box-edit-item .form-group-unit-cost {
  display: flex;
  flex-direction: column;
}
.view-shop-item-modal .right-box-edit-item .form-group-unit-cost .title-text {
  margin-bottom: 0.5rem;
}
.view-shop-item-modal .right-box-edit-item .form-group-unit-cost .wrap {
  border: 0.1rem solid var(--border-color);
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: var(--white-color);
}
.view-shop-item-modal .right-box-edit-item .form-group-unit-cost .wrap .label {
  padding-right: 1rem;
  border-right: 0.1rem solid #bbbbbb;
}
.view-shop-item-modal .right-box-edit-item .form-group-unit-cost .wrap input {
  border: none;
  outline: none;
  flex: 1;
  padding: 0.5rem 1rem;
}
.view-shop-item-modal .right-box-edit-item .form-group-unit-cost .wrap input:focus {
  border: none;
  outline: none;
}
.view-shop-item-modal .right-box-edit-item .two-group-input {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1.5rem;
}
.view-shop-item-modal .right-box-edit-item .two-group-input .form-group {
  display: flex;
  flex-direction: column;
}
.view-shop-item-modal .right-box-edit-item .two-group-input .form-group .label {
  margin-bottom: 0.5rem;
}
.view-shop-item-modal .right-box-edit-item .two-group-input .form-group .input-border {
  border: 0.1rem solid var(--border-color);
  padding: 1rem 1rem;
  width: 70%;
  border-radius: 0.5rem;
}
.view-shop-item-modal .right-box-edit-item .two-group-input .form-group .input-border:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}
.view-shop-item-modal .right-box-edit-item .form-group-two {
  display: flex;
  flex-direction: column;
}
.view-shop-item-modal .right-box-edit-item .form-group-two .title-item {
  margin-bottom: 0.5rem;
}
.view-shop-item-modal .right-box-edit-item .form-group-two .wrap {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
  margin-top: 0.5rem;
}
.view-shop-item-modal .right-box-edit-item .form-group-two .wrap .check-box .checkbox-list {
  display: none;
}
.view-shop-item-modal .right-box-edit-item .form-group-two .wrap .check-box .checkbox-list:checked ~ .label span::after {
  visibility: visible;
}
.view-shop-item-modal .right-box-edit-item .form-group-two .wrap .check-box .label {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.view-shop-item-modal .right-box-edit-item .form-group-two .wrap .check-box .label span {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.1rem solid #424242;
  border-radius: 50%;
  margin-right: 0.5rem;
  position: relative;
}
.view-shop-item-modal .right-box-edit-item .form-group-two .wrap .check-box .label span::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 65%;
  height: 65%;
  border-radius: 50%;
  background-color: #424242;
  visibility: hidden;
}/*# sourceMappingURL=Modal.css.map */