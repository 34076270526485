.btn-wrap {
  padding: 1.3rem 3rem;
  border-radius: 0.6rem;
  background-color: var(--dark-yellow-color);
  border: none;
  outline: none;
  color: var(--white-color);
  font-weight: 600;
  font-size: 1.3rem;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;

  &:hover {
    transform: translateY(-0.12rem);
  }
}




.results{
 .row{
    border: 2px solid #cccccc;
    padding: 1rem;
    border-bottom: unset;
  }

  *>*:last-child{
    border-bottom: 2px solid #cccccc;
  }
}