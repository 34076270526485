.create-blog-post-wrap {
  display: flex;
  flex-direction: column;
  padding-bottom: 7rem;
}
.create-blog-post-wrap .title-back-box {
  display: flex;
  align-self: flex-start;
  align-items: center;
  color: #bbbbbb;
  cursor: pointer;
  margin-bottom: 2rem;
}
.create-blog-post-wrap .title-back-box .text {
  font-size: 1.6rem;
}
.create-blog-post-wrap .title-back-box .text-active {
  color: #424242;
  font-weight: 500;
  margin-left: 0.5rem;
}
.create-blog-post-wrap .blog-detail-box {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 2rem;
  align-items: center;
  width: 70%;
  margin: 2rem 0rem 4rem 0rem;
}
.create-blog-post-wrap .blog-detail-box .text {
  color: #424242;
  opacity: 0.75;
}
.create-blog-post-wrap .blog-detail-box span {
  display: inline-block;
  height: 0.1rem;
  background-color: #455d5f;
  opacity: 0.25;
}
.create-blog-post-wrap .form {
  width: 65%;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 75em) {
  .create-blog-post-wrap .form {
    width: 80%;
  }
}
.create-blog-post-wrap .form > *:not(:last-child) {
  margin-bottom: 2rem;
}
.create-blog-post-wrap .form .quill-editor {
  width: 100% !important;
  height: 35rem !important;
}
.create-blog-post-wrap .form .quill-editor > *:nth-child(2) {
  border-radius: 1rem !important;
  border-top-left-radius: unset !important;
  border-top-right-radius: unset !important;
  background-color: #f5f7f7 !important;
}
.create-blog-post-wrap .form .quill-editor > *:first-child {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}
.create-blog-post-wrap .form .btn {
  margin-top: 7rem;
  align-self: flex-start;
}
.create-blog-post-wrap .form .dna-wrapper {
  margin-top: 7rem;
  align-self: flex-start;
}
.create-blog-post-wrap .form .form-submit {
  background-color: #F5F7F7;
  padding: 1.3rem 3.5rem;
  border: none;
  outline: none;
  border-radius: 0.7rem;
  color: var(--black-color);
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}
.create-blog-post-wrap .form .form-submit-active {
  background-color: #84BFCD;
  color: var(--white-color);
}
.create-blog-post-wrap .form .form-submit-active:hover {
  transform: translateY(-0.12rem);
}
.create-blog-post-wrap .form .two-groups {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
}
.create-blog-post-wrap .form .form-group {
  display: flex;
  flex-direction: column;
}
.create-blog-post-wrap .form .form-group .form-label {
  font-size: 1.45rem;
  color: #424242;
  opacity: 0.75;
  margin-bottom: 1rem;
}
.create-blog-post-wrap .form .form-group .input-group {
  padding: 0.5rem 0.7rem;
  border-radius: 0.9rem;
  border: 0.1rem solid #bbbbbb;
  display: flex;
  align-items: center;
  width: 100%;
}
.create-blog-post-wrap .form .form-group .input-group .img-article {
  margin-right: 1rem;
}
.create-blog-post-wrap .form .form-group .input-group .input-border {
  flex: 1;
}
.create-blog-post-wrap .form .form-group .input-group .input-border > *:nth-child(3) {
  background-color: #f5f7f7 !important;
  border-radius: 0.6rem !important;
  border: none !important;
}
.create-blog-post-wrap .form .form-group .input-group .input-border > *:nth-child(3) > *:nth-child(2) {
  display: none;
}
.create-blog-post-wrap .form .form-group .input-group .input-border > * {
  border: none !important;
}
.create-blog-post-wrap .form .form-group .input-group .img-wrap {
  flex: 0 0 10%;
  display: grid;
  place-items: center;
}
.create-blog-post-wrap .form .form-group .input-group .img-wrap .img-box {
  width: 1.3rem;
  height: 1.3rem;
  cursor: pointer;
}
.create-blog-post-wrap .form .form-group .input-group .form-input {
  background-color: #f5f7f7;
  padding: 1.3rem;
  border-radius: 0.6rem;
  border: none;
  outline: none;
  flex: 1;
}
.create-blog-post-wrap .form .form-group .input-group .form-input:focus {
  border: none;
  outline: none;
}

.view-blog-article .title-back-box {
  display: flex;
  align-self: flex-start;
  align-items: center;
  color: #bbbbbb;
  cursor: pointer;
  margin-bottom: 2rem;
}
.view-blog-article .title-back-box .text {
  font-size: 1.6rem;
}
.view-blog-article .title-back-box .text-active {
  color: #424242;
  font-weight: 500;
  margin-left: 0.5rem;
}
.view-blog-article .content-box {
  display: flex;
  padding-bottom: 6rem;
}
.view-blog-article .content-box .left-box {
  flex: 0 0 65%;
  border-right: 0.1rem solid rgba(69, 93, 95, 0.25);
  display: flex;
  flex-direction: column;
  padding: 0rem 3rem 0rem 0rem;
}
.view-blog-article .content-box .left-box .img-wrap-box {
  height: 35rem;
  width: 100%;
  position: relative;
  background-image: url("../../../assets/article-dog-img.png");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 1.5rem;
  margin: 2rem 0rem;
}
.view-blog-article .content-box .left-box .img-wrap-box .heading {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  background-color: rgba(255, 167, 44, 0.25);
  color: #FFA72C;
  padding: 1rem 2rem;
  border-radius: 0.7rem;
  font-weight: 700;
}
.view-blog-article .content-box .left-box .title {
  color: #424242;
  font-weight: 700;
  font-size: 2.5rem;
}
.view-blog-article .content-box .left-box .like-view-box {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin: 2rem 0rem;
}
.view-blog-article .content-box .left-box .like-view-box .box {
  display: flex;
  align-items: center;
}
.view-blog-article .content-box .left-box .like-view-box .box .img-box {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
}
.view-blog-article .content-box .left-box .like-view-box .box .text {
  color: #bbbbbb;
  font-size: 1.2rem;
}
.view-blog-article .content-box .left-box .article-text {
  color: #424242;
  text-align: justify;
  font-size: 1.6rem;
}
.view-blog-article .content-box .right-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 3rem;
}
.view-blog-article .content-box .right-box .btn-view {
  align-self: center;
  margin-top: 5rem;
  color: #424242;
}
.view-blog-article .content-box .right-box .title {
  font-weight: 700;
  color: #424242;
  font-size: 1.6rem;
  margin-bottom: 2rem;
}
.view-blog-article .content-box .right-box .comment-wrap {
  display: flex;
  flex-direction: column;
}
.view-blog-article .content-box .right-box .comment-wrap > *:not(:last-child) {
  margin-bottom: 2.5rem;
}
.view-blog-article .content-box .right-box .comment-wrap .comment-box .name-date-box {
  display: flex;
  align-items: center;
  color: #424242;
}
.view-blog-article .content-box .right-box .comment-wrap .comment-box .name-date-box .name {
  font-weight: 500;
  font-size: 1.6rem;
}
.view-blog-article .content-box .right-box .comment-wrap .comment-box .name-date-box .date {
  font-weight: 300;
  margin-left: auto;
  font-size: 1.2rem;
}
.view-blog-article .content-box .right-box .comment-wrap .comment-box .comment {
  text-align: justify;
  font-size: 1.5rem;
  margin-top: 1.5rem;
  color: #bbbbbb;
}/*# sourceMappingURL=DashboardBlog.css.map */