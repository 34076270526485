.reuseble-table-wrap {
  // min-height: 20rem
  // background-color: red;
  margin-top: 5rem;
  //   padding-bottom: 25rem;

  .table {
    width: 100%;
    table-layout: auto;

    thead {
      tr {
        background-color: #f5f7f7;

        & > *:first-child {
          border-top-left-radius: 0.6rem;
          border-bottom-left-radius: 0.6rem;
        }

        & > *:last-child {
          border-top-right-radius: 0.6rem;
          border-bottom-right-radius: 0.6rem;
        }

        th {
          padding: 1.4rem 0rem;
          text-align: left;
          padding-left: 2rem;
          font-weight: 500;
          color: #424242;
          font-size: 1.3rem;
          //   opacity: 0.8;

          .icon {
            transform: translateY(0.5rem);
            width: 2rem;
            height: 2rem;
          }
        }

        .action {
          width: 4rem;
          padding-left: unset;
        }
      }
    }

    tbody {
      tr {
        position: relative;

        .action {
          position: relative;
          z-index: 3;
        }

        .blog-td {
          display: flex;
          align-items: center;
          // width: 50%;

          .img-title-box {
            display: flex;
            align-items: center;

            .img-box {
              width: 7rem;
              height: 7rem;
              margin-right: 1.5rem;
            }

            .text-box {
              display: flex;
              flex-direction: column;

              .header {
                padding: 1rem 2rem;
                border-radius: 0.7rem;
                font-size: 1.2rem;
                align-self: flex-start;
                margin-bottom: 0.8rem;
              }

              .heading-one {
                background-color: rgba(255, 167, 44, 0.15);
                color: #ffa72c;
              }

              .heading-two {
                background-color: rgba(132, 191, 205, 0.15);
                color: #84bfcd;
              }

              .regular {
                color: #424242;
                background-color: rgba(66, 66, 66, 0.15);
              }

              .title {
                font-weight: 500;
                font-size: 1.65rem;
              }
            }
          }
        }

        .img-text-td {
          .img-text-box {
            display: flex;
            align-items: center;

            .img-box {
              width: 5rem;
              height: 5rem;
              margin-right: 1rem;

              .img {
                object-fit: contain;
                width: 100%;
                height: 100%;
                display: block;
              }
            }
          }
        }

        td {
          padding: 2.5rem 0rem;
          text-align: left;
          padding-left: 2rem;
          font-weight: 500;
          color: #424242;
          font-size: 1.3rem;
          //   opacity: 0.8;
          border-bottom: 0.1rem solid rgba(66, 66, 66, 0.1);
          position: relative;

          .drop-wrap {
            background-color: rgba(69, 93, 95, 0.15);
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            // padding: .3rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;

            .icon {
              color: #455d5f;
              width: 2rem;
              height: 2rem;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }

            .drop-box {
              position: absolute;
              top: 100%;
              right: 2rem;
              width: 17rem;
              background-color: var(--white-color);
              box-shadow: 0.1rem 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.1);
              border-radius: 0.8rem;
              z-index: 3;
              transform: scale(0);
              transform-origin: top right;
              transition: 0.5s;
              padding: 2.5rem 2rem;
              display: flex;
              flex-direction: column;
              // align-items: center;
              text-align: left;
              justify-content: space-evenly;

              & > *:not(:last-child) {
                margin-bottom: 2rem;
              }

              .text {
                font-weight: 400;
                cursor: pointer;
              }
            }

            .drop-box-big{
              width: 23rem;
            }

            .drop-box-show {
              transform: scale(1);
            }
          }
        }

        .status {
          position: relative;
          z-index: 1;

          .status-bg {
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 1;
            min-width: 12rem;

            span {
              position: absolute;
              top: 50%;
              left: 0%;
              transform: translate(0%, -50%);
              padding: 0.8rem 1.5rem;
              border-radius: 0.8rem;
              font-size: 1.25rem;
              z-index: 1;
              text-transform: capitalize;
            }
          }

          .status-red {
            span {
              background-color: rgba(222, 11, 11, 0.15);
              color: #de0b0b;
            }
          }

          .status-pending {
            span {
              background-color: rgba(255, 167, 44, 0.15);
              color: var(--dark-yellow-color);
            }
          }

          .status-deliver {
            span {
              background-color: rgba(0, 192, 19, 0.15);
              color: #00c013;
            }
          }

          .status-black{
            // transform: translateX(-2rem);
            // min-width: 15rem;
            span {
              background-color: rgba(69, 93, 95, 0.15);
              color: #455D5F;
              // font-size: .6rem;
            }
          }

          .status-yellow{
            span {
              background-color: rgba(255, 167, 44, 0.15);
              color: #FFA72C;
              // font-size: .6rem;
            }
          }
        }
      }
    }

    .table-body {
      & > *:last-child,
      & > *:nth-last-child(2),
      & > *:nth-last-child(3) {
        td {
          .drop-wrap {
            .drop-box {
              top: unset;
              bottom: 2rem;
              transform-origin: bottom right;
            }
          }
        }
      }
    }
  }

  .table-two {
    table-layout: fixed;
  }

  .pagination-wrap-box{
    display: flex;
    width: 100%;
    padding: 1.2rem 2rem;
    background-color: #F5F7F7;
    align-items: center;
    border-radius: .8rem;
    margin: 7rem 0rem;
    // margin-top: 7rem;
    color: #424242;
    opacity: .5;
    font-size: 1.35rem;
    font-weight: 600;

    .text{
      margin-left: auto;
    }

    .react-select{
      margin: 0rem 2rem;
      width: 7rem;

      &>*:nth-child(3){
        border: .15rem solid rgba(0, 0, 0, 0.1);
        border-radius: .5rem;
      }
    }

    .prev-next-box{
      margin-left: 2.5rem;
      padding-left: 2rem;
      border-left: .1rem solid rgba(0, 0, 0, 0.25);
      display: flex;

      &>*:first-child{
        margin-right: 1.5rem;
      }

      .box{
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        border: .1rem solid #424242;
        display: grid;
        place-items: center;
        opacity: .35;
        transition: all .3s;

        .icon{
          width: 1.5rem;
          height: 1.5rem;
        }
      }

      .box-active{
        opacity: 1;
        cursor: pointer;
        
        &:hover{
          transform: scale(1.1);
        }
      }
    }

    .from-to-box{
      display: flex;
      align-items: center;

      &>*:not(:last-child){
        margin-right: .7rem;
      }
    }
  }
}
