.support-history-wrap-box {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.support-history-wrap-box .text-value-box {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 4rem;
  background-color: rgba(0, 192, 19, 0.15);
  border-radius: 1rem;
  margin-bottom: 3rem;
}
.support-history-wrap-box .text-value-box .value {
  color: #00c013;
  font-size: 2vw;
  font-weight: 600;
  margin-top: 1rem;
}
.support-history-wrap-box .btn-reuse {
  align-self: flex-start;
  padding: 1.4rem 3rem;
}
.support-history-wrap-box .reset-text-box {
  margin: 2rem 0rem -2rem 0rem;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
}
.support-history-wrap-box .reset-text-box .text span {
  font-weight: 800;
}
.support-history-wrap-box .reset-text-box .reset {
  margin: 0rem 0rem 0rem 5rem;
  font-weight: 800;
  color: #ffa72c;
  border-bottom: 0.1rem solid currentColor;
  cursor: pointer;
}
.support-history-wrap-box .subtitle {
  font-size: 1.3rem;
  margin-top: 3rem;
}
.support-history-wrap-box .calender-box {
  display: flex;
  align-items: center;
  margin: 2rem 0rem;
}
.support-history-wrap-box .calender-box .text {
  margin-right: 1.3rem;
  margin-bottom: unset;
}
.support-history-wrap-box .calender-box .form-group {
  display: flex;
  align-items: center;
  padding: 0.2rem 1rem;
  border: 0.1rem solid rgba(66, 66, 66, 0.5);
  border-radius: 0.8rem;
}
.support-history-wrap-box .calender-box .form-group .label {
  margin-right: 0.7rem;
  cursor: pointer;
}
.support-history-wrap-box .calender-box .form-group .input-calender {
  border: unset;
  padding: 1rem 0rem;
}
.support-history-wrap-box .calender-box .form-group .input-calender:focus {
  border: none;
  outline: none;
}

.support-setting-wrap-box {
  display: flex;
  flex-direction: column;
}
.support-setting-wrap-box .metrics-box {
  width: 80%;
}
.support-setting-wrap-box .metrics-box .title {
  font-weight: 600;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  text-transform: capitalize;
}
.support-setting-wrap-box .metrics-box .detail-box {
  position: relative;
  min-height: 15rem;
  border: 0.1rem solid var(--border-color);
  border-radius: 1rem;
  padding: 2rem;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.support-setting-wrap-box .metrics-box .detail-box > *:not(:last-child) {
  margin-right: 1rem;
}
.support-setting-wrap-box .metrics-box .detail-box .info {
  color: #bbbbbb;
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: 1;
}
.support-setting-wrap-box .metrics-box .detail-box .input-btn-box {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
}
.support-setting-wrap-box .metrics-box .detail-box .input-btn-box .metrics-input {
  padding: 1rem;
  border: 0.1rem solid var(--dark-yellow-color);
  border-radius: 0.8rem;
}
.support-setting-wrap-box .metrics-box .detail-box .input-btn-box .metrics-input:focus {
  outline: none;
  border: 0.1rem solid var(--dark-yellow-color);
}
.support-setting-wrap-box .metrics-box .detail-box .input-btn-box .icon {
  color: var(--dark-yellow-color);
  cursor: pointer;
  transform: translateY(0.7rem);
  width: 2rem;
  height: 2rem;
}
.support-setting-wrap-box .metrics-box .detail-box .input-btn-box-active {
  padding: 1rem;
  border-radius: 0.6rem;
  box-shadow: var(--box-shadow-one);
  background-color: var(--white-color);
}/*# sourceMappingURL=SuppportHistory.css.map */