.shop-common-header-wrap {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
}
.shop-common-header-wrap .link-box {
  flex: 0 0 50%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.shop-common-header-wrap .link-box .child {
  color: #BBBBBB;
  font-size: 1.35rem;
  text-align: center;
  padding-bottom: 1.5rem;
  border-bottom: 0.1rem solid #BBBBBB;
  cursor: pointer;
}
.shop-common-header-wrap .link-box .child span:hover {
  color: var(--dark-yellow-color);
}
.shop-common-header-wrap .link-box .child-active {
  color: var(--dark-yellow-color);
  font-weight: 700;
  position: relative;
}
.shop-common-header-wrap .link-box .child-active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.35rem;
  background-color: var(--dark-yellow-color);
  border-radius: 1rem;
  transform: translateY(50%);
}
.shop-common-header-wrap .export-box {
  display: flex;
  padding: 1.4rem 4rem;
  justify-content: center;
  align-items: center;
  background-color: var(--white-color);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  margin-right: 3rem;
  border-radius: 1rem;
  margin-left: auto;
  cursor: pointer;
}
.shop-common-header-wrap .export-box:hover .img-box {
  transform: translateY(0.2rem);
}
.shop-common-header-wrap .export-box .img-box {
  margin-right: 1rem;
  width: 2rem;
  height: 2rem;
  transition: all 0.3s;
}
.shop-common-header-wrap .btn-search-box {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.shop-common-header-wrap .btn-search-box .form-group {
  display: flex;
  align-items: center;
  border: 0.1rem solid #BBBBBB;
  border-radius: 3rem;
  padding: 0.5rem 1rem;
  color: #BBBBBB;
  margin-right: 3rem;
}
.shop-common-header-wrap .btn-search-box .form-group .img-box {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
.shop-common-header-wrap .btn-search-box .form-group .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.shop-common-header-wrap .btn-search-box .form-group .input {
  border: none;
  outline: none;
  padding: 0.7rem 1.2rem;
}
.shop-common-header-wrap .btn-search-box .form-group .input::-moz-placeholder {
  color: #BBBBBB;
}
.shop-common-header-wrap .btn-search-box .form-group .input::placeholder {
  color: #BBBBBB;
}
.shop-common-header-wrap .btn-search-box .form-group .input:focus {
  border: none;
  outline: none;
}
.shop-common-header-wrap .btn-search-box .btn-wrap .img-box {
  margin-right: 1rem;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
.shop-common-header-wrap .btn-search-box .btn-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}/*# sourceMappingURL=CommonHeader.css.map */