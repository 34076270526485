.support-input-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0rem 1rem;
  justify-content: space-evenly;
}
.support-input-wrap .input-wrap {
  flex: 0 0 80%;
}
.support-input-wrap .input-wrap > *:first-child > *:first-child {
  order: 1;
  background-color: #F7F7F7;
  color: #bbbbbb;
}
.support-input-wrap .img-box {
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
}/*# sourceMappingURL=SupportChat.css.map */