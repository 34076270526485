.create-pet-detail-wrap {
  display: flex;
  flex-direction: column;
  width: 80%;
  padding-bottom: 7rem;
}
.create-pet-detail-wrap .title-box {
  margin: 3rem 0rem;
  display: grid;
  align-items: center;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 2rem;
}
.create-pet-detail-wrap .title-box .text {
  font-size: 1.5rem;
}
.create-pet-detail-wrap .title-box span {
  display: inline-block;
  height: 0.1rem;
  background-color: rgba(69, 93, 95, 0.15);
}
.create-pet-detail-wrap .form {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
}
.create-pet-detail-wrap .form > *:not(:last-child) {
  margin-bottom: 2.5rem;
}
.create-pet-detail-wrap .form .btn-box-wrap {
  margin-top: 5rem;
  display: grid;
  grid-template-columns: max-content min-content;
  align-items: center;
}
.create-pet-detail-wrap .form .btn-box-wrap .btn-reuse {
  margin-right: 3rem;
  justify-self: flex-start;
  min-width: 20rem;
}
.create-pet-detail-wrap .form .btn-box-wrap .cancel-text {
  cursor: pointer;
}
.create-pet-detail-wrap .form .input-border-select {
  padding: 0.7rem 1rem;
  border: 0.1rem solid var(--border-color);
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
}
.create-pet-detail-wrap .form .input-border-select > * {
  border: none !important;
  background-color: #f5f7f7;
}
.create-pet-detail-wrap .form .three-group-boxes {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  grid-column-gap: 4rem;
}
.create-pet-detail-wrap .form .two-group-boxes {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-column-gap: 4rem;
}
.create-pet-detail-wrap .form .btn-reuse {
  padding: 1.4rem 3rem;
  width: 70%;
  align-self: center;
}
.create-pet-detail-wrap .form .picture-detail-box {
  width: 100%;
  display: flex;
}
.create-pet-detail-wrap .form .picture-detail-box .picture-box {
  flex: 0 0 30%;
}
.create-pet-detail-wrap .form .picture-detail-box .picture-box .form-group {
  display: flex;
  flex-direction: column;
}
.create-pet-detail-wrap .form .picture-detail-box .picture-box .form-group .text {
  font-size: 1.45rem;
}
.create-pet-detail-wrap .form .picture-detail-box .picture-box .form-group .file-input {
  display: none;
}
.create-pet-detail-wrap .form .picture-detail-box .picture-box .form-group .file-label {
  width: 12rem;
  height: 12rem;
  margin-top: 1rem;
  border-radius: 50%;
  background-image: url("../../../assets/create-pet-img-default.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}
.create-pet-detail-wrap .form .form-group {
  display: flex;
}
.create-pet-detail-wrap .form .form-group .input-wrap {
  border: 0.1rem solid var(--border-color);
  border-radius: 0.5rem;
  padding: 0.3rem 1rem;
}
.create-pet-detail-wrap .form .form-group .input {
  margin: 1rem 0rem 3rem 0rem;
  background-color: #f5f7f7;
  border: none;
  outline: none;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  width: 100%;
}
.create-pet-detail-wrap .form .form-group .input::-moz-placeholder {
  color: #bbbbbb;
}
.create-pet-detail-wrap .form .form-group .input::placeholder {
  color: #bbbbbb;
}
.create-pet-detail-wrap .form .form-group .input:focus {
  border: none;
  outline: none;
}
.create-pet-detail-wrap .form .form-group .label {
  margin-right: 0.7rem;
  cursor: pointer;
}
.create-pet-detail-wrap .form .form-group .label-calendar {
  margin-left: 0.7rem;
  margin-right: unset;
}
.create-pet-detail-wrap .form .form-group .input-calender {
  border: unset;
  padding: 1.2rem 0rem;
  color: rgba(66, 66, 66, 0.5);
  background-color: #f5f7f7;
  order: -1;
}
.create-pet-detail-wrap .form .form-group .input-calender:focus {
  border: none;
  outline: none;
}
.create-pet-detail-wrap .form .form-group-text-area {
  width: 100%;
}
.create-pet-detail-wrap .form .form-group {
  display: flex;
  flex-direction: column;
}
.create-pet-detail-wrap .form .form-group .input-wrap {
  border: 0.1rem solid var(--border-color);
  border-radius: 0.8rem;
  padding: 0.5rem 1rem;
}
.create-pet-detail-wrap .form .form-group .input-wrap .input {
  margin: unset;
}
.create-pet-detail-wrap .form .form-group .label {
  margin-bottom: 0.5rem;
}
.create-pet-detail-wrap .form .form-group .group-two {
  display: flex;
  flex-direction: row;
  border: 0.1rem solid var(--border-color);
  border-radius: 0.5rem;
  padding: 0.3rem 1rem;
  align-items: center;
}
.create-pet-detail-wrap .form .form-group .group-two > :last-child {
  flex: 1;
}
.create-pet-detail-wrap .form .form-group .input-border {
  border: 0.1rem solid var(--border-color);
  padding: 1rem 1rem;
  width: 70%;
  border-radius: 0.5rem;
}
.create-pet-detail-wrap .form .form-group .input-border:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}
.create-pet-detail-wrap .form .distinguish-box .form-group {
  display: flex;
  flex-direction: column;
  border: 0.1rem solid var(--border-color);
  padding: 0.8rem 1rem;
  border-radius: 0.8rem;
  margin-top: 1rem;
}
.create-pet-detail-wrap .form .distinguish-box .form-group .input {
  margin: unset;
}
.create-pet-detail-wrap .form .distinguish-box .form-group .input-border-describtion {
  border: 0.1rem solid var(--border-color);
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
}
.create-pet-detail-wrap .form .distinguish-box .form-group .input-border-describtion:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}
.create-pet-detail-wrap .form .distinguish-box .form-group .label {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}
.create-pet-detail-wrap .form .distinguish-box .form-group .input-border {
  border: 0.1rem solid var(--border-color);
  padding: 0.8rem 2rem;
  border-radius: 0.5rem;
}
.create-pet-detail-wrap .form .distinguish-box .form-group .input-border:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}
.create-pet-detail-wrap .form .form-group {
  display: flex;
  flex-direction: column;
}
.create-pet-detail-wrap .form .form-group .input-border-describtion {
  border: 0.1rem solid var(--border-color);
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
}
.create-pet-detail-wrap .form .form-group .input-border-describtion:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}
.create-pet-detail-wrap .form .form-group .label {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}
.create-pet-detail-wrap .form .form-group .input-border {
  border: 0.1rem solid var(--border-color);
  padding: 0.8rem 2rem;
  border-radius: 0.5rem;
}
.create-pet-detail-wrap .form .form-group .input-border:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}/*# sourceMappingURL=CreatePetDetails.css.map */