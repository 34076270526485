.service-provider-profile-detail-box {
  display: flex;
  flex-direction: column;
}
.service-provider-profile-detail-box .bottom-box {
  padding-top: 3rem;
  margin-top: 2rem;
  border-top: 0.1rem solid rgba(69, 93, 95, 0.25);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 2rem;
}
.service-provider-profile-detail-box .bottom-box .box {
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
}
.service-provider-profile-detail-box .bottom-box .box .light {
  opacity: 0.5;
  margin-bottom: 0.5rem;
}
.service-provider-profile-detail-box .bottom-box .box .bold {
  font-weight: 700;
}
.service-provider-profile-detail-box .middle-box {
  display: flex;
  margin: 4rem 0rem;
}
.service-provider-profile-detail-box .middle-box .box {
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
}
.service-provider-profile-detail-box .middle-box .box .light {
  opacity: 0.5;
  margin-bottom: 0.5rem;
}
.service-provider-profile-detail-box .middle-box .box .bold {
  font-weight: 700;
  font-size: 1.2rem;
}
.service-provider-profile-detail-box .middle-box .box .green {
  color: #00c013;
}
.service-provider-profile-detail-box .middle-box .left-box {
  flex: 0 0 55%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem;
  border-right: unset;
}
.service-provider-profile-detail-box .middle-box .right-box {
  flex: 1;
}
.service-provider-profile-detail-box .middle-box .right-box .box > *:not(:last-child) {
  margin-bottom: 1.5rem;
}
.service-provider-profile-detail-box .top-box {
  display: flex;
  align-items: center;
}
.service-provider-profile-detail-box .top-box .img-wrap {
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 3rem;
}
.service-provider-profile-detail-box .top-box .text-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem;
}
.service-provider-profile-detail-box .top-box .text-wrap .rate-clinic-box {
  display: flex;
  align-items: center;
}
.service-provider-profile-detail-box .top-box .text-wrap .rate-clinic-box .clinic {
  padding: 0.7rem 1.5rem;
  border-radius: 2rem;
  background-color: rgba(255, 167, 44, 0.15);
  margin-right: 1.5rem;
  color: #ffa72c;
  font-weight: 700;
  font-size: 1.25rem;
}
.service-provider-profile-detail-box .top-box .text-wrap .rate-clinic-box .rate-box {
  display: flex;
  align-items: center;
}
.service-provider-profile-detail-box .top-box .text-wrap .rate-clinic-box .rate-box .text {
  font-size: 1.3rem;
  font-weight: 700;
}
.service-provider-profile-detail-box .top-box .text-wrap .box {
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
}
.service-provider-profile-detail-box .top-box .text-wrap .box .light {
  opacity: 0.5;
  margin-bottom: 0.5rem;
}
.service-provider-profile-detail-box .top-box .text-wrap .box .bold {
  font-weight: 700;
}

.service-provider-document-wrap-box {
  height: 50rem;
  width: 100%;
  overflow-y: scroll;
}
.service-provider-document-wrap-box .documents-box {
  display: flex;
  flex-direction: column;
}
.service-provider-document-wrap-box .documents-box > *:not(:last-child) {
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  border-bottom: 0.1rem solid rgba(69, 93, 95, 0.25);
}
.service-provider-document-wrap-box .documents-box .child-wrap {
  display: flex;
  flex-direction: column;
}
.service-provider-document-wrap-box .documents-box .child-wrap .name {
  margin-bottom: 1rem;
  font-size: 1.3rem;
}
.service-provider-document-wrap-box .documents-box .child-wrap .bottom-box {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 3rem;
}
.service-provider-document-wrap-box .documents-box .child-wrap .bottom-box .img-wrap {
  width: 25rem;
  height: 25rem;
  background-position: left center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 1rem;
}
.service-provider-document-wrap-box .documents-box .child-wrap .bottom-box .right-box {
  display: flex;
  flex-direction: column;
}
.service-provider-document-wrap-box .documents-box .child-wrap .bottom-box .right-box > *:not(:last-child) {
  margin-bottom: 2rem;
}
.service-provider-document-wrap-box .documents-box .child-wrap .bottom-box .right-box .btn-box {
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
}
.service-provider-document-wrap-box .documents-box .child-wrap .bottom-box .right-box .btn-box .btn-one {
  margin-right: 3rem;
}
.service-provider-document-wrap-box .documents-box .child-wrap .bottom-box .right-box .box {
  font-size: 1.2rem;
}
.service-provider-document-wrap-box .documents-box .child-wrap .bottom-box .right-box .box .light {
  color: #424242;
  margin-bottom: 0.7rem;
  opacity: 0.5;
}
.service-provider-document-wrap-box .documents-box .child-wrap .bottom-box .right-box .box .bold {
  font-weight: 700;
}
.service-provider-document-wrap-box .documents-box .child-wrap .bottom-box .right-box .box .green {
  color: #00C013;
}
.service-provider-document-wrap-box .documents-box .child-wrap .bottom-box .right-box .box .red {
  color: #DE0B0B;
}
.service-provider-document-wrap-box .documents-box .child-wrap .bottom-box .right-box .box .yellow {
  color: #FFA72C;
}/*# sourceMappingURL=ServiceProviderProfileModal.css.map */