.auth-form-wrap {
  flex: 1;
  margin-top: 3rem;
}
.auth-form-wrap .form {
  display: flex;
  flex-direction: column;
}
.auth-form-wrap .form > *:not(:last-child) {
  margin-bottom: 2rem;
}
.auth-form-wrap .form .error {
  margin-top: -1.5rem;
  color: #DE0B0B;
  text-transform: capitalize;
  font-size: 1.45rem;
  height: 2rem;
}
.auth-form-wrap .form .progress-box {
  display: grid;
  grid-template-columns: min-content 1fr;
}
.auth-form-wrap .form .progress-box .text {
  font-size: 1.2rem;
  width: 13rem;
}
.auth-form-wrap .form .rember-login-box {
  align-self: center;
}
.auth-form-wrap .form .rember-login-box .text {
  font-size: 1.3rem;
}
.auth-form-wrap .form .rember-login-box .text span {
  margin-left: 1rem;
  cursor: pointer;
  color: var(--dark-yellow-color);
  font-weight: 500;
}
.auth-form-wrap .form .form-group {
  display: flex;
  align-items: center;
  position: relative;
  border: 0.1rem solid rgba(66, 66, 66, 0.25);
  border-radius: 0.8rem;
  padding: 0rem 1rem;
}
.auth-form-wrap .form .form-group .show-hide {
  display: grid;
  place-items: center;
  padding-right: 1.5rem;
}
.auth-form-wrap .form .form-group .show-hide .icon {
  color: rgba(66, 66, 66, 0.35);
  cursor: pointer;
  width: 2rem;
  height: 2rem;
}
.auth-form-wrap .form .form-group .placeholder {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 10%;
  color: rgba(166, 177, 189, 0.75);
  font-size: 1.3rem;
  text-transform: uppercase;
  background-color: var(--white-color);
  padding: 0rem 1rem;
  transition: transform 0.5s;
}
.auth-form-wrap .form .form-group .placeholder-active {
  visibility: visible;
  transform: translateY(-50%);
}
.auth-form-wrap .form .form-group .form-label {
  margin-right: 0.2rem;
  flex: 0 0 7%;
  cursor: pointer;
}
.auth-form-wrap .form .form-group .form-label .img-box {
  width: 2rem;
  height: 2rem;
}
.auth-form-wrap .form .form-group .form-label .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.auth-form-wrap .form .form-group .form-input {
  border: none;
  flex: 1;
  padding: 1.5rem 1rem;
  outline: none;
  border-radius: 1.3rem;
  background-color: transparent;
  font-size: 1.5rem;
}
.auth-form-wrap .form .form-group .form-input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.25);
  font-size: 1.3rem;
  text-transform: uppercase;
}
.auth-form-wrap .form .form-group .form-input::placeholder {
  color: rgba(0, 0, 0, 0.25);
  font-size: 1.3rem;
  text-transform: uppercase;
}
.auth-form-wrap .form .form-group .form-input:focus::-moz-placeholder {
  visibility: hidden;
}
.auth-form-wrap .form .form-group .form-input:focus::placeholder {
  visibility: hidden;
}
.auth-form-wrap .form .form-group .form-input:focus ~ .placeholder {
  visibility: visible;
  transform: translateY(-50%);
}
.auth-form-wrap .form .form-group .phone-input {
  padding: 0.5rem;
  color: #a5b1bd;
}
.auth-form-wrap .form .form-group .input-class {
  border: none;
  color: #a5b1bd;
}
@media only screen and (max-width: 38em) {
  .auth-form-wrap .form .form-group .input-class {
    width: 100%;
  }
}
.auth-form-wrap .form .form-group .drop-down-class {
  background-color: transparent;
  border: unset;
}
.auth-form-wrap .form .form-group .pin-field {
  border-radius: 1.5rem;
  font-size: 2rem;
  height: 5rem;
  outline: none;
  text-align: center;
  transition-duration: 250ms;
  transition-property: background, color, border, box-shadow, transform;
  width: 16.6666666667%;
  border: 0.05rem solid #a5b1bd;
}
@media only screen and (max-width: 24em) {
  .auth-form-wrap .form .form-group .pin-field {
    height: 4rem;
  }
}
.auth-form-wrap .form .form-group .pin-field:not(:last-child) {
  margin-right: 1rem;
}
.auth-form-wrap .form .form-group .pin-field:focus {
  border-color: #0069ff;
  outline: none;
  transform: scale(1.05);
}
.auth-form-wrap .form .form-group .pin-field:invalid {
  animation: shake 3 linear 75ms;
  border-color: rgb(220, 53, 69);
  box-shadow: 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.auth-form-wrap .form .form-group .pin-field:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.auth-form-wrap .form .form-group swd-pin-field[completed] .pin-field {
  border-color: blue;
  background-color: white;
}
.auth-form-wrap .form .form-submit {
  margin-top: 3rem;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.2rem 3rem;
  width: 40%;
  border-radius: 0.8rem;
  background-color: #84bfcd;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  color: var(--white-color);
  font-weight: 600;
  font-size: 1.3rem;
}
.auth-form-wrap .form .form-submit:hover {
  transform: translateY(-0.15rem);
}
.auth-form-wrap .form .forget-rem-password-box {
  margin-top: -1rem;
  display: flex;
  align-items: center;
}
.auth-form-wrap .form .forget-rem-password-box .rem-box {
  display: flex;
  align-items: center;
}
.auth-form-wrap .form .forget-rem-password-box .rem-box .check {
  display: none;
}
.auth-form-wrap .form .forget-rem-password-box .rem-box .check:checked ~ .label span::after {
  visibility: visible;
}
.auth-form-wrap .form .forget-rem-password-box .rem-box .label {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  cursor: pointer;
  font-weight: 300;
  opacity: 0.75;
}
.auth-form-wrap .form .forget-rem-password-box .rem-box .label span {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
  border: 0.15rem solid var(--black-color);
  border-radius: 50%;
  position: relative;
}
.auth-form-wrap .form .forget-rem-password-box .rem-box .label span::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--black-color);
  width: 70%;
  height: 70%;
  border-radius: 50%;
  visibility: hidden;
}
.auth-form-wrap .form .forget-rem-password-box .link {
  color: var(--dark-yellow-color);
  margin-left: auto;
  font-size: 1.25rem;
  text-decoration: none;
}/*# sourceMappingURL=AuthForm.css.map */