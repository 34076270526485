.sidebar-menu-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 100;
  background-color: var(--dark-yellow-color);
  display: flex;
  flex-direction: column;
  padding: 2rem 0rem;
  border-radius: 1rem;
}
.sidebar-menu-wrap .logo-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sidebar-menu-wrap .logo-box .img-box {
  width: 12rem;
  height: 3rem;
  margin-bottom: 1rem;
  cursor: pointer;
}
.sidebar-menu-wrap .logo-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.sidebar-menu-wrap .logo-box .text {
  color: var(--white-color);
  font-weight: 500;
  background-color: rgba(69, 93, 95, 0.25);
  padding: 0.4rem 1rem;
  border-radius: 0.7rem;
  text-align: center;
  cursor: pointer;
  text-transform: capitalize;
  font-size: 1.3rem;
}
.sidebar-menu-wrap .list-box {
  flex: 1;
  padding-bottom: 2rem;
}
.sidebar-menu-wrap .list-box .content-box {
  width: 100%;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  background-color: var(--dark-yellow-color);
  border-radius: 1rem;
}
.sidebar-menu-wrap .list-box .content-box .box {
  display: flex;
  flex-direction: column;
}
.sidebar-menu-wrap .list-box .content-box .box .title-box {
  margin-bottom: 1.5rem;
  display: grid;
  grid-template-columns: min-content 1fr;
  padding: 0rem 3rem;
  grid-column-gap: 1rem;
  align-items: center;
}
.sidebar-menu-wrap .list-box .content-box .box .title-box .title {
  color: var(--white-color);
  font-size: 1.5rem;
}
.sidebar-menu-wrap .list-box .content-box .box .title-box span {
  height: 0.15rem;
  background-color: var(--white-color);
  display: inline-block;
  transform: translateY(0.1rem);
}
.sidebar-menu-wrap .list-box .content-box .box .dash {
  margin: 1rem 0rem 1.5rem 0rem;
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  grid-gap: 0.7rem;
  overflow-x: hidden;
}
.sidebar-menu-wrap .list-box .content-box .box .dash span {
  height: 0.2rem;
  background-color: var(--white-color);
  justify-content: space-between;
}
.sidebar-menu-wrap .list-box .content-box .box .link-box {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--white-color);
  margin-bottom: 1rem;
  padding: 1rem 0rem 1rem 3rem;
  opacity: 0.5;
  cursor: pointer;
  position: relative;
  transition: all 0.3s;
}
.sidebar-menu-wrap .list-box .content-box .box .link-box > * {
  position: relative;
  z-index: 3;
}
.sidebar-menu-wrap .list-box .content-box .box .link-box:hover {
  opacity: 1;
}
.sidebar-menu-wrap .list-box .content-box .box .link-box:hover::after {
  transform: translateY(-50%) scaleY(1);
}
.sidebar-menu-wrap .list-box .content-box .box .link-box::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0;
  width: 0.9rem;
  height: 70%;
  transform: translateY(-50%) scaleY(0);
  background-color: var(--white-color);
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  transform-origin: bottom;
  transition: transform 0.3s;
}
.sidebar-menu-wrap .list-box .content-box .box .link-box .img-box {
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  margin-right: 1.5rem;
}
.sidebar-menu-wrap .list-box .content-box .box .link-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.sidebar-menu-wrap .list-box .content-box .box .link-box .name {
  font-weight: 700;
  font-size: 1.5rem;
  cursor: pointer;
}
.sidebar-menu-wrap .list-box .content-box .box .link-box-active {
  opacity: 1;
}
.sidebar-menu-wrap .list-box .content-box .box .link-box-active::after {
  transform: translateY(-50%) scaleY(1);
}/*# sourceMappingURL=SidebarMenu.css.map */