.reuseble-table-wrap {
  margin-top: 5rem;
}
.reuseble-table-wrap .table {
  width: 100%;
  table-layout: auto;
}
.reuseble-table-wrap .table thead tr {
  background-color: #f5f7f7;
}
.reuseble-table-wrap .table thead tr > *:first-child {
  border-top-left-radius: 0.6rem;
  border-bottom-left-radius: 0.6rem;
}
.reuseble-table-wrap .table thead tr > *:last-child {
  border-top-right-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
}
.reuseble-table-wrap .table thead tr th {
  padding: 1.4rem 0rem;
  text-align: left;
  padding-left: 2rem;
  font-weight: 500;
  color: #424242;
  font-size: 1.3rem;
}
.reuseble-table-wrap .table thead tr th .icon {
  transform: translateY(0.5rem);
  width: 2rem;
  height: 2rem;
}
.reuseble-table-wrap .table thead tr .action {
  width: 4rem;
  padding-left: unset;
}
.reuseble-table-wrap .table tbody tr {
  position: relative;
}
.reuseble-table-wrap .table tbody tr .action {
  position: relative;
  z-index: 3;
}
.reuseble-table-wrap .table tbody tr .blog-td {
  display: flex;
  align-items: center;
}
.reuseble-table-wrap .table tbody tr .blog-td .img-title-box {
  display: flex;
  align-items: center;
}
.reuseble-table-wrap .table tbody tr .blog-td .img-title-box .img-box {
  width: 7rem;
  height: 7rem;
  margin-right: 1.5rem;
}
.reuseble-table-wrap .table tbody tr .blog-td .img-title-box .text-box {
  display: flex;
  flex-direction: column;
}
.reuseble-table-wrap .table tbody tr .blog-td .img-title-box .text-box .header {
  padding: 1rem 2rem;
  border-radius: 0.7rem;
  font-size: 1.2rem;
  align-self: flex-start;
  margin-bottom: 0.8rem;
}
.reuseble-table-wrap .table tbody tr .blog-td .img-title-box .text-box .heading-one {
  background-color: rgba(255, 167, 44, 0.15);
  color: #ffa72c;
}
.reuseble-table-wrap .table tbody tr .blog-td .img-title-box .text-box .heading-two {
  background-color: rgba(132, 191, 205, 0.15);
  color: #84bfcd;
}
.reuseble-table-wrap .table tbody tr .blog-td .img-title-box .text-box .regular {
  color: #424242;
  background-color: rgba(66, 66, 66, 0.15);
}
.reuseble-table-wrap .table tbody tr .blog-td .img-title-box .text-box .title {
  font-weight: 500;
  font-size: 1.65rem;
}
.reuseble-table-wrap .table tbody tr .img-text-td .img-text-box {
  display: flex;
  align-items: center;
}
.reuseble-table-wrap .table tbody tr .img-text-td .img-text-box .img-box {
  width: 5rem;
  height: 5rem;
  margin-right: 1rem;
}
.reuseble-table-wrap .table tbody tr .img-text-td .img-text-box .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
}
.reuseble-table-wrap .table tbody tr td {
  padding: 2.5rem 0rem;
  text-align: left;
  padding-left: 2rem;
  font-weight: 500;
  color: #424242;
  font-size: 1.3rem;
  border-bottom: 0.1rem solid rgba(66, 66, 66, 0.1);
  position: relative;
}
.reuseble-table-wrap .table tbody tr td .drop-wrap {
  background-color: rgba(69, 93, 95, 0.15);
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.reuseble-table-wrap .table tbody tr td .drop-wrap .icon {
  color: #455d5f;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.reuseble-table-wrap .table tbody tr td .drop-wrap .drop-box {
  position: absolute;
  top: 100%;
  right: 2rem;
  width: 17rem;
  background-color: var(--white-color);
  box-shadow: 0.1rem 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.1);
  border-radius: 0.8rem;
  z-index: 3;
  transform: scale(0);
  transform-origin: top right;
  transition: 0.5s;
  padding: 2.5rem 2rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-evenly;
}
.reuseble-table-wrap .table tbody tr td .drop-wrap .drop-box > *:not(:last-child) {
  margin-bottom: 2rem;
}
.reuseble-table-wrap .table tbody tr td .drop-wrap .drop-box .text {
  font-weight: 400;
  cursor: pointer;
}
.reuseble-table-wrap .table tbody tr td .drop-wrap .drop-box-big {
  width: 23rem;
}
.reuseble-table-wrap .table tbody tr td .drop-wrap .drop-box-show {
  transform: scale(1);
}
.reuseble-table-wrap .table tbody tr .status {
  position: relative;
  z-index: 1;
}
.reuseble-table-wrap .table tbody tr .status .status-bg {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  min-width: 12rem;
}
.reuseble-table-wrap .table tbody tr .status .status-bg span {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(0%, -50%);
  padding: 0.8rem 1.5rem;
  border-radius: 0.8rem;
  font-size: 1.25rem;
  z-index: 1;
  text-transform: capitalize;
}
.reuseble-table-wrap .table tbody tr .status .status-red span {
  background-color: rgba(222, 11, 11, 0.15);
  color: #de0b0b;
}
.reuseble-table-wrap .table tbody tr .status .status-pending span {
  background-color: rgba(255, 167, 44, 0.15);
  color: var(--dark-yellow-color);
}
.reuseble-table-wrap .table tbody tr .status .status-deliver span {
  background-color: rgba(0, 192, 19, 0.15);
  color: #00c013;
}
.reuseble-table-wrap .table tbody tr .status .status-black span {
  background-color: rgba(69, 93, 95, 0.15);
  color: #455D5F;
}
.reuseble-table-wrap .table tbody tr .status .status-yellow span {
  background-color: rgba(255, 167, 44, 0.15);
  color: #FFA72C;
}
.reuseble-table-wrap .table .table-body > *:last-child td .drop-wrap .drop-box, .reuseble-table-wrap .table .table-body > *:nth-last-child(2) td .drop-wrap .drop-box, .reuseble-table-wrap .table .table-body > *:nth-last-child(3) td .drop-wrap .drop-box {
  top: unset;
  bottom: 2rem;
  transform-origin: bottom right;
}
.reuseble-table-wrap .table-two {
  table-layout: fixed;
}
.reuseble-table-wrap .pagination-wrap-box {
  display: flex;
  width: 100%;
  padding: 1.2rem 2rem;
  background-color: #F5F7F7;
  align-items: center;
  border-radius: 0.8rem;
  margin: 7rem 0rem;
  color: #424242;
  opacity: 0.5;
  font-size: 1.35rem;
  font-weight: 600;
}
.reuseble-table-wrap .pagination-wrap-box .text {
  margin-left: auto;
}
.reuseble-table-wrap .pagination-wrap-box .react-select {
  margin: 0rem 2rem;
  width: 7rem;
}
.reuseble-table-wrap .pagination-wrap-box .react-select > *:nth-child(3) {
  border: 0.15rem solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
}
.reuseble-table-wrap .pagination-wrap-box .prev-next-box {
  margin-left: 2.5rem;
  padding-left: 2rem;
  border-left: 0.1rem solid rgba(0, 0, 0, 0.25);
  display: flex;
}
.reuseble-table-wrap .pagination-wrap-box .prev-next-box > *:first-child {
  margin-right: 1.5rem;
}
.reuseble-table-wrap .pagination-wrap-box .prev-next-box .box {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 0.1rem solid #424242;
  display: grid;
  place-items: center;
  opacity: 0.35;
  transition: all 0.3s;
}
.reuseble-table-wrap .pagination-wrap-box .prev-next-box .box .icon {
  width: 1.5rem;
  height: 1.5rem;
}
.reuseble-table-wrap .pagination-wrap-box .prev-next-box .box-active {
  opacity: 1;
  cursor: pointer;
}
.reuseble-table-wrap .pagination-wrap-box .prev-next-box .box-active:hover {
  transform: scale(1.1);
}
.reuseble-table-wrap .pagination-wrap-box .from-to-box {
  display: flex;
  align-items: center;
}
.reuseble-table-wrap .pagination-wrap-box .from-to-box > *:not(:last-child) {
  margin-right: 0.7rem;
}/*# sourceMappingURL=ReuseableTable.css.map */