@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400;500;600;800&family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
:root {
  --light-yellow-color: rgba(255, 190, 12, 0.15);
  --dark-yellow-color: #ffa72c;
  --black-color: #424242;
  --fade-black-color: #758089;
  --primary-blue-color: #84bfcd;
  --white-color: #ffffff;
  --box-shadow-one: 0.1rem 0.1rem 8rem 0.1rem rgba(0, 0, 0, 0.1);
  --box-shadow-two: 0.1rem 0.1rem 8rem 0.1rem rgba(0, 0, 0, 0.05),
    -0.1rem -0.1rem 8rem 0.1rem rgba(0, 0, 0, 0.05);
  --border-color: rgba(66, 66, 66, 0.25);
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}
@media only screen and (max-width: 75em) {
  html {
    font-size: 55%;
  }
}

body {
  font-size: 1.4rem;
  font-family: "Outfit", sans-serif;
  font-weight: 300;
  /* overflow-x: hidden !important; */
  position: relative;
  width: 100vw;
  overscroll-behavior: none;
  color: var(--black-color);
  overflow-x: hidden;
  background-color: var(--white-color);
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
body::-webkit-scrollbar {
  display: none;
}

.img-box {
  width: 3rem;
  height: 3rem;
}
.img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}/*# sourceMappingURL=index.css.map */