

.create-pet-parent-modal-wrap{
    width: 80rem;
    min-height: 55rem;
    background-color: #ffffff;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
  
    .close-icon {
      position: absolute;
      top: 2rem;
      right: 2rem;
      // background-color: red;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      border: 0.1rem solid var(--black-color);
      display: grid;
      place-items: center;
      transition: all 0.3s;
      cursor: pointer;
  
      &:hover {
        transform: scale(1.04);
      }
  
      .icon {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .title{
        align-self: center;
        margin-top: 4rem;
        font-weight: 500;
    }

    .form{
        width: 80%;
        margin: 3rem auto;
        display: flex;
        flex-direction: column;
        margin-top: 3rem;
        // background-color: red;

    
        &>*:not(:last-child){
            margin-bottom: 2.5rem;
        }
    
    
        .input-border-select{
            padding: .7rem 1rem ;
            border: 0.1rem solid var(--border-color);
            border-radius: 0.5rem;
            padding: .5rem 1rem;
    
            &>*{
                border: none !important;
                background-color: #F5F7F7;
            }
        }
    
        .three-group-boxes{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap: 4rem;
        }
    
        .two-group-boxes{
            display: grid;
            grid-template-columns: 2fr 1fr;
            grid-column-gap: 4rem;
        }
    
         .btn-reuse{
            align-self: flex-start;
            margin-top: 3rem;
        }
    
    
        .picture-detail-box{
            width: 100%;
            // min-height: 20rem;
            // background-color: red;
            display: flex;
          }
      
    
        .form-group {
          display: flex;
        //   width: 70%;
    
      
    
          .input-wrap{
            border: 0.1rem solid var(--border-color);
            border-radius: 0.5rem;
            padding: .3rem 1rem;
          }
    
          .input {
            margin: 1rem 0rem 3rem 0rem;
            background-color: #F5F7F7;
            border: none;
            outline: none;
            padding: 1rem 1.5rem;
            border-radius: 1rem;
            width: 100%;
        
            &::placeholder {
              color: #bbbbbb;
            }
        
            &:focus {
              border: none;
              outline: none;
            }
          }
    
          .label {
            margin-right: 0.7rem;
            cursor: pointer;
          }
    
          .label-calendar{
            margin-left: 0.7rem;
            margin-right: unset;
          }
    
          .input-calender {
            border: unset;
            padding: 1.2rem 0rem;
            color: rgba(66, 66, 66, 0.5);
            background-color: #F5F7F7;
            order: -1;
    
            &:focus {
              border: none;
              outline: none;
            }
          }
        }
    
        .form-group-text-area{
            width: 100%;
        }
    
        .form-group {
          display: flex;
          flex-direction: column;
    
          .input-wrap{
            border: 0.1rem solid var(--border-color);
            border-radius: 0.8rem;
            padding: .5rem 1rem;
            width: 100%;
            // background-color: red;
    
            .input{
                margin: unset;
            }
          }
    
          .label {
            margin-bottom: 0.5rem;
          }
    
          .group-two{
            display: flex;
            flex-direction: row;
            border: 0.1rem solid var(--border-color);
            border-radius: 0.5rem;
            padding: .3rem 1rem;
            align-items: center;
    
            &>:last-child{
                flex: 1;
            }
          }
    
          .input-border {
            border: 0.1rem solid var(--border-color);
            padding: 1rem 1rem;
            width: 100%;
            border-radius: 0.5rem;
    
            &:focus {
              border: 0.1rem solid var(--border-color);
              outline: none;
            }
          }
        }
    
        .distinguish-box{
            // background-color: red;
            .form-group {
                display: flex;
                flex-direction: column;
                // background-color: red;
                  border: 0.1rem solid var(--border-color);
                  padding: 0.8rem 1rem;
                  border-radius: 0.8rem;
                  margin-top: 1rem;
    
                  .input{
                    margin: unset;
                }
          
                .input-border-describtion {
                  border: 0.1rem solid var(--border-color);
                  padding: 0.8rem 1rem;
                  border-radius: 0.5rem;
          
                  &:focus {
                    border: 0.1rem solid var(--border-color);
                    outline: none;
                  }
                }
          
                .label {
                  margin-bottom: 0.5rem;
                  font-size: 1.2rem;
                }
          
                .input-border {
                  border: 0.1rem solid var(--border-color);
                  padding: 0.8rem 2rem;
                  border-radius: 0.5rem;
          
                  &:focus {
                    border: 0.1rem solid var(--border-color);
                    outline: none;
                  }
                }
              }
        }
    
        .form-group {
          display: flex;
          flex-direction: column;
    
          .input-border-describtion {
            border: 0.1rem solid var(--border-color);
            padding: 0.8rem 1rem;
            border-radius: 0.5rem;
    
            &:focus {
              border: 0.1rem solid var(--border-color);
              outline: none;
            }
          }
    
          .label {
            margin-bottom: 0.5rem;
            font-size: 1.2rem;
          }
    
          .input-border {
            border: 0.1rem solid var(--border-color);
            padding: 0.8rem 2rem;
            border-radius: 0.5rem;
    
            &:focus {
              border: 0.1rem solid var(--border-color);
              outline: none;
            }
          }
        }
    }
}