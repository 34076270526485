.view-appointment-modal-wrap {
  width: 65rem;
  min-height: 65rem;
  background-color: var(--white-color);
  border-radius: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem 5rem 4rem;
}
.view-appointment-modal-wrap .btn-wrap {
  align-self: center;
  margin-top: 4rem;
}
.view-appointment-modal-wrap .close-icon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 0.1rem solid var(--black-color);
  display: grid;
  place-items: center;
  transition: all 0.3s;
  cursor: pointer;
}
.view-appointment-modal-wrap .close-icon:hover {
  transform: scale(1.04);
}
.view-appointment-modal-wrap .close-icon .icon {
  width: 1.5rem;
  height: 1.5rem;
}
.view-appointment-modal-wrap .content-box {
  display: flex;
  flex-direction: column;
}
.view-appointment-modal-wrap .content-box .content-title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  color: #424242;
  margin: 3rem 0rem 2rem 0rem;
}
.view-appointment-modal-wrap .content-box .select-content-box {
  margin: 2rem 0rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 65%;
  align-self: center;
  border-bottom: 0.1rem solid #bbbbbb;
  padding-bottom: 1rem;
}
.view-appointment-modal-wrap .content-box .select-content-box .item {
  display: flex;
  justify-content: center;
  position: relative;
  cursor: pointer;
}
.view-appointment-modal-wrap .content-box .select-content-box .item-active {
  color: #ffa72c;
  font-weight: 700;
}
.view-appointment-modal-wrap .content-box .select-content-box .item-active::after {
  position: absolute;
  content: "";
  width: 100%;
  bottom: 0;
  left: 0;
  height: 0.4rem;
  border-radius: 3rem;
  background-color: #ffa72c;
  transform: translateY(1.2rem);
}
.view-appointment-modal-wrap .content-box .note-detail-box {
  margin-top: 3rem;
}
.view-appointment-modal-wrap .content-box .note-detail-box .form-group {
  display: flex;
  flex-direction: column;
}
.view-appointment-modal-wrap .content-box .note-detail-box .form-group .input-border-describtion {
  border: 0.1rem solid var(--border-color);
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
}
.view-appointment-modal-wrap .content-box .note-detail-box .form-group .input-border-describtion:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}
.view-appointment-modal-wrap .content-box .note-detail-box .form-group .label {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}
.view-appointment-modal-wrap .content-box .note-detail-box .form-group .input-border {
  border: 0.1rem solid var(--border-color);
  padding: 0.8rem 2rem;
  border-radius: 0.5rem;
}
.view-appointment-modal-wrap .content-box .note-detail-box .form-group .input-border:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}
.view-appointment-modal-wrap .content-box .note-detail-box .form-group .input-wrap {
  border-radius: 0.5rem;
  padding: 0.3rem 1rem;
}
.view-appointment-modal-wrap .content-box .note-detail-box .form-group .input {
  margin: 1rem 0rem 3rem 0rem;
  background-color: #f5f7f7;
  border: none;
  outline: none;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  width: 100%;
}
.view-appointment-modal-wrap .content-box .note-detail-box .form-group .input::-moz-placeholder {
  color: #bbbbbb;
}
.view-appointment-modal-wrap .content-box .note-detail-box .form-group .input::placeholder {
  color: #bbbbbb;
}
.view-appointment-modal-wrap .content-box .note-detail-box .form-group .input:focus {
  border: none;
  outline: none;
}
.view-appointment-modal-wrap .content-box .detail-box {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}
.view-appointment-modal-wrap .content-box .detail-box .bottom-box .three-group {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  grid-row-gap: 3rem;
}
.view-appointment-modal-wrap .content-box .detail-box .bottom-box .btn-reuse {
  background-color: rgba(0, 192, 19, 0.15);
  color: #00c013;
  align-self: flex-end;
  justify-self: flex-start;
  margin-top: unset;
  margin-left: -5rem;
  border-radius: 2rem;
  padding: 1rem 2rem;
  transform: translateY(0.6rem);
}
.view-appointment-modal-wrap .content-box .detail-box .bottom-box .box {
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
}
.view-appointment-modal-wrap .content-box .detail-box .bottom-box .box .light {
  opacity: 0.5;
  margin-bottom: 0.5rem;
}
.view-appointment-modal-wrap .content-box .detail-box .bottom-box .box .bold {
  font-weight: 700;
}
.view-appointment-modal-wrap .content-box .detail-box .top-box {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  border-bottom: 0.1rem solid rgba(187, 187, 187, 0.25);
}
.view-appointment-modal-wrap .content-box .detail-box .top-box > *:not(:last-child) {
  margin-bottom: 3rem;
}
.view-appointment-modal-wrap .content-box .detail-box .top-box .three-group {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}
.view-appointment-modal-wrap .content-box .detail-box .top-box .four-group {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
}
.view-appointment-modal-wrap .content-box .detail-box .top-box .box {
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
}
.view-appointment-modal-wrap .content-box .detail-box .top-box .box .light {
  opacity: 0.5;
  margin-bottom: 0.5rem;
}
.view-appointment-modal-wrap .content-box .detail-box .top-box .box .bold {
  font-weight: 700;
}

.reassign-service-provider-wrap {
  width: 55rem;
  min-height: 45rem;
  background-color: var(--white-color);
  border-radius: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem 5rem 4rem;
}
.reassign-service-provider-wrap .btn-wrap {
  align-self: center;
  margin-top: 4rem;
}
.reassign-service-provider-wrap .close-icon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 0.1rem solid var(--black-color);
  display: grid;
  place-items: center;
  transition: all 0.3s;
  cursor: pointer;
}
.reassign-service-provider-wrap .close-icon:hover {
  transform: scale(1.04);
}
.reassign-service-provider-wrap .close-icon .icon {
  width: 1.5rem;
  height: 1.5rem;
}
.reassign-service-provider-wrap .content-box {
  display: flex;
  flex-direction: column;
}
.reassign-service-provider-wrap .content-box .btn-reuse {
  align-self: center;
  margin-top: 4rem;
  background-color: #84BFCD;
}
.reassign-service-provider-wrap .content-box .content-title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  color: #424242;
  margin: 3rem 0rem 2rem 0rem;
}
.reassign-service-provider-wrap .content-box .bottom-box {
  margin-top: 1rem;
}
.reassign-service-provider-wrap .content-box .bottom-box .form-group {
  display: flex;
  width: 70%;
}
.reassign-service-provider-wrap .content-box .bottom-box .form-group .input {
  margin: 1rem 0rem 3rem 0rem;
  background-color: #f5f7f7;
  border: none;
  outline: none;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  width: 100%;
}
.reassign-service-provider-wrap .content-box .bottom-box .form-group .input::-moz-placeholder {
  color: #bbbbbb;
}
.reassign-service-provider-wrap .content-box .bottom-box .form-group .input::placeholder {
  color: #bbbbbb;
}
.reassign-service-provider-wrap .content-box .bottom-box .form-group .input:focus {
  border: none;
  outline: none;
}
.reassign-service-provider-wrap .content-box .bottom-box .form-group .label {
  margin-right: 0.7rem;
  cursor: pointer;
}
.reassign-service-provider-wrap .content-box .bottom-box .form-group .input-calender {
  border: unset;
  padding: 1rem 0rem;
  color: rgba(66, 66, 66, 0.5);
}
.reassign-service-provider-wrap .content-box .bottom-box .form-group .input-calender:focus {
  border: none;
  outline: none;
}
.reassign-service-provider-wrap .content-box .bottom-box .form-group {
  display: flex;
  flex-direction: column;
}
.reassign-service-provider-wrap .content-box .bottom-box .form-group .label {
  margin-bottom: 0.5rem;
}
.reassign-service-provider-wrap .content-box .bottom-box .form-group .group-two {
  display: flex;
  flex-direction: row;
  border: 0.1rem solid var(--border-color);
  border-radius: 0.5rem;
  padding: 0.3rem 1rem;
  align-items: center;
}
.reassign-service-provider-wrap .content-box .bottom-box .form-group .group-two > :last-child {
  flex: 1;
}
.reassign-service-provider-wrap .content-box .bottom-box .form-group .input-border {
  border: 0.1rem solid var(--border-color);
  padding: 1rem 1rem;
  width: 70%;
  border-radius: 0.5rem;
}
.reassign-service-provider-wrap .content-box .bottom-box .form-group .input-border:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}
.reassign-service-provider-wrap .content-box .bottom-box .form-group {
  display: flex;
  width: 70%;
}
.reassign-service-provider-wrap .content-box .bottom-box .form-group .input {
  margin: 1rem 0rem 3rem 0rem;
  background-color: #F5F7F7;
  border: none;
  outline: none;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  width: 100%;
}
.reassign-service-provider-wrap .content-box .bottom-box .form-group .input::-moz-placeholder {
  color: #bbbbbb;
}
.reassign-service-provider-wrap .content-box .bottom-box .form-group .input::placeholder {
  color: #bbbbbb;
}
.reassign-service-provider-wrap .content-box .bottom-box .form-group .input:focus {
  border: none;
  outline: none;
}
.reassign-service-provider-wrap .content-box .bottom-box .form-group .label {
  margin-right: 0.7rem;
  cursor: pointer;
}
.reassign-service-provider-wrap .content-box .bottom-box .form-group .input-calender {
  border: unset;
  padding: 1rem 0rem;
  color: rgba(66, 66, 66, 0.5);
}
.reassign-service-provider-wrap .content-box .bottom-box .form-group .input-calender:focus {
  border: none;
  outline: none;
}
.reassign-service-provider-wrap .content-box .top-box {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  border-bottom: 0.1rem solid rgba(187, 187, 187, 0.25);
}
.reassign-service-provider-wrap .content-box .top-box .two-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}
.reassign-service-provider-wrap .content-box .top-box .two-group .btn-reuse {
  background-color: rgba(0, 192, 19, 0.15);
  color: #00c013;
  align-self: flex-end;
  justify-self: flex-start;
  margin-top: unset;
  margin-left: -10rem;
  border-radius: 2rem;
  padding: 1rem 2rem;
  transform: translateY(0.6rem);
}
.reassign-service-provider-wrap .content-box .top-box .two-group .box {
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
}
.reassign-service-provider-wrap .content-box .top-box .two-group .box .light {
  opacity: 0.5;
  margin-bottom: 0.5rem;
}
.reassign-service-provider-wrap .content-box .top-box .two-group .box .bold {
  font-weight: 700;
}/*# sourceMappingURL=ViewAppointmentModal.css.map */