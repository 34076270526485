.view-appointment-modal-wrap {
  width: 65rem;
  min-height: 65rem;
  background-color: var(--white-color);
  border-radius: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem 5rem 4rem;

  .btn-wrap {
    align-self: center;
    margin-top: 4rem;
  }

  .close-icon {
    position: absolute;
    top: 2rem;
    right: 2rem;
    // background-color: red;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 0.1rem solid var(--black-color);
    display: grid;
    place-items: center;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      transform: scale(1.04);
    }

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .content-box {
    display: flex;
    flex-direction: column;

    .content-title {
      text-align: center;
      font-size: 1.5rem;
      font-weight: 500;
      color: #424242;
      margin: 3rem 0rem 2rem 0rem;
    }

    .select-content-box {
      margin: 2rem 0rem;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 65%;
      align-self: center;
      border-bottom: 0.1rem solid #bbbbbb;
      padding-bottom: 1rem;

      .item {
        display: flex;
        justify-content: center;
        position: relative;
        cursor: pointer;
      }

      .item-active {
        color: #ffa72c;
        font-weight: 700;

        &::after {
          position: absolute;
          content: "";
          width: 100%;
          bottom: 0;
          left: 0;
          height: 0.4rem;
          border-radius: 3rem;
          background-color: #ffa72c;
          transform: translateY(1.2rem);
        }
      }
    }

    .note-detail-box {
      margin-top: 3rem;
      .form-group {
        display: flex;
        flex-direction: column;

        .input-border-describtion {
          border: 0.1rem solid var(--border-color);
          padding: 0.8rem 1rem;
          border-radius: 0.5rem;

          &:focus {
            border: 0.1rem solid var(--border-color);
            outline: none;
          }
        }

        .label {
          margin-bottom: 0.5rem;
          font-size: 1.2rem;
        }

        .input-border {
          border: 0.1rem solid var(--border-color);
          padding: 0.8rem 2rem;
          border-radius: 0.5rem;

          &:focus {
            border: 0.1rem solid var(--border-color);
            outline: none;
          }
        }

        .input-wrap {
          // border: 0.1rem solid var(--border-color);
          border-radius: 0.5rem;
          padding: 0.3rem 1rem;
        }

        .input {
          margin: 1rem 0rem 3rem 0rem;
          background-color: #f5f7f7;
          border: none;
          outline: none;
          padding: 1rem 1.5rem;
          border-radius: 1rem;
          width: 100%;

          &::placeholder {
            color: #bbbbbb;
          }

          &:focus {
            border: none;
            outline: none;
          }
        }
      }
    }

    .detail-box {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;

      .bottom-box {
        .three-group {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 2rem;
          grid-row-gap: 3rem;
        }

        .btn-reuse {
          background-color: rgba(0, 192, 19, 0.15);
          color: #00c013;
          align-self: flex-end;
          justify-self: flex-start;
          margin-top: unset;
          margin-left: -5rem;
          border-radius: 2rem;
          padding: 1rem 2rem;
          transform: translateY(0.6rem);
        }

        .box {
          display: flex;
          flex-direction: column;
          font-size: 1.25rem;

          .light {
            opacity: 0.5;
            margin-bottom: 0.5rem;
          }

          .bold {
            font-weight: 700;
          }
        }
      }

      .top-box {
        display: flex;
        flex-direction: column;
        margin-bottom: 3rem;
        padding-bottom: 3rem;
        border-bottom: 0.1rem solid rgba(187, 187, 187, 0.25);

        & > *:not(:last-child) {
          margin-bottom: 3rem;
        }

        .three-group {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 2rem;
        }

        .four-group {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 2rem;
        }

        .box {
          display: flex;
          flex-direction: column;
          font-size: 1.25rem;

          .light {
            opacity: 0.5;
            margin-bottom: 0.5rem;
          }

          .bold {
            font-weight: 700;
          }
        }
      }
    }
  }
}

.reassign-service-provider-wrap {
  width: 55rem;
  min-height: 45rem;
  background-color: var(--white-color);
  border-radius: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem 5rem 4rem;

  .btn-wrap {
    align-self: center;
    margin-top: 4rem;
  }

  .close-icon {
    position: absolute;
    top: 2rem;
    right: 2rem;
    // background-color: red;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 0.1rem solid var(--black-color);
    display: grid;
    place-items: center;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      transform: scale(1.04);
    }

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .content-box {
    display: flex;
    flex-direction: column;

    .btn-reuse{
        align-self: center;
        margin-top: 4rem;
        background-color: #84BFCD;
    }

    .content-title {
      text-align: center;
      font-size: 1.5rem;
      font-weight: 500;
      color: #424242;
      margin: 3rem 0rem 2rem 0rem;
    }

    .bottom-box {
      margin-top: 1rem;

      .form-group {
        display: flex;
        width: 70%;

        .input {
          margin: 1rem 0rem 3rem 0rem;
          background-color: #f5f7f7;
          border: none;
          outline: none;
          padding: 1rem 1.5rem;
          border-radius: 1rem;
          width: 100%;

          &::placeholder {
            color: #bbbbbb;
          }

          &:focus {
            border: none;
            outline: none;
          }
        }

        .label {
          margin-right: 0.7rem;
          cursor: pointer;
        }

        .input-calender {
          border: unset;
          padding: 1rem 0rem;
          color: rgba(66, 66, 66, 0.5);

          &:focus {
            border: none;
            outline: none;
          }
        }
      }



      .form-group {
        display: flex;
        flex-direction: column;

        .label {
          margin-bottom: 0.5rem;
        }

        .group-two {
          display: flex;
          flex-direction: row;
          border: 0.1rem solid var(--border-color);
          border-radius: 0.5rem;
          padding: 0.3rem 1rem;
          align-items: center;

          & > :last-child {
            flex: 1;
          }
        }

        .input-border {
          border: 0.1rem solid var(--border-color);
          padding: 1rem 1rem;
          width: 70%;
          border-radius: 0.5rem;

          &:focus {
            border: 0.1rem solid var(--border-color);
            outline: none;
          }
        }
      }

      .form-group {
        display: flex;
        width: 70%;
  
        .input {
          margin: 1rem 0rem 3rem 0rem;
          background-color: #F5F7F7;
          border: none;
          outline: none;
          padding: 1rem 1.5rem;
          border-radius: 1rem;
          width: 100%;
      
          &::placeholder {
            color: #bbbbbb;
          }
      
          &:focus {
            border: none;
            outline: none;
          }
        }
  
        .label {
          margin-right: 0.7rem;
          cursor: pointer;
        }
  
        .input-calender {
          border: unset;
          padding: 1rem 0rem;
          color: rgba(66, 66, 66, 0.5);
  
          &:focus {
            border: none;
            outline: none;
          }
        }
      }
  
    }

    .top-box {
      margin-top: 3rem;
      display: flex;
      flex-direction: column;
      margin-bottom: 3rem;
      padding-bottom: 3rem;
      border-bottom: 0.1rem solid rgba(187, 187, 187, 0.25);

      .two-group {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;

        .btn-reuse {
          background-color: rgba(0, 192, 19, 0.15);
          color: #00c013;
          align-self: flex-end;
          justify-self: flex-start;
          margin-top: unset;
          margin-left: -10rem;
          border-radius: 2rem;
          padding: 1rem 2rem;
          transform: translateY(0.6rem);
        }

        .box {
          display: flex;
          flex-direction: column;
          font-size: 1.25rem;

          .light {
            opacity: 0.5;
            margin-bottom: 0.5rem;
          }

          .bold {
            font-weight: 700;
          }
        }
      }
    }
  }
}
