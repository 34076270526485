.view-ticket-modal-wrap {
  width: 80rem;
  min-height: 50rem;
  background-color: var(--white-color);
  border-radius: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem 0rem 4rem;

  .close-icon {
    position: absolute;
    top: 2rem;
    right: 2rem;
    // background-color: red;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 0.1rem solid var(--black-color);
    display: grid;
    place-items: center;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      transform: scale(1.04);
    }

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .title {
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 2rem;
    align-self: center;
  }

  .bottom-box {
    flex: 1;
    // background-color: red;
    display: flex;

    .left-box {
      flex: 0 0 30%;
      display: flex;
      flex-direction: column;
      padding-top: 3rem;

      & > *:not(:last-child) {
        margin-bottom: 2rem;
      }

      .ticket-type-box {
        padding: 1rem 2rem;
        border-radius: 1rem;
        display: grid;
        place-items: center;
        align-self: flex-start;

        .ticket {
          font-size: 1.3rem;
          font-weight: 600;
        }
      }

      .ticket-type-box-yellow {
        background-color: rgba(0, 192, 19, 0.15);

        .ticket {
          color: #ffa72c;
        }

        .ticket {
          color: #00c013;
        }
      }

      .ticket-type-box-green {
        background-color: rgba(0, 192, 19, 0.15);

        .ticket {
          color: #00c013;
        }
      }

      .box {
        display: flex;
        flex-direction: column;
        font-size: 1.25rem;

        .light {
          opacity: 0.5;
          margin-bottom: 0.5rem;
        }

        .bold {
          font-weight: 700;
        }

        .bold-green {
          color: #00c013;
        }
      }
    }

    .right-box {
      flex: 1;
      // background-color: green;
      border: 0.1rem solid rgba(187, 187, 187, 0.25);
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      display: flex;
      flex-direction: column;
      height: 50rem;

      .top-content-box {
        flex: 0 0 87%;
        background-color: #f5f7f7;
        overflow-y: scroll;
        padding: 0rem 2rem;
        

        // .content{
        //     height: 79rem;
        //     background-color: red;
        // }
      }

      .bottom-content-box {
        flex: 1;
      }
    }
  }
}


.add-new-faq-modal-wrap{
  width: 50rem;
  min-height: 55rem;
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;

  .close-icon {
    position: absolute;
    top: 2rem;
    right: 2rem;
    // background-color: red;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 0.1rem solid var(--black-color);
    display: grid;
    place-items: center;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      transform: scale(1.04);
    }

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .title{
      align-self: center;
      margin-top: 4rem;
      font-weight: 500;
  }

  .form{
      width: 80%;
      margin: 3rem auto;
      display: flex;
      flex-direction: column;
      margin-top: 3rem;
      // background-color: red;

      .metrics-box {
          width: 80%;
          margin-top: -4rem;
      
          .title {
              font-size: 1.3rem;
              font-weight: 300;
              margin-bottom: .7rem;
          //   font-weight: 600;
          //   font-size: 1.8rem;
          //   margin-bottom: 1rem;
          //   text-transform: capitalize;
          }
      
          .detail-box {
              position: relative;
            min-height: 5rem;
            border: 0.1rem solid var(--border-color);
            border-radius: 1rem;
            padding: 2rem;
            cursor: pointer;
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
      
            &>*:not(:last-child) {
              margin-right: 1rem;
            }
      
            .info {
              color: #bbbbbb;
              position: absolute;
              top: 2rem;
              left: 2rem;
              z-index: 1;
            }
      
            .input-btn-box{
              // position: absolute;
              // position: absolute;
              // top: 2rem;
              // left: 2rem;
              // z-index: 3;
              display: flex;
              align-items: center;
              margin-left: 1.5rem;
      
              .metrics-input{
                  padding: 1rem;
                  border: .1rem solid var(--dark-yellow-color);
                  border-radius: .8rem;
                  // margin-right: 1rem;
      
                  &:focus{
                      outline: none;
                      border: .1rem solid var(--dark-yellow-color);
                  }
              }
      
              .icon{
                  color: var(--dark-yellow-color);
                  cursor: pointer;
                  transform: translateY(.7rem);
                  width: 2rem;
                  height: 2rem;
              }
            }
      
            .input-btn-box-active{
              padding: 1rem;
              border-radius: .6rem;
              box-shadow: var(--box-shadow-one);
              background-color: var(--white-color);
            }
          }
        }

  
      &>*:not(:last-child){
          margin-bottom: 2.5rem;
      }
  
  
      .input-border-select{
          padding: .7rem 1rem ;
          border: 0.1rem solid var(--border-color);
          border-radius: 0.5rem;
          padding: .5rem 1rem;
  
          &>*{
              border: none !important;
              background-color: #F5F7F7;
          }
      }
  
      .three-group-boxes{
          display: grid;
          grid-template-columns: 1.5fr 1.5fr;
          grid-column-gap: 4rem;
      }
  
      .two-group-boxes{
          display: grid;
          grid-template-columns: 1fr;
          grid-column-gap: 4rem;
      }
  
       .btn-reuse{
          align-self: center;
          margin-top: 3rem;
      }
  
  
      .picture-detail-box{
          width: 100%;
          // min-height: 20rem;
          // background-color: red;
          display: flex;
        }
    
  
      .form-group {
        display: flex;
      //   width: 70%;
  
    
  
        .input-wrap{
          border: 0.1rem solid var(--border-color);
          border-radius: 0.5rem;
          padding: .3rem 1rem;
        }
  
        .input {
          margin: 1rem 0rem 3rem 0rem;
          background-color: #F5F7F7;
          border: none;
          outline: none;
          padding: 1rem 1.5rem;
          border-radius: 1rem;
          width: 100%;
      
          &::placeholder {
            color: #bbbbbb;
          }
      
          &:focus {
            border: none;
            outline: none;
          }
        }
  
        .label {
          margin-right: 0.7rem;
          cursor: pointer;
        }
  
        .label-calendar{
          margin-left: 0.7rem;
          margin-right: unset;
        }
  
        .input-calender {
          border: unset;
          padding: 1.2rem 0rem;
          color: rgba(66, 66, 66, 0.5);
          background-color: #F5F7F7;
          order: -1;
  
          &:focus {
            border: none;
            outline: none;
          }
        }
      }
  
      .form-group-text-area{
          width: 100%;
      }
  
      .form-group {
        display: flex;
        flex-direction: column;
  
        .input-wrap{
          border: 0.1rem solid var(--border-color);
          border-radius: 0.8rem;
          padding: .5rem 1rem;
          width: 100%;
          // background-color: red;
  
          .input{
              margin: unset;
          }
        }
  
        .label {
          margin-bottom: 0.5rem;
        }
  
        .group-two{
          display: flex;
          flex-direction: row;
          border: 0.1rem solid var(--border-color);
          border-radius: 0.5rem;
          padding: .3rem 1rem;
          align-items: center;
  
          &>:last-child{
              flex: 1;
          }
        }
  
        .input-border {
          border: 0.1rem solid var(--border-color);
          padding: 1rem 1rem;
          width: 100%;
          border-radius: 0.5rem;
  
          &:focus {
            border: 0.1rem solid var(--border-color);
            outline: none;
          }
        }
      }
  
      .distinguish-box{
          // background-color: red;
          .form-group {
              display: flex;
              flex-direction: column;
              // background-color: red;
                border: 0.1rem solid var(--border-color);
                padding: 0.8rem 1rem;
                border-radius: 0.8rem;
                margin-top: 1rem;
  
                .input{
                  margin: unset;
              }
        
              .input-border-describtion {
                border: 0.1rem solid var(--border-color);
                padding: 0.8rem 1rem;
                border-radius: 0.5rem;
        
                &:focus {
                  border: 0.1rem solid var(--border-color);
                  outline: none;
                }
              }
        
              .label {
                margin-bottom: 0.5rem;
                font-size: 1.2rem;
              }
        
              .input-border {
                border: 0.1rem solid var(--border-color);
                padding: 0.8rem 2rem;
                border-radius: 0.5rem;
        
                &:focus {
                  border: 0.1rem solid var(--border-color);
                  outline: none;
                }
              }
            }
      }
  
      .form-group {
        display: flex;
        flex-direction: column;
  
        .input-border-describtion {
          border: 0.1rem solid var(--border-color);
          padding: 0.8rem 1rem;
          border-radius: 0.5rem;
  
          &:focus {
            border: 0.1rem solid var(--border-color);
            outline: none;
          }
        }
  
        .label {
          margin-bottom: 0.5rem;
          font-size: 1.2rem;
        }
  
        .input-border {
          border: 0.1rem solid var(--border-color);
          padding: 0.8rem 2rem;
          border-radius: 0.5rem;
  
          &:focus {
            border: 0.1rem solid var(--border-color);
            outline: none;
          }
        }
      }
  }
}