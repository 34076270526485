.view-ticket-modal-wrap {
  width: 80rem;
  min-height: 50rem;
  background-color: var(--white-color);
  border-radius: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem 0rem 4rem;
}
.view-ticket-modal-wrap .close-icon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 0.1rem solid var(--black-color);
  display: grid;
  place-items: center;
  transition: all 0.3s;
  cursor: pointer;
}
.view-ticket-modal-wrap .close-icon:hover {
  transform: scale(1.04);
}
.view-ticket-modal-wrap .close-icon .icon {
  width: 1.5rem;
  height: 1.5rem;
}
.view-ticket-modal-wrap .title {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 2rem;
  align-self: center;
}
.view-ticket-modal-wrap .bottom-box {
  flex: 1;
  display: flex;
}
.view-ticket-modal-wrap .bottom-box .left-box {
  flex: 0 0 30%;
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
}
.view-ticket-modal-wrap .bottom-box .left-box > *:not(:last-child) {
  margin-bottom: 2rem;
}
.view-ticket-modal-wrap .bottom-box .left-box .ticket-type-box {
  padding: 1rem 2rem;
  border-radius: 1rem;
  display: grid;
  place-items: center;
  align-self: flex-start;
}
.view-ticket-modal-wrap .bottom-box .left-box .ticket-type-box .ticket {
  font-size: 1.3rem;
  font-weight: 600;
}
.view-ticket-modal-wrap .bottom-box .left-box .ticket-type-box-yellow {
  background-color: rgba(0, 192, 19, 0.15);
}
.view-ticket-modal-wrap .bottom-box .left-box .ticket-type-box-yellow .ticket {
  color: #ffa72c;
}
.view-ticket-modal-wrap .bottom-box .left-box .ticket-type-box-yellow .ticket {
  color: #00c013;
}
.view-ticket-modal-wrap .bottom-box .left-box .ticket-type-box-green {
  background-color: rgba(0, 192, 19, 0.15);
}
.view-ticket-modal-wrap .bottom-box .left-box .ticket-type-box-green .ticket {
  color: #00c013;
}
.view-ticket-modal-wrap .bottom-box .left-box .box {
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
}
.view-ticket-modal-wrap .bottom-box .left-box .box .light {
  opacity: 0.5;
  margin-bottom: 0.5rem;
}
.view-ticket-modal-wrap .bottom-box .left-box .box .bold {
  font-weight: 700;
}
.view-ticket-modal-wrap .bottom-box .left-box .box .bold-green {
  color: #00c013;
}
.view-ticket-modal-wrap .bottom-box .right-box {
  flex: 1;
  border: 0.1rem solid rgba(187, 187, 187, 0.25);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  display: flex;
  flex-direction: column;
  height: 50rem;
}
.view-ticket-modal-wrap .bottom-box .right-box .top-content-box {
  flex: 0 0 87%;
  background-color: #f5f7f7;
  overflow-y: scroll;
  padding: 0rem 2rem;
}
.view-ticket-modal-wrap .bottom-box .right-box .bottom-content-box {
  flex: 1;
}

.add-new-faq-modal-wrap {
  width: 50rem;
  min-height: 55rem;
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
}
.add-new-faq-modal-wrap .close-icon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 0.1rem solid var(--black-color);
  display: grid;
  place-items: center;
  transition: all 0.3s;
  cursor: pointer;
}
.add-new-faq-modal-wrap .close-icon:hover {
  transform: scale(1.04);
}
.add-new-faq-modal-wrap .close-icon .icon {
  width: 1.5rem;
  height: 1.5rem;
}
.add-new-faq-modal-wrap .title {
  align-self: center;
  margin-top: 4rem;
  font-weight: 500;
}
.add-new-faq-modal-wrap .form {
  width: 80%;
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
}
.add-new-faq-modal-wrap .form .metrics-box {
  width: 80%;
  margin-top: -4rem;
}
.add-new-faq-modal-wrap .form .metrics-box .title {
  font-size: 1.3rem;
  font-weight: 300;
  margin-bottom: 0.7rem;
}
.add-new-faq-modal-wrap .form .metrics-box .detail-box {
  position: relative;
  min-height: 5rem;
  border: 0.1rem solid var(--border-color);
  border-radius: 1rem;
  padding: 2rem;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.add-new-faq-modal-wrap .form .metrics-box .detail-box > *:not(:last-child) {
  margin-right: 1rem;
}
.add-new-faq-modal-wrap .form .metrics-box .detail-box .info {
  color: #bbbbbb;
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: 1;
}
.add-new-faq-modal-wrap .form .metrics-box .detail-box .input-btn-box {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
}
.add-new-faq-modal-wrap .form .metrics-box .detail-box .input-btn-box .metrics-input {
  padding: 1rem;
  border: 0.1rem solid var(--dark-yellow-color);
  border-radius: 0.8rem;
}
.add-new-faq-modal-wrap .form .metrics-box .detail-box .input-btn-box .metrics-input:focus {
  outline: none;
  border: 0.1rem solid var(--dark-yellow-color);
}
.add-new-faq-modal-wrap .form .metrics-box .detail-box .input-btn-box .icon {
  color: var(--dark-yellow-color);
  cursor: pointer;
  transform: translateY(0.7rem);
  width: 2rem;
  height: 2rem;
}
.add-new-faq-modal-wrap .form .metrics-box .detail-box .input-btn-box-active {
  padding: 1rem;
  border-radius: 0.6rem;
  box-shadow: var(--box-shadow-one);
  background-color: var(--white-color);
}
.add-new-faq-modal-wrap .form > *:not(:last-child) {
  margin-bottom: 2.5rem;
}
.add-new-faq-modal-wrap .form .input-border-select {
  padding: 0.7rem 1rem;
  border: 0.1rem solid var(--border-color);
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
}
.add-new-faq-modal-wrap .form .input-border-select > * {
  border: none !important;
  background-color: #F5F7F7;
}
.add-new-faq-modal-wrap .form .three-group-boxes {
  display: grid;
  grid-template-columns: 1.5fr 1.5fr;
  grid-column-gap: 4rem;
}
.add-new-faq-modal-wrap .form .two-group-boxes {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 4rem;
}
.add-new-faq-modal-wrap .form .btn-reuse {
  align-self: center;
  margin-top: 3rem;
}
.add-new-faq-modal-wrap .form .picture-detail-box {
  width: 100%;
  display: flex;
}
.add-new-faq-modal-wrap .form .form-group {
  display: flex;
}
.add-new-faq-modal-wrap .form .form-group .input-wrap {
  border: 0.1rem solid var(--border-color);
  border-radius: 0.5rem;
  padding: 0.3rem 1rem;
}
.add-new-faq-modal-wrap .form .form-group .input {
  margin: 1rem 0rem 3rem 0rem;
  background-color: #F5F7F7;
  border: none;
  outline: none;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  width: 100%;
}
.add-new-faq-modal-wrap .form .form-group .input::-moz-placeholder {
  color: #bbbbbb;
}
.add-new-faq-modal-wrap .form .form-group .input::placeholder {
  color: #bbbbbb;
}
.add-new-faq-modal-wrap .form .form-group .input:focus {
  border: none;
  outline: none;
}
.add-new-faq-modal-wrap .form .form-group .label {
  margin-right: 0.7rem;
  cursor: pointer;
}
.add-new-faq-modal-wrap .form .form-group .label-calendar {
  margin-left: 0.7rem;
  margin-right: unset;
}
.add-new-faq-modal-wrap .form .form-group .input-calender {
  border: unset;
  padding: 1.2rem 0rem;
  color: rgba(66, 66, 66, 0.5);
  background-color: #F5F7F7;
  order: -1;
}
.add-new-faq-modal-wrap .form .form-group .input-calender:focus {
  border: none;
  outline: none;
}
.add-new-faq-modal-wrap .form .form-group-text-area {
  width: 100%;
}
.add-new-faq-modal-wrap .form .form-group {
  display: flex;
  flex-direction: column;
}
.add-new-faq-modal-wrap .form .form-group .input-wrap {
  border: 0.1rem solid var(--border-color);
  border-radius: 0.8rem;
  padding: 0.5rem 1rem;
  width: 100%;
}
.add-new-faq-modal-wrap .form .form-group .input-wrap .input {
  margin: unset;
}
.add-new-faq-modal-wrap .form .form-group .label {
  margin-bottom: 0.5rem;
}
.add-new-faq-modal-wrap .form .form-group .group-two {
  display: flex;
  flex-direction: row;
  border: 0.1rem solid var(--border-color);
  border-radius: 0.5rem;
  padding: 0.3rem 1rem;
  align-items: center;
}
.add-new-faq-modal-wrap .form .form-group .group-two > :last-child {
  flex: 1;
}
.add-new-faq-modal-wrap .form .form-group .input-border {
  border: 0.1rem solid var(--border-color);
  padding: 1rem 1rem;
  width: 100%;
  border-radius: 0.5rem;
}
.add-new-faq-modal-wrap .form .form-group .input-border:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}
.add-new-faq-modal-wrap .form .distinguish-box .form-group {
  display: flex;
  flex-direction: column;
  border: 0.1rem solid var(--border-color);
  padding: 0.8rem 1rem;
  border-radius: 0.8rem;
  margin-top: 1rem;
}
.add-new-faq-modal-wrap .form .distinguish-box .form-group .input {
  margin: unset;
}
.add-new-faq-modal-wrap .form .distinguish-box .form-group .input-border-describtion {
  border: 0.1rem solid var(--border-color);
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
}
.add-new-faq-modal-wrap .form .distinguish-box .form-group .input-border-describtion:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}
.add-new-faq-modal-wrap .form .distinguish-box .form-group .label {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}
.add-new-faq-modal-wrap .form .distinguish-box .form-group .input-border {
  border: 0.1rem solid var(--border-color);
  padding: 0.8rem 2rem;
  border-radius: 0.5rem;
}
.add-new-faq-modal-wrap .form .distinguish-box .form-group .input-border:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}
.add-new-faq-modal-wrap .form .form-group {
  display: flex;
  flex-direction: column;
}
.add-new-faq-modal-wrap .form .form-group .input-border-describtion {
  border: 0.1rem solid var(--border-color);
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
}
.add-new-faq-modal-wrap .form .form-group .input-border-describtion:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}
.add-new-faq-modal-wrap .form .form-group .label {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}
.add-new-faq-modal-wrap .form .form-group .input-border {
  border: 0.1rem solid var(--border-color);
  padding: 0.8rem 2rem;
  border-radius: 0.5rem;
}
.add-new-faq-modal-wrap .form .form-group .input-border:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}/*# sourceMappingURL=ViewTicketModal.css.map */