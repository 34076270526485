.payment-request-settle-upload-form-box {
  .form-group {
    .form-input {
      display: none;
    }

    .form-label {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 1rem;
      padding: 0rem 2rem;
      background-color: rgba(217, 217, 217, 0.25);
      border: 0.1rem solid #bbbbbb;
      cursor: pointer;

      .img-box {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 1.5rem;
      }

      .text {
        color: #bbbbbb;
        font-size: 1.3rem;
      }

      .text-bold {
        color: #424242;
        font-weight: 500;
      }
    }
  }
}

// history styles ------------------------

.payment-request-history-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
//   margin-top: 10rem;
  // padding-right: 3rem;

  .text-value-box {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 4rem;
    background-color: rgba(0, 192, 19, 0.15);
    border-radius: 1rem;
    margin-bottom: 3rem;

    .value {
      color: #00c013;
      font-size: 2vw;
      font-weight: 600;
      margin-top: 1rem;
    }
  }
  // background-color: red;

  .btn-reuse {
    align-self: flex-start;
    padding: 1.4rem 3rem;
  }

  .reset-text-box {
    margin: 2rem 0rem -2rem 0rem;
    display: flex;
    align-items: center;
    font-size: 1.3rem;

    .text {
      span {
        font-weight: 800;
      }
    }
    .reset {
      margin: 0rem 0rem 0rem 5rem;
      font-weight: 800;
      color: #ffa72c;
      border-bottom: 0.1rem solid currentColor;
      cursor: pointer;
    }
  }

  .subtitle {
    font-size: 1.3rem;
    margin-top: 3rem;
  }

  .calender-box {
    display: flex;
    align-items: center;
    margin: 2rem 0rem;

    .text {
      margin-right: 1.3rem;
      margin-bottom: unset;
    }

    .form-group {
      display: flex;
      align-items: center;
      padding: 0.2rem 1rem;
      border: 0.1rem solid rgba(66, 66, 66, 0.5);
      border-radius: 0.8rem;

      .label {
        margin-right: 0.7rem;
        cursor: pointer;
      }

      .input-calender {
        border: unset;
        padding: 1rem 0rem;
        // color: rgba(66, 66, 66, 0.5);

        &:focus {
          border: none;
          outline: none;
        }
      }
    }
  }
}
