.create-appointment-modal {
  width: 50rem;
  min-height: 45rem;
  background-color: #ffffff;
  position: relative;
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  padding-bottom: 5rem;
}
.create-appointment-modal .close-icon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 0.1rem solid var(--black-color);
  display: grid;
  place-items: center;
  transition: all 0.3s;
  cursor: pointer;
}
.create-appointment-modal .close-icon:hover {
  transform: scale(1.04);
}
.create-appointment-modal .close-icon .icon {
  width: 1.5rem;
  height: 1.5rem;
}
.create-appointment-modal .detail-note-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-self: center;
  width: 70%;
  border-bottom: 0.1rem solid #bbbbbb;
}
.create-appointment-modal .detail-note-box .text {
  padding: 1rem;
  color: #bbbbbb;
  font-weight: 400;
  position: relative;
  text-align: center;
  cursor: pointer;
}
.create-appointment-modal .detail-note-box .text::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.4rem;
  background-color: currentColor;
  transform: scale(0) translateY(50%);
}
.create-appointment-modal .detail-note-box .text-active {
  color: #ffa72c;
  font-weight: 700;
}
.create-appointment-modal .detail-note-box .text-active::after {
  transform: scale(1) translateY(50%);
}
.create-appointment-modal .form {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  padding: 0rem 4rem;
}
.create-appointment-modal .form .btn-reuse {
  padding: 1.4rem 3rem;
  width: 70%;
  align-self: center;
}
.create-appointment-modal .form .group-one {
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  border-bottom: 0.1rem solid rgba(66, 66, 66, 0.25);
}
.create-appointment-modal .form .group-one > *:not(:last-child) {
  margin-bottom: 2rem;
}
.create-appointment-modal .form .group-two {
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
}
.create-appointment-modal .form .group-two > *:not(:last-child) {
  margin-bottom: 2rem;
}
.create-appointment-modal .form .form-group {
  display: flex;
  width: 70%;
}
.create-appointment-modal .form .form-group .input {
  margin: 1rem 0rem 3rem 0rem;
  background-color: #F5F7F7;
  border: none;
  outline: none;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  width: 100%;
}
.create-appointment-modal .form .form-group .input::-moz-placeholder {
  color: #bbbbbb;
}
.create-appointment-modal .form .form-group .input::placeholder {
  color: #bbbbbb;
}
.create-appointment-modal .form .form-group .input:focus {
  border: none;
  outline: none;
}
.create-appointment-modal .form .form-group .label {
  margin-right: 0.7rem;
  cursor: pointer;
}
.create-appointment-modal .form .form-group .input-calender {
  border: unset;
  padding: 1rem 0rem;
  color: rgba(66, 66, 66, 0.5);
}
.create-appointment-modal .form .form-group .input-calender:focus {
  border: none;
  outline: none;
}
.create-appointment-modal .form .form-group-text-area {
  width: 100%;
}
.create-appointment-modal .form .form-group {
  display: flex;
  flex-direction: column;
}
.create-appointment-modal .form .form-group .label {
  margin-bottom: 0.5rem;
}
.create-appointment-modal .form .form-group .group-two {
  display: flex;
  flex-direction: row;
  border: 0.1rem solid var(--border-color);
  border-radius: 0.5rem;
  padding: 0.3rem 1rem;
  align-items: center;
}
.create-appointment-modal .form .form-group .group-two > :last-child {
  flex: 1;
}
.create-appointment-modal .form .form-group .input-border {
  border: 0.1rem solid var(--border-color);
  padding: 1rem 1rem;
  width: 70%;
  border-radius: 0.5rem;
}
.create-appointment-modal .form .form-group .input-border:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}
.create-appointment-modal .form .form-group {
  display: flex;
  flex-direction: column;
}
.create-appointment-modal .form .form-group .input-border-describtion {
  border: 0.1rem solid var(--border-color);
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
}
.create-appointment-modal .form .form-group .input-border-describtion:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}
.create-appointment-modal .form .form-group .label {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}
.create-appointment-modal .form .form-group .input-border {
  border: 0.1rem solid var(--border-color);
  padding: 0.8rem 2rem;
  border-radius: 0.5rem;
}
.create-appointment-modal .form .form-group .input-border:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}/*# sourceMappingURL=CreateAppointmentModal.css.map */