.change-password-modal-wrap {
  width: 50rem;
  min-height: 55rem;
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
}
.change-password-modal-wrap .close-icon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 0.1rem solid var(--black-color);
  display: grid;
  place-items: center;
  transition: all 0.3s;
  cursor: pointer;
}
.change-password-modal-wrap .close-icon:hover {
  transform: scale(1.04);
}
.change-password-modal-wrap .close-icon .icon {
  width: 1.5rem;
  height: 1.5rem;
}
.change-password-modal-wrap .title {
  align-self: center;
  margin-top: 4rem;
  font-weight: 500;
}
.change-password-modal-wrap .form {
  width: 80%;
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
}
.change-password-modal-wrap .form > *:not(:last-child) {
  margin-bottom: 2.5rem;
}
.change-password-modal-wrap .form .input-border-select {
  padding: 0.7rem 1rem;
  border: 0.1rem solid var(--border-color);
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
}
.change-password-modal-wrap .form .input-border-select > * {
  border: none !important;
  background-color: #f5f7f7;
}
.change-password-modal-wrap .form .three-group-boxes {
  display: grid;
  grid-template-columns: 1.5fr;
  grid-column-gap: 4rem;
}
.change-password-modal-wrap .form .two-group-boxes {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 4rem;
}
.change-password-modal-wrap .form .btn-reuse {
  align-self: center;
  margin-top: 3rem;
}
.change-password-modal-wrap .form .picture-detail-box {
  width: 100%;
  display: flex;
}
.change-password-modal-wrap .form .form-group {
  display: flex;
}
.change-password-modal-wrap .form .form-group .input-wrap {
  border: 0.1rem solid var(--border-color);
  border-radius: 0.5rem;
  padding: 0.3rem 1rem;
}
.change-password-modal-wrap .form .form-group .input {
  margin: 1rem 0rem 3rem 0rem;
  background-color: #f5f7f7;
  border: none;
  outline: none;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  width: 100%;
}
.change-password-modal-wrap .form .form-group .input::-moz-placeholder {
  color: #bbbbbb;
}
.change-password-modal-wrap .form .form-group .input::placeholder {
  color: #bbbbbb;
}
.change-password-modal-wrap .form .form-group .input:focus {
  border: none;
  outline: none;
}
.change-password-modal-wrap .form .form-group .label {
  margin-right: 0.7rem;
  cursor: pointer;
}
.change-password-modal-wrap .form .form-group .label-calendar {
  margin-left: 0.7rem;
  margin-right: unset;
}
.change-password-modal-wrap .form .form-group .input-calender {
  border: unset;
  padding: 1.2rem 0rem;
  color: rgba(66, 66, 66, 0.5);
  background-color: #f5f7f7;
  order: -1;
}
.change-password-modal-wrap .form .form-group .input-calender:focus {
  border: none;
  outline: none;
}
.change-password-modal-wrap .form .form-group-text-area {
  width: 100%;
}
.change-password-modal-wrap .form .form-group {
  display: flex;
  flex-direction: column;
}
.change-password-modal-wrap .form .form-group .input-wrap {
  border: 0.1rem solid var(--border-color);
  border-radius: 0.8rem;
  padding: 0.5rem 1rem;
  width: 100%;
}
.change-password-modal-wrap .form .form-group .input-wrap .input {
  margin: unset;
}
.change-password-modal-wrap .form .form-group .label {
  margin-bottom: 0.5rem;
}
.change-password-modal-wrap .form .form-group .group-two {
  display: flex;
  flex-direction: row;
  border: 0.1rem solid var(--border-color);
  border-radius: 0.5rem;
  padding: 0.3rem 1rem;
  align-items: center;
}
.change-password-modal-wrap .form .form-group .group-two > :last-child {
  flex: 1;
}
.change-password-modal-wrap .form .form-group .input-border {
  border: 0.1rem solid var(--border-color);
  padding: 1rem 1rem;
  width: 100%;
  border-radius: 0.5rem;
}
.change-password-modal-wrap .form .form-group .input-border:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}
.change-password-modal-wrap .form .distinguish-box .form-group {
  display: flex;
  flex-direction: column;
  border: 0.1rem solid var(--border-color);
  padding: 0.8rem 1rem;
  border-radius: 0.8rem;
  margin-top: 1rem;
}
.change-password-modal-wrap .form .distinguish-box .form-group .input {
  margin: unset;
}
.change-password-modal-wrap .form .distinguish-box .form-group .input-border-describtion {
  border: 0.1rem solid var(--border-color);
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
}
.change-password-modal-wrap .form .distinguish-box .form-group .input-border-describtion:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}
.change-password-modal-wrap .form .distinguish-box .form-group .label {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}
.change-password-modal-wrap .form .distinguish-box .form-group .input-border {
  border: 0.1rem solid var(--border-color);
  padding: 0.8rem 2rem;
  border-radius: 0.5rem;
}
.change-password-modal-wrap .form .distinguish-box .form-group .input-border:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}
.change-password-modal-wrap .form .form-group {
  display: flex;
  flex-direction: column;
}
.change-password-modal-wrap .form .form-group .input-border-describtion {
  border: 0.1rem solid var(--border-color);
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
}
.change-password-modal-wrap .form .form-group .input-border-describtion:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}
.change-password-modal-wrap .form .form-group .label {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}
.change-password-modal-wrap .form .form-group .input-border {
  border: 0.1rem solid var(--border-color);
  padding: 0.8rem 2rem;
  border-radius: 0.5rem;
}
.change-password-modal-wrap .form .form-group .input-border:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}

.system-setting-modal-wrap {
  width: 85rem;
  min-height: 65rem;
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  overflow-y: scroll;
}
.system-setting-modal-wrap .close-icon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 0.1rem solid var(--black-color);
  display: grid;
  place-items: center;
  transition: all 0.3s;
  cursor: pointer;
}
.system-setting-modal-wrap .close-icon:hover {
  transform: scale(1.04);
}
.system-setting-modal-wrap .close-icon .icon {
  width: 1.5rem;
  height: 1.5rem;
}
.system-setting-modal-wrap .content-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
}
.system-setting-modal-wrap .content-box .content-title {
  font-weight: 700;
  color: #424242;
  font-size: 1.6rem;
  margin-bottom: 1rem;
  text-align: center;
}
.system-setting-modal-wrap .content-box .content-text {
  opacity: 0.45;
}
.system-setting-modal-wrap .content-box .switch-box {
  display: grid;
  width: 50%;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 3rem;
  padding-bottom: 1rem;
  border-bottom: 0.1rem solid rgba(187, 187, 187, 0.5);
}
.system-setting-modal-wrap .content-box .switch-box .text {
  text-align: center;
  cursor: pointer;
  position: relative;
}
.system-setting-modal-wrap .content-box .switch-box .text-active {
  font-weight: 700;
  color: #FFA72C;
}
.system-setting-modal-wrap .content-box .switch-box .text-active::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  height: 0.4rem;
  width: 100%;
  border-radius: 2rem;
  background-color: #FFA72C;
  transform: translateY(1rem);
}
.system-setting-modal-wrap .content-box .metrics-box {
  width: 80%;
  margin-top: -4rem;
}
.system-setting-modal-wrap .content-box .metrics-box .title {
  font-size: 1.3rem;
  font-weight: 300;
  margin-bottom: 0.7rem;
}
.system-setting-modal-wrap .content-box .metrics-box .detail-box {
  position: relative;
  min-height: 5rem;
  border: 0.1rem solid var(--border-color);
  border-radius: 1rem;
  padding: 2rem;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.system-setting-modal-wrap .content-box .metrics-box .detail-box > *:not(:last-child) {
  margin-right: 1rem;
}
.system-setting-modal-wrap .content-box .metrics-box .detail-box .info {
  color: #bbbbbb;
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: 1;
}
.system-setting-modal-wrap .content-box .metrics-box .detail-box .input-btn-box {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
}
.system-setting-modal-wrap .content-box .metrics-box .detail-box .input-btn-box .metrics-input {
  padding: 1rem;
  border: 0.1rem solid var(--dark-yellow-color);
  border-radius: 0.8rem;
}
.system-setting-modal-wrap .content-box .metrics-box .detail-box .input-btn-box .metrics-input:focus {
  outline: none;
  border: 0.1rem solid var(--dark-yellow-color);
}
.system-setting-modal-wrap .content-box .metrics-box .detail-box .input-btn-box .icon {
  color: var(--dark-yellow-color);
  cursor: pointer;
  transform: translateY(0.7rem);
  width: 2rem;
  height: 2rem;
}
.system-setting-modal-wrap .content-box .metrics-box .detail-box .input-btn-box-active {
  padding: 1rem;
  border-radius: 0.6rem;
  box-shadow: var(--box-shadow-one);
  background-color: var(--white-color);
}/*# sourceMappingURL=ChangePasswordModal.css.map */