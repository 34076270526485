.payment-request-settle-upload-form-box .form-group .form-input {
  display: none;
}
.payment-request-settle-upload-form-box .form-group .form-label {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  padding: 0rem 2rem;
  background-color: rgba(217, 217, 217, 0.25);
  border: 0.1rem solid #bbbbbb;
  cursor: pointer;
}
.payment-request-settle-upload-form-box .form-group .form-label .img-box {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1.5rem;
}
.payment-request-settle-upload-form-box .form-group .form-label .text {
  color: #bbbbbb;
  font-size: 1.3rem;
}
.payment-request-settle-upload-form-box .form-group .form-label .text-bold {
  color: #424242;
  font-weight: 500;
}

.payment-request-history-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.payment-request-history-wrap .text-value-box {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 4rem;
  background-color: rgba(0, 192, 19, 0.15);
  border-radius: 1rem;
  margin-bottom: 3rem;
}
.payment-request-history-wrap .text-value-box .value {
  color: #00c013;
  font-size: 2vw;
  font-weight: 600;
  margin-top: 1rem;
}
.payment-request-history-wrap .btn-reuse {
  align-self: flex-start;
  padding: 1.4rem 3rem;
}
.payment-request-history-wrap .reset-text-box {
  margin: 2rem 0rem -2rem 0rem;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
}
.payment-request-history-wrap .reset-text-box .text span {
  font-weight: 800;
}
.payment-request-history-wrap .reset-text-box .reset {
  margin: 0rem 0rem 0rem 5rem;
  font-weight: 800;
  color: #ffa72c;
  border-bottom: 0.1rem solid currentColor;
  cursor: pointer;
}
.payment-request-history-wrap .subtitle {
  font-size: 1.3rem;
  margin-top: 3rem;
}
.payment-request-history-wrap .calender-box {
  display: flex;
  align-items: center;
  margin: 2rem 0rem;
}
.payment-request-history-wrap .calender-box .text {
  margin-right: 1.3rem;
  margin-bottom: unset;
}
.payment-request-history-wrap .calender-box .form-group {
  display: flex;
  align-items: center;
  padding: 0.2rem 1rem;
  border: 0.1rem solid rgba(66, 66, 66, 0.5);
  border-radius: 0.8rem;
}
.payment-request-history-wrap .calender-box .form-group .label {
  margin-right: 0.7rem;
  cursor: pointer;
}
.payment-request-history-wrap .calender-box .form-group .input-calender {
  border: unset;
  padding: 1rem 0rem;
}
.payment-request-history-wrap .calender-box .form-group .input-calender:focus {
  border: none;
  outline: none;
}/*# sourceMappingURL=PaymentRequest.css.map */