.content-box-shop-settings {
  // height: 30rem;
  // background-color: red;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;

  .metrics-box {
    width: 80%;

    .title {
      font-weight: 600;
      font-size: 1.8rem;
      margin-bottom: 1rem;
      text-transform: capitalize;
    }

    .detail-box {
        position: relative;
      min-height: 15rem;
      border: 0.1rem solid var(--border-color);
      border-radius: 1rem;
      padding: 2rem;
      cursor: pointer;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      &>*:not(:last-child) {
        margin-right: 1rem;
      }

      .info {
        color: #bbbbbb;
        position: absolute;
        top: 2rem;
        left: 2rem;
        z-index: 1;
      }

      .input-btn-box{
        // position: absolute;
        // position: absolute;
        // top: 2rem;
        // left: 2rem;
        // z-index: 3;
        display: flex;
        align-items: center;
        margin-left: 1.5rem;

        .metrics-input{
            padding: 1rem;
            border: .1rem solid var(--dark-yellow-color);
            border-radius: .8rem;
            // margin-right: 1rem;

            &:focus{
                outline: none;
                border: .1rem solid var(--dark-yellow-color);
            }
        }

        .icon{
            color: var(--dark-yellow-color);
            cursor: pointer;
            transform: translateY(.7rem);
            width: 2rem;
            height: 2rem;
        }
      }

      .input-btn-box-active{
        padding: 1rem;
        border-radius: .6rem;
        box-shadow: var(--box-shadow-one);
        background-color: var(--white-color);
      }
    }
  }
}
