.dashboard-layout-wrap {
  width: 100%;
  min-height: 100vh;
  position: relative;

  .sidebar-wrap {
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 25rem;
    z-index: 4;
    padding: 0.6rem 1rem;
    overflow-y: scroll;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    & > * {
      position: relative;
      z-index: 2;
    }

    &::after {
      content: "";
      // position: absolute;
      width: 25rem;
      height: 50%;
      background-color: var(--dark-yellow-color);
      opacity: 0.5;
      position: absolute;
      top: 0;
      left: 0;
      filter: blur(1rem);
      z-index: 1;
    }

    &::before {
      content: "";
      // position: absolute;
      width: 25rem;
      height: 50%;
      background-color: #84bfcd;
      opacity: 0.5;
      position: absolute;
      bottom: 0;
      left: 0;
      filter: blur(1rem);
      z-index: 1;
    }
  }

  .header-wrap {
    position: fixed;
    right: 0;
    top: 0;
    height: 9rem;
    width: calc(100vw - 25rem);
    z-index: 4;
    // box-shadow: -.1rem 0rem 8rem .1rem  rgba(0,0,0, 0.05);
    background-color: var(--white-color);
  }

  .main-wrap {
    position: absolute;
    right: 0;
    top: 9rem;
    min-height: calc(100vh - 9rem);
    width: calc(100vw - 25rem);
    z-index: 1;
    overflow-y: scroll;
    // border-left: .1rem solid rgba(0, 0, 0, 0.15) ;
    box-shadow: -0.1rem 0rem 0.1rem rgba(0, 0, 0, 0.05);

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 0.6rem;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--dark-yellow-color);
      border-radius: 30rem;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: var(--dark-yellow-color);
    }

    & > * {
      padding: 2rem 4rem;
    }

    & > *:not(:first-child) {
      // position: relative;
      // z-index: 2;
    }

    & > *:first-child {
      padding: unset;
      // position: relative;
    }
  }
}
