.header-nav-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0rem 3rem 0rem 5rem;
}
.header-nav-wrap .text-box {
  flex: 1;
}
.header-nav-wrap .text-box .title {
  font-size: 2.4rem;
  font-weight: 700;
  color: var(--black-color);
  margin-bottom: 0.5rem;
  text-transform: capitalize;
}
.header-nav-wrap .text-box .text {
  font-size: 1.45rem;
  color: rgba(66, 66, 66, 0.5);
}
.header-nav-wrap .profile-box {
  margin-left: auto;
  display: flex;
  align-items: center;
  position: relative;
}
.header-nav-wrap .profile-box .wrap {
  display: flex;
}
.header-nav-wrap .profile-box .wrap .user-img-box {
  width: 5.5rem;
  height: 5.5rem;
  cursor: pointer;
  margin-right: 0.5rem;
  border-radius: 50%;
  overflow: hidden;
}
.header-nav-wrap .profile-box .wrap .user-img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.header-nav-wrap .profile-box .wrap .drop-img-box {
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  align-self: center;
}
.header-nav-wrap .profile-box .wrap .drop-img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.header-nav-wrap .profile-box .drop-box-wrap {
  position: absolute;
  top: 110%;
  right: 1.5rem;
  min-height: 22rem;
  min-width: 25rem;
  z-index: 100;
  background-color: #ffffff;
  box-shadow: 0.1rem 0.1rem 2rem 0.2rem rgba(0, 0, 0, 0.1);
  padding: 3rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1.35rem;
  align-items: flex-start;
  transition: all 0.4s;
  transform: scale(0);
  transform-origin: top right;
}
.header-nav-wrap .profile-box .drop-box-wrap > *:not(:last-child) {
  margin-bottom: 2rem;
}
.header-nav-wrap .profile-box .drop-box-wrap .text-icon-box {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: flex-start;
}
.header-nav-wrap .profile-box .drop-box-wrap .text-icon-box .img-box {
  width: 2rem;
  height: 2rem;
  margin-right: 1.5rem;
}
.header-nav-wrap .profile-box .drop-box-wrap .text-icon-box .red {
  color: #de0b0b;
}
.header-nav-wrap .profile-box .drop-box-wrap-show {
  transform: scale(1);
}/*# sourceMappingURL=HeaderNav.css.map */