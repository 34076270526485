.order-shop-modal-wrap {
  width: 55rem;
  min-height: 30rem;
  background-color: var(--white-color);
  border-radius: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem 5rem 4rem;

  .btn-wrap {
    align-self: center;
    margin-top: 4rem;
  }

  .close-icon {
    position: absolute;
    top: 2rem;
    right: 2rem;
    // background-color: red;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 0.1rem solid var(--black-color);
    display: grid;
    place-items: center;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      transform: scale(1.04);
    }

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .title {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 2rem;
    align-self: center;
  }

  .switch-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 80%;
    align-self: center;
    // justify-items: center;

    .item {
      color: #bbbbbb;
      font-size: 1.35rem;
      text-align: center;
      padding-bottom: 1.5rem;
      border-bottom: 0.1rem solid #bbbbbb;
      cursor: pointer;

      span {
        &:hover {
          color: var(--dark-yellow-color);
        }
      }
    }

    .item-active {
      color: var(--dark-yellow-color);
      font-weight: 700;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.35rem;
        background-color: var(--dark-yellow-color);
        border-radius: 1rem;
        transform: translateY(50%);
      }
    }
  }

  .detail-wrap {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;

    .top-box {
      border-bottom: 0.07rem solid var(--border-color);
      padding-bottom: 2.5rem;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 2rem;
      grid-row-gap: 3rem;

      .key {
        font-size: 1.25rem;
        opacity: 0.5;
        margin-bottom: 0.3rem;
      }

      .value {
        font-size: 1.45rem;
        font-weight: 500;
      }

      & > *:last-child {
        grid-row: 2 / -1;
        grid-column: 1 / -1;
      }
    }

    .bottom-box {
      grid-row-gap: 3rem;
      margin-top: 2rem;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 2rem;

      .key {
        font-size: 1.25rem;
        opacity: 0.5;
        margin-bottom: 0.3rem;
      }

      .value {
        font-size: 1.45rem;
        font-weight: 500;
      }

      & > *:last-child {
        grid-row: 2 / -1;
        grid-column: 1 / -1;
      }

      .status {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;

        span {
          position: absolute;
          top: 50%;
          left: 0%;
          transform: translate(0%, -50%);
          padding: 0.8rem 1.5rem;
          border-radius: 0.8rem;
          font-size: 1.25rem;
          z-index: 1;
        }
      }

      .status-pending {
        span {
          background-color: rgba(255, 167, 44, 0.15);
          color: var(--dark-yellow-color);
        }
      }

      .status-deliver {
        span {
          background-color: rgba(0, 192, 19, 0.15);
          color: #00c013;
        }
      }
    }
  }

  .item-list-box {
    // background-color: red;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;

    .content-box {
      display: flex;
      flex-direction: column;
      height: 35rem;
      overflow-y: scroll;
      // background-color: red;
      padding-bottom: 2rem;

      & > *:not(:last-child) {
        margin-bottom: 1.5rem;
      }

      .box {
        display: flex;
        align-items: center;
        background-color: #f5f7f7;
        border-radius: 1rem;
        padding: 1.5rem 2rem;

        .img-box {
          width: 8rem;
          height: 7rem;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: block;
          }
        }

        .text-box {
          display: flex;
          flex-direction: column;
          margin: 0rem 2rem;
          border-right: 0.1rem solid #bbbbbb;
          width: 23rem;

          .key {
            font-size: 1.25rem;
            opacity: 0.5;
          }

          .value {
            font-size: 1.45rem;
            margin-bottom: 0.3rem;
            font-weight: 500;
          }
        }

        .amount {
          font-size: 1.5rem;
          font-weight: 600;
        }
      }
    }
  }
}

.accept-reject-modal-wrap {
  width: 45rem;
  min-height: 35rem;
  border-radius: 1rem;
  background-color: var(--white-color);
  padding: 3rem;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: space-evenly;

  .close-icon {
    position: absolute;
    top: 2rem;
    right: 2rem;
    // background-color: red;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 0.1rem solid var(--black-color);
    display: grid;
    place-items: center;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      transform: scale(1.04);
    }

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .img-box {
    width: 8rem;
    height: 6rem;
    margin: 2rem 0rem;

    .img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
    }
  }

  .big-text {
    text-align: center;
    font-size: 1.45rem;
    font-weight: 500;
  }

  .small-text {
    color: rgba(66, 66, 66, 0.5);
    text-align: center;
    margin-top: 0.8rem;
  }

  .input {
    margin: 2rem 0rem;
    background-color: #f5f7f7;
    border: none;
    outline: none;
    padding: 1rem 1.5rem;
    border-radius: 1rem;
    width: 100%;

    &::placeholder {
      color: #bbbbbb;
    }

    &:focus {
      border: none;
      outline: none;
    }
  }

  .btn-wrap {
    padding: 1.2rem 3rem;
    margin-top: 1.5rem;
    font-size: 1.25rem;
  }
}

.order-history-modal-wrap {
  width: 55rem;
  min-height: 30rem;
  background-color: var(--white-color);
  border-radius: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem 5rem 4rem;
  align-items: center;

  .close-icon {
    position: absolute;
    top: 2rem;
    right: 2rem;
    // background-color: red;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 0.1rem solid var(--black-color);
    display: grid;
    place-items: center;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      transform: scale(1.04);
    }

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .title {
    align-self: center;
    font-weight: 600;
    font-size: 1.8rem;
    margin-bottom: 4rem;
    text-transform: capitalize;
  }

  .text {
    color: #424242;
    margin-bottom: 3rem;
    font-size: 1.25rem;
  }

  .calender-box {
    display: flex;
    align-items: center;

    .text {
      margin-right: 1.3rem;
      margin-bottom: unset;
    }

    .form-group {
      display: flex;
      align-items: center;
      padding: 0.2rem 1rem;
      border: 0.1rem solid rgba(66, 66, 66, 0.5);
      border-radius: 0.8rem;

      .label {
        margin-right: 0.7rem;
        cursor: pointer;
      }

      .input-calender {
        border: unset;
        padding: 1rem 0rem;
        color: rgba(66, 66, 66, 0.5);

        &:focus {
          border: none;
          outline: none;
        }
      }
    }
  }

  .btn-wrap {
    margin-top: 4rem;
    padding: 1.2rem 5rem;
  }

  .title-box {
    font-size: 1.2rem;
    display: flex;
    width: 100%;
    align-items: center;

    .export {
      margin-left: auto;
      color: var(--dark-yellow-color);
      cursor: pointer;
    }

    .text-one {
      span {
        font-weight: 1.9rem;
        font-weight: 700;
        margin: 0rem 0.4rem;
      }
    }
  }

  .table-box {
    // background-color:red;
    height: 35rem;
    overflow-y: scroll;
    align-self: flex-start;

    table {
      thead {
        tr {
          // th{
          //     padding: 1rem 0rem 1rem 2rem;
          //     font-size: 1rem;

          //     .img-box{
          //         width: 1rem;
          //         height: 1rem;
          //     }
          // }
        }
      }

      tbody {
        tr {
          // td{
          //     padding: 1rem 0rem 1rem 2rem;
          //     font-size: 1rem;
          // }
        }
      }
    }
  }
}

.add-discount-modal-wrap {
  position: relative;
  min-width: 45rem;
  min-height: 35rem;
  background-color: var(--white-color);
  position: relative;
  display: flex;
  padding: 3rem 4rem 5rem 4rem;
  border-radius: 1rem;
  flex-direction: column;
  align-items: center;

  //   .btn-wrap {
  //     align-self: center;
  //     margin-top: 4rem;
  //   }

  .close-icon {
    position: absolute;
    top: 2rem;
    right: 2rem;
    // background-color: red;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 0.1rem solid var(--black-color);
    display: grid;
    place-items: center;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      transform: scale(1.04);
    }

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .title {
    align-self: center;
    font-weight: 600;
    font-size: 1.8rem;
    margin-bottom: 2rem;
    text-transform: capitalize;
  }

  .text {
    color: rgba(66, 66, 66, 0.5);
    margin-bottom: 2rem;
    width: 70%;
    font-size: 1.5rem;
    text-align: center;
  }

  .two-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2rem;

    .box {
      display: flex;
      flex-direction: column;
      // grid-column: 1 / -1;

      .key {
        font-size: 1.45rem;
        opacity: 0.5;
        margin-bottom: 0.3rem;
      }

      .value {
        font-size: 1.6rem;
        font-weight: 500;
      }
    }

    .form-group {
      display: flex;
      flex-direction: column;

      label {
        margin-bottom: 0.5rem;
      }

      .wrap {
        display: flex;
        align-items: center;
        border: 0.1rem solid #bbbbbb;
        border-radius: 0.7rem;
        padding: 0.4rem 1rem;
        background-color: #f5f7f7;
        width: 14rem;

        input {
          flex: 0 0 50%;
          width: 50%;
          padding: 1rem 1rem;
          border: none;
          outline: none;

          &:focus {
            border: none;
            outline: none;
          }
        }

        .unit {
          display: flex;
          justify-content: center;
          flex: 0 0 50%;
          background-color: #f5f7f7;
          color: #bbbbbb;
        }
      }
    }
  }

  .two-group-extra {
    // align-self: stretch;
    padding-bottom: 1.5rem;
    border-bottom: 0.1rem solid var(--border-color);
    margin-bottom: 2rem;
  }

  .two-group-bottom {
    margin-bottom: 2rem;
  }

  .btn-wrap {
    margin-top: 4rem;
  }
}

.view-shop-item-modal {
  position: relative;
  // width: 65rem;
  min-height: 35rem;
  background-color: var(--white-color);
  position: relative;
  display: flex;
  //   padding: 3rem 4rem 5rem 4rem;
  border-radius: 1rem;

  //   .btn-wrap {
  //     align-self: center;
  //     margin-top: 4rem;
  //   }



  .close-icon {
    position: absolute;
    top: 2rem;
    right: 2rem;
    // background-color: red;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 0.1rem solid var(--black-color);
    display: grid;
    place-items: center;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      transform: scale(1.04);
    }

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .left-box {
    flex: 0 0 50%;
    // background-color:red;
    display: flex;
    flex-direction: column;
    padding: 3rem 4rem 5rem 4rem;
    overflow-x: scroll;

    .img-scroll-wrap{
      width: 100%;
      height: 20rem;
      display: grid;
      overflow: scroll;
      grid-gap: 3rem;

      &::-webkit-scrollbar{
        display: none;
      }

    .img-box {
      width: 15rem;
      height: 20rem;

      .img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        display: block;
      }
    }
    }


    .detail-box {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 2rem;

      .box {
        display: flex;
        flex-direction: column;

        .key {
          font-size: 1.1rem;
          opacity: 0.5;
        }

        .value {
          font-size: 1.25rem;
          margin-bottom: 0.3rem;
          font-weight: 500;
        }
      }
    }

    .upload-wrap {
      // background-color: red;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        margin-bottom: 0.7rem;
        align-self: flex-start;
        text-transform: capitalize;
      }

      .input-upload {
        display: none;
      }

      .label {
        margin-bottom: 2rem;
        cursor: pointer;

        .img-box {
          width: 20rem;
          height: 20rem;
          padding: 2rem;
          border-radius: 1rem;
          border: 0.1rem solid var(--border-color);

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: block;
          }
        }
      }

      .switch-upload-wrap {
        display: flex;
        align-items: center;

        & > *:not(:last-child) {
          margin-right: 1.5rem;
        }

        .img-box {
          cursor: pointer;
          width: 4rem;
          height: 4rem;
          padding: 0.5rem;
          border-radius: 0.5rem;
          border: 0.1rem solid var(--border-color);
          opacity: 0.35;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: block;
          }
        }

        .img-box-active {
          opacity: 1;
        }
      }
    }
  }

  .right-box {
    flex: 1;
    background-color: rgba(255, 167, 44, 0.05);
    display: flex;
    flex-direction: column;
    padding: 3rem;

    & > *:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    .btn {
      color: #00c013;
      background-color: rgba(0, 192, 19, 0.15);
      padding: 0.6rem 1.5rem;
      border-radius: 0.6rem;
      font-weight: 500;
      align-self: flex-start;
    }

    .status-pending {
      background-color: rgba(255, 167, 44, 0.15);
      color: var(--dark-yellow-color);
    }

    // .status-deliver {
    //   span {
    //     background-color: rgba(0, 192, 19, 0.15);
    //     color: #00c013;
    //   }
    // }

    .title {
      font-weight: 500;
      color: #424242;
      font-size: 1.6rem;
    }

    .rate-box {
      .number {
        color: #424242;
        opacity: 0.5;
        margin-left: 0.5rem;
      }
    }

    .btn-wrap {
      align-self: center;
      border-radius: 1.5rem;
      margin-top: 3.5rem;
      width: 13rem;
    }

    .detail-box {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 2rem;

      .box {
        display: flex;
        flex-direction: column;

        .key {
          font-size: 1.3rem;
          opacity: 0.5;
        }

        .value {
          font-size: 1.45rem;
          margin-bottom: 0.3rem;
          font-weight: 500;
        }
      }
    }

    .description {
      color: #bbbbbb;
      font-size: 1.6rem;
    }

    .text {
      font-size: 1.2rem;
      margin-top: -1rem;
    }
  }

  .right-box-edit-item {
    display: flex;
    flex-direction: column;
    padding-top: 5rem;
    // align-items: cen;

    .btn-wrap {
      align-self: center;
      border-radius: 0.5rem;
      margin-top: 2rem;
    }

    & > *:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    .form-group {
      display: flex;
      flex-direction: column;

      .input-border-describtion {
        border: 0.1rem solid var(--border-color);
        padding: 0.8rem 1rem;
        border-radius: 0.5rem;

        &:focus {
          border: 0.1rem solid var(--border-color);
          outline: none;
        }
      }

      .label {
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
      }

      .input-border {
        border: 0.1rem solid var(--border-color);
        padding: 0.8rem 2rem;
        border-radius: 0.5rem;

        &:focus {
          border: 0.1rem solid var(--border-color);
          outline: none;
        }
      }
    }

    .form-group-unit-cost {
      display: flex;
      flex-direction: column;

      .title-text {
        margin-bottom: 0.5rem;
      }

      .wrap {
        border: 0.1rem solid var(--border-color);
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        background-color: var(--white-color);

        .label {
          padding-right: 1rem;
          border-right: 0.1rem solid #bbbbbb;
        }

        input {
          border: none;
          outline: none;
          flex: 1;
          padding: 0.5rem 1rem;

          &:focus {
            border: none;
            outline: none;
          }
        }
      }
    }

    .two-group-input {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 1.5rem;

      .form-group {
        display: flex;
        flex-direction: column;

        .label {
          margin-bottom: 0.5rem;
        }

        .input-border {
          border: 0.1rem solid var(--border-color);
          padding: 1rem 1rem;
          width: 70%;
          border-radius: 0.5rem;

          &:focus {
            border: 0.1rem solid var(--border-color);
            outline: none;
          }
        }
      }
    }

    .form-group-two {
      display: flex;
      flex-direction: column;

      .title-item {
        margin-bottom: 0.5rem;
      }

      .wrap {
        display: flex;
        align-items: center;
        margin-left: 1.5rem;
        margin-top: 0.5rem;

        .check-box {
          .checkbox-list {
            display: none;

            &:checked ~ .label span::after {
              visibility: visible;
            }
          }

          .label {
            display: flex;
            align-items: center;
            cursor: pointer;

            span {
              display: inline-block;
              width: 1.5rem;
              height: 1.5rem;
              border: 0.1rem solid #424242;
              border-radius: 50%;
              margin-right: 0.5rem;
              position: relative;

              &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 65%;
                height: 65%;
                border-radius: 50%;
                background-color: #424242;
                visibility: hidden;
              }
            }
          }
        }
      }
    }
  }
}
