.deworm-vaccination-diet-wrap {
  min-width: 65rem;
  min-height: 45rem;
  background-color: #ffffff;
  position: relative;
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  padding-bottom: 5rem;
}
.deworm-vaccination-diet-wrap .close-icon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 0.1rem solid var(--black-color);
  display: grid;
  place-items: center;
  transition: all 0.3s;
  cursor: pointer;
}
.deworm-vaccination-diet-wrap .close-icon:hover {
  transform: scale(1.04);
}
.deworm-vaccination-diet-wrap .close-icon .icon {
  width: 1.5rem;
  height: 1.5rem;
}
.deworm-vaccination-diet-wrap .modal-title {
  margin-bottom: 3rem;
  align-self: center;
  font-size: 1.6rem;
}
.deworm-vaccination-diet-wrap .no-content-box {
  width: 100%;
  display: grid;
  place-items: center;
}
.deworm-vaccination-diet-wrap .no-content-box .wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.deworm-vaccination-diet-wrap .no-content-box .wrap .img-box {
  width: 15rem;
  height: 15rem;
}
.deworm-vaccination-diet-wrap .no-content-box .wrap .text {
  color: #bbbbbb;
  font-size: 1.3rem;
  font-weight: 500;
}
.deworm-vaccination-diet-wrap .content-box {
  min-height: 25rem;
  width: 75%;
  flex-direction: column;
  display: flex;
  margin: 0rem auto;
}
.deworm-vaccination-diet-wrap .content-box .small-title {
  align-self: center;
  color: #bbbbbb;
  font-weight: 500;
  margin-bottom: 3rem;
}
.deworm-vaccination-diet-wrap .content-box .row-wrap {
  flex-direction: column;
  display: flex;
  width: 100%;
}
.deworm-vaccination-diet-wrap .content-box .row-wrap > *:not(:last-child) {
  border-bottom: 0.1rem solid rgba(66, 66, 66, 0.25);
}
.deworm-vaccination-diet-wrap .content-box .row-wrap .row {
  display: flex;
  align-items: center;
  padding: 2rem 0rem;
  justify-content: space-between;
  font-size: 1.25rem;
}
.deworm-vaccination-diet-wrap .content-box .bottom-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 3rem;
}
.deworm-vaccination-diet-wrap .content-box .bottom-box .box {
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
}
.deworm-vaccination-diet-wrap .content-box .bottom-box .box .light {
  opacity: 0.5;
  margin-bottom: 0.5rem;
}
.deworm-vaccination-diet-wrap .content-box .bottom-box .box .bold {
  font-weight: 700;
}
.deworm-vaccination-diet-wrap .content-box .top-box {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 3rem;
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  border-bottom: 0.1rem solid rgba(66, 66, 66, 0.25);
}
.deworm-vaccination-diet-wrap .content-box .top-box .box {
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
}
.deworm-vaccination-diet-wrap .content-box .top-box .box .light {
  opacity: 0.5;
  margin-bottom: 0.5rem;
}
.deworm-vaccination-diet-wrap .content-box .top-box .box .bold {
  font-weight: 700;
}
.deworm-vaccination-diet-wrap .btn-reuse {
  margin-top: 5rem;
  align-self: center;
}/*# sourceMappingURL=DewormVaccinationDietModal.css.map */