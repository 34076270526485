.change-password-modal-wrap {
  width: 50rem;
  min-height: 55rem;
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;

  .close-icon {
    position: absolute;
    top: 2rem;
    right: 2rem;
    // background-color: red;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 0.1rem solid var(--black-color);
    display: grid;
    place-items: center;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      transform: scale(1.04);
    }

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .title {
    align-self: center;
    margin-top: 4rem;
    font-weight: 500;
  }

  .form {
    width: 80%;
    margin: 3rem auto;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    // background-color: red;

    & > *:not(:last-child) {
      margin-bottom: 2.5rem;
    }

    .input-border-select {
      padding: 0.7rem 1rem;
      border: 0.1rem solid var(--border-color);
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;

      & > * {
        border: none !important;
        background-color: #f5f7f7;
      }
    }

    .three-group-boxes {
      display: grid;
      grid-template-columns: 1.5fr;
      grid-column-gap: 4rem;
    }

    .two-group-boxes {
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: 4rem;
    }

    .btn-reuse {
      align-self: center;
      margin-top: 3rem;
    }

    .picture-detail-box {
      width: 100%;
      // min-height: 20rem;
      // background-color: red;
      display: flex;
    }

    .form-group {
      display: flex;
      //   width: 70%;

      .input-wrap {
        border: 0.1rem solid var(--border-color);
        border-radius: 0.5rem;
        padding: 0.3rem 1rem;
      }

      .input {
        margin: 1rem 0rem 3rem 0rem;
        background-color: #f5f7f7;
        border: none;
        outline: none;
        padding: 1rem 1.5rem;
        border-radius: 1rem;
        width: 100%;

        &::placeholder {
          color: #bbbbbb;
        }

        &:focus {
          border: none;
          outline: none;
        }
      }

      .label {
        margin-right: 0.7rem;
        cursor: pointer;
      }

      .label-calendar {
        margin-left: 0.7rem;
        margin-right: unset;
      }

      .input-calender {
        border: unset;
        padding: 1.2rem 0rem;
        color: rgba(66, 66, 66, 0.5);
        background-color: #f5f7f7;
        order: -1;

        &:focus {
          border: none;
          outline: none;
        }
      }
    }

    .form-group-text-area {
      width: 100%;
    }

    .form-group {
      display: flex;
      flex-direction: column;

      .input-wrap {
        border: 0.1rem solid var(--border-color);
        border-radius: 0.8rem;
        padding: 0.5rem 1rem;
        width: 100%;
        // background-color: red;

        .input {
          margin: unset;
        }
      }

      .label {
        margin-bottom: 0.5rem;
      }

      .group-two {
        display: flex;
        flex-direction: row;
        border: 0.1rem solid var(--border-color);
        border-radius: 0.5rem;
        padding: 0.3rem 1rem;
        align-items: center;

        & > :last-child {
          flex: 1;
        }
      }

      .input-border {
        border: 0.1rem solid var(--border-color);
        padding: 1rem 1rem;
        width: 100%;
        border-radius: 0.5rem;

        &:focus {
          border: 0.1rem solid var(--border-color);
          outline: none;
        }
      }
    }

    .distinguish-box {
      // background-color: red;
      .form-group {
        display: flex;
        flex-direction: column;
        // background-color: red;
        border: 0.1rem solid var(--border-color);
        padding: 0.8rem 1rem;
        border-radius: 0.8rem;
        margin-top: 1rem;

        .input {
          margin: unset;
        }

        .input-border-describtion {
          border: 0.1rem solid var(--border-color);
          padding: 0.8rem 1rem;
          border-radius: 0.5rem;

          &:focus {
            border: 0.1rem solid var(--border-color);
            outline: none;
          }
        }

        .label {
          margin-bottom: 0.5rem;
          font-size: 1.2rem;
        }

        .input-border {
          border: 0.1rem solid var(--border-color);
          padding: 0.8rem 2rem;
          border-radius: 0.5rem;

          &:focus {
            border: 0.1rem solid var(--border-color);
            outline: none;
          }
        }
      }
    }

    .form-group {
      display: flex;
      flex-direction: column;

      .input-border-describtion {
        border: 0.1rem solid var(--border-color);
        padding: 0.8rem 1rem;
        border-radius: 0.5rem;

        &:focus {
          border: 0.1rem solid var(--border-color);
          outline: none;
        }
      }

      .label {
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
      }

      .input-border {
        border: 0.1rem solid var(--border-color);
        padding: 0.8rem 2rem;
        border-radius: 0.5rem;

        &:focus {
          border: 0.1rem solid var(--border-color);
          outline: none;
        }
      }
    }
  }
}

.system-setting-modal-wrap {
  width: 85rem;
  min-height: 65rem;
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  overflow-y: scroll;

  .close-icon {
    position: absolute;
    top: 2rem;
    right: 2rem;
    // background-color: red;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 0.1rem solid var(--black-color);
    display: grid;
    place-items: center;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      transform: scale(1.04);
    }

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;

    .content-title {
      font-weight: 700;
      color: #424242;
      font-size: 1.6rem;
      margin-bottom: 1rem;
      text-align: center;
    }

    .content-text {
      opacity: 0.45;
    }

    .switch-box {
      display: grid;
      width: 50%;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 3rem;
      padding-bottom: 1rem;
      border-bottom: .1rem solid rgba(187, 187, 187, .5);

      .text{
        text-align: center;
        cursor: pointer;
        position: relative;
      }

      .text-active{
        font-weight: 700;
        color: #FFA72C;

        &::after{
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            height: .4rem;
            width: 100%;
            border-radius: 2rem;
            background-color: #FFA72C;
            transform: translateY(1rem);
        }
      }
    }


    .metrics-box {
        width: 80%;
        margin-top: -4rem;
    
        .title {
            font-size: 1.3rem;
            font-weight: 300;
            margin-bottom: .7rem;
        //   font-weight: 600;
        //   font-size: 1.8rem;
        //   margin-bottom: 1rem;
        //   text-transform: capitalize;
        }
    
        .detail-box {
            position: relative;
          min-height: 5rem;
          border: 0.1rem solid var(--border-color);
          border-radius: 1rem;
          padding: 2rem;
          cursor: pointer;
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
    
          &>*:not(:last-child) {
            margin-right: 1rem;
          }
    
          .info {
            color: #bbbbbb;
            position: absolute;
            top: 2rem;
            left: 2rem;
            z-index: 1;
          }
    
          .input-btn-box{
            // position: absolute;
            // position: absolute;
            // top: 2rem;
            // left: 2rem;
            // z-index: 3;
            display: flex;
            align-items: center;
            margin-left: 1.5rem;
    
            .metrics-input{
                padding: 1rem;
                border: .1rem solid var(--dark-yellow-color);
                border-radius: .8rem;
                // margin-right: 1rem;
    
                &:focus{
                    outline: none;
                    border: .1rem solid var(--dark-yellow-color);
                }
            }
    
            .icon{
                color: var(--dark-yellow-color);
                cursor: pointer;
                transform: translateY(.7rem);
                width: 2rem;
                height: 2rem;
            }
          }
    
          .input-btn-box-active{
            padding: 1rem;
            border-radius: .6rem;
            box-shadow: var(--box-shadow-one);
            background-color: var(--white-color);
          }
        }
      }
  }
}
