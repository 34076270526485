.create-appointment-modal {
  width: 50rem;
  min-height: 45rem;
  background-color: #ffffff;
  position: relative;
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  padding-bottom: 5rem;

  .close-icon {
    position: absolute;
    top: 2rem;
    right: 2rem;
    // background-color: red;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 0.1rem solid var(--black-color);
    display: grid;
    place-items: center;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      transform: scale(1.04);
    }

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .detail-note-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-self: center;
    width: 70%;
    border-bottom: 0.1rem solid #bbbbbb;

    .text {
      padding: 1rem;
      color: #bbbbbb;
      font-weight: 400;
      position: relative;
      text-align: center;
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.4rem;
        background-color: currentColor;
        transform: scale(0) translateY(50%);
      }
    }

    .text-active {
      color: #ffa72c;
      font-weight: 700;

      &::after {
        transform: scale(1) translateY(50%);
      }
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    padding: 0rem 4rem;

    .btn-reuse{
        padding: 1.4rem 3rem;
        width: 70%;
        align-self: center;
    }

    .group-one {
      margin-bottom: 3rem;
      display: flex;
      flex-direction: column;
      padding-bottom: 3rem;
      border-bottom: 0.1rem solid rgba(66, 66, 66, .25);

      & > *:not(:last-child) {
        margin-bottom: 2rem;
      }
    }

    .group-two{
        margin-bottom: 3rem;
        display: flex;
        flex-direction: column;
  
        & > *:not(:last-child) {
          margin-bottom: 2rem;
        }
    }

  

    .form-group {
      display: flex;
      width: 70%;

      .input {
        margin: 1rem 0rem 3rem 0rem;
        background-color: #F5F7F7;
        border: none;
        outline: none;
        padding: 1rem 1.5rem;
        border-radius: 1rem;
        width: 100%;
    
        &::placeholder {
          color: #bbbbbb;
        }
    
        &:focus {
          border: none;
          outline: none;
        }
      }

      .label {
        margin-right: 0.7rem;
        cursor: pointer;
      }

      .input-calender {
        border: unset;
        padding: 1rem 0rem;
        color: rgba(66, 66, 66, 0.5);

        &:focus {
          border: none;
          outline: none;
        }
      }
    }

    .form-group-text-area{
        width: 100%;
    }

    .form-group {
      display: flex;
      flex-direction: column;

      .label {
        margin-bottom: 0.5rem;
      }

      .group-two{
        display: flex;
        flex-direction: row;
        border: 0.1rem solid var(--border-color);
        border-radius: 0.5rem;
        padding: .3rem 1rem;
        align-items: center;

        &>:last-child{
            flex: 1;
        }
      }

      .input-border {
        border: 0.1rem solid var(--border-color);
        padding: 1rem 1rem;
        width: 70%;
        border-radius: 0.5rem;

        &:focus {
          border: 0.1rem solid var(--border-color);
          outline: none;
        }
      }
    }

    .form-group {
      display: flex;
      flex-direction: column;

      .input-border-describtion {
        border: 0.1rem solid var(--border-color);
        padding: 0.8rem 1rem;
        border-radius: 0.5rem;

        &:focus {
          border: 0.1rem solid var(--border-color);
          outline: none;
        }
      }

      .label {
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
      }

      .input-border {
        border: 0.1rem solid var(--border-color);
        padding: 0.8rem 2rem;
        border-radius: 0.5rem;

        &:focus {
          border: 0.1rem solid var(--border-color);
          outline: none;
        }
      }
    }
  }
}
