.dashboard-layout-wrap {
  width: 100%;
  min-height: 100vh;
  position: relative;
}
.dashboard-layout-wrap .sidebar-wrap {
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 25rem;
  z-index: 4;
  padding: 0.6rem 1rem;
  overflow-y: scroll;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.dashboard-layout-wrap .sidebar-wrap::-webkit-scrollbar {
  display: none;
}
.dashboard-layout-wrap .sidebar-wrap > * {
  position: relative;
  z-index: 2;
}
.dashboard-layout-wrap .sidebar-wrap::after {
  content: "";
  width: 25rem;
  height: 50%;
  background-color: var(--dark-yellow-color);
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(1rem);
  z-index: 1;
}
.dashboard-layout-wrap .sidebar-wrap::before {
  content: "";
  width: 25rem;
  height: 50%;
  background-color: #84bfcd;
  opacity: 0.5;
  position: absolute;
  bottom: 0;
  left: 0;
  filter: blur(1rem);
  z-index: 1;
}
.dashboard-layout-wrap .header-wrap {
  position: fixed;
  right: 0;
  top: 0;
  height: 9rem;
  width: calc(100vw - 25rem);
  z-index: 4;
  background-color: var(--white-color);
}
.dashboard-layout-wrap .main-wrap {
  position: absolute;
  right: 0;
  top: 9rem;
  min-height: calc(100vh - 9rem);
  width: calc(100vw - 25rem);
  z-index: 1;
  overflow-y: scroll;
  box-shadow: -0.1rem 0rem 0.1rem rgba(0, 0, 0, 0.05);
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.dashboard-layout-wrap .main-wrap::-webkit-scrollbar {
  display: none;
}
.dashboard-layout-wrap .main-wrap::-webkit-scrollbar {
  width: 0.6rem;
}
.dashboard-layout-wrap .main-wrap::-webkit-scrollbar-track {
  background: transparent;
}
.dashboard-layout-wrap .main-wrap::-webkit-scrollbar-thumb {
  background: var(--dark-yellow-color);
  border-radius: 30rem;
}
.dashboard-layout-wrap .main-wrap::-webkit-scrollbar-thumb:hover {
  background: var(--dark-yellow-color);
}
.dashboard-layout-wrap .main-wrap > * {
  padding: 2rem 4rem;
}
.dashboard-layout-wrap .main-wrap > *:first-child {
  padding: unset;
}/*# sourceMappingURL=DashboardLayout.css.map */