.common-parent-detail-modal-wrap {
  width: 80rem;
  min-height: 55rem;
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;

  .close-icon {
    position: absolute;
    top: 2rem;
    right: 2rem;
    // background-color: red;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 0.1rem solid var(--black-color);
    display: grid;
    place-items: center;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      transform: scale(1.04);
    }

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .content-wrap {
    width: 100%;
    flex: 1;
    display: flex;

    & > * {
      padding: 5rem 0rem;
    }

    .left-box {
      flex: 0 0 30%;
      border-right: 0.05rem solid rgba(255, 167, 44, 0.5);

      .list {
        display: flex;
        flex-direction: column;

        & > *:not(:last-child) {
          margin-bottom: 1rem;
        }

        .item {
          align-self: flex-end;
          padding: 1.5rem 0rem 1.5rem 2rem;
          font-size: 1.5rem;
          position: relative;
          width: 85%;
          cursor: pointer;

          span {
            opacity: 0.25;
          }

          &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 167, 44, 0.25);
            border-top-left-radius: 2rem;
            border-bottom-left-radius: 2rem;
            transform: scale(0);
          }
        }

        .item-active {
          span {
            opacity: 1;
            font-weight: 600;
          }

          &::after {
            transform: scale(1);
          }
        }
      }
    }

    .right-box {
        flex: 1;
      padding-left: 3rem;
      display: flex;
      flex-direction: column;
      padding-right: 3rem;
      overflow-y: scroll;

      .transaction-history-box{
        width: 100%;
        display: flex;
        flex-direction: column;
        // padding-right: 3rem;

        .text-value-box{
            align-self: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 3rem 4rem;
            background-color: rgba(0, 192, 19, 0.15);
            border-radius: 1rem;
            margin-bottom: 3rem;

            .value{
                color: #00C013;
                font-size: 2vw;
                font-weight: 600;
                margin-top: 1rem;
            }
        }
        // background-color: red;

        .btn-reuse{
            align-self: flex-start;
        }

        .reset-text-box{
            margin: 2rem 0rem -2rem 0rem;
            display: flex;
            align-items: center;
            font-size: 1.3rem;

            .text{
                span{
                    font-weight: 800;
                }
            }
            .reset{
                margin: 0rem auto;
                font-weight: 800;
                color: #ffa72c;
                border-bottom: .1rem solid currentColor;
                cursor: pointer;
            }
        }

        .subtitle{
            font-size: 1.3rem;
        }

        .calender-box {
            display: flex;
            align-items: center;
            margin: 2rem 0rem;
        
            .text {
              margin-right: 1.3rem;
              margin-bottom: unset;
            }
        
            .form-group {
              display: flex;
              align-items: center;
              padding: 0.2rem 1rem;
              border: 0.1rem solid rgba(66, 66, 66, 0.5);
              border-radius: 0.8rem;
        
              .label {
                margin-right: 0.7rem;
                cursor: pointer;
              }
        
              .input-calender {
                border: unset;
                padding: 1rem 0rem;
                // color: rgba(66, 66, 66, 0.5);
        
                &:focus {
                  border: none;
                  outline: none;
                }
              }
            }
          }
      }

      .appointment-box{
        
        .switch-box{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            align-self: center;
            width: 100%;
            border-bottom: 0.1rem solid #bbbbbb;
        
            .switch {
              padding: 1rem;
              color: #bbbbbb;
              font-weight: 400;
              position: relative;
              text-align: center;
              cursor: pointer;
        
              &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 0.4rem;
                background-color: currentColor;
                transform: scale(0) translateY(50%);
              }
            }
        
            .switch-active {
              color: #ffa72c;
              font-weight: 700;
        
              &::after {
                transform: scale(1) translateY(50%);
              }
            }
        }
      }

      .history-box{
        margin-top: 4rem;
        display: flex;
        flex-direction: column;

        .btn-reuse{
            align-self: flex-start;
        }

        .reset-text-box{
            margin: 2rem 0rem -2rem 0rem;
            display: flex;
            align-items: center;
            font-size: 1.3rem;

            .text{
                span{
                    font-weight: 800;
                }
            }
            .reset{
                margin: 0rem auto;
                font-weight: 800;
                color: #ffa72c;
                border-bottom: .1rem solid currentColor;
                cursor: pointer;
            }
        }

        .subtitle{
            font-size: 1.3rem;
        }

        .calender-box {
            display: flex;
            align-items: center;
            margin: 2rem 0rem;
        
            .text {
              margin-right: 1.3rem;
              margin-bottom: unset;
            }
        
            .form-group {
              display: flex;
              align-items: center;
              padding: 0.2rem 1rem;
              border: 0.1rem solid rgba(66, 66, 66, 0.5);
              border-radius: 0.8rem;
        
              .label {
                margin-right: 0.7rem;
                cursor: pointer;
              }
        
              .input-calender {
                border: unset;
                padding: 1rem 0rem;
                // color: rgba(66, 66, 66, 0.5);
        
                &:focus {
                  border: none;
                  outline: none;
                }
              }
            }
          }
      }

      .pet-wrap-box {

      }

      .profile-box {
        .bottom-box {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 3rem;
          margin-top: 4rem;

          .box {
            display: flex;
            flex-direction: column;
            font-size: 1.25rem;

            .light {
              opacity: 0.5;
              margin-bottom: 0.5rem;
            }

            .bold {
              font-weight: 700;
            }

            .bold-green {
              color: #00c013;
            }
          }
        }

        .picture-box {
          display: flex;
          align-items: center;

          & > *:not(:last-child) {
            margin-right: 2rem;
          }

          .box {
            display: flex;
            flex-direction: column;
            font-size: 1.25rem;

            .light {
              opacity: 0.5;
              margin-bottom: 0.5rem;
            }

            .bold {
              font-weight: 700;
            }
          }
          .active {
            background-color: rgba(0, 192, 19, 0.15);
            color: #00c013;
            padding: 1rem 2rem;
            border-radius: 3rem;
            font-weight: 700;
            font-size: 1.3rem;
          }

          .disabled {
            background-color: rgba(222, 11, 11, 0.15);
            color: #de0b0b;
          }

          .img-show-wrap {
            width: 15rem;
            height: 15rem;
            border-radius: 50%;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
}
