.common-parent-detail-modal-wrap {
  width: 80rem;
  min-height: 55rem;
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
}
.common-parent-detail-modal-wrap .close-icon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 0.1rem solid var(--black-color);
  display: grid;
  place-items: center;
  transition: all 0.3s;
  cursor: pointer;
}
.common-parent-detail-modal-wrap .close-icon:hover {
  transform: scale(1.04);
}
.common-parent-detail-modal-wrap .close-icon .icon {
  width: 1.5rem;
  height: 1.5rem;
}
.common-parent-detail-modal-wrap .content-wrap {
  width: 100%;
  flex: 1;
  display: flex;
}
.common-parent-detail-modal-wrap .content-wrap > * {
  padding: 5rem 0rem;
}
.common-parent-detail-modal-wrap .content-wrap .left-box {
  flex: 0 0 30%;
  border-right: 0.05rem solid rgba(255, 167, 44, 0.5);
}
.common-parent-detail-modal-wrap .content-wrap .left-box .list {
  display: flex;
  flex-direction: column;
}
.common-parent-detail-modal-wrap .content-wrap .left-box .list > *:not(:last-child) {
  margin-bottom: 1rem;
}
.common-parent-detail-modal-wrap .content-wrap .left-box .list .item {
  align-self: flex-end;
  padding: 1.5rem 0rem 1.5rem 2rem;
  font-size: 1.5rem;
  position: relative;
  width: 85%;
  cursor: pointer;
}
.common-parent-detail-modal-wrap .content-wrap .left-box .list .item span {
  opacity: 0.25;
}
.common-parent-detail-modal-wrap .content-wrap .left-box .list .item::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 167, 44, 0.25);
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
  transform: scale(0);
}
.common-parent-detail-modal-wrap .content-wrap .left-box .list .item-active span {
  opacity: 1;
  font-weight: 600;
}
.common-parent-detail-modal-wrap .content-wrap .left-box .list .item-active::after {
  transform: scale(1);
}
.common-parent-detail-modal-wrap .content-wrap .right-box {
  flex: 1;
  padding-left: 3rem;
  display: flex;
  flex-direction: column;
  padding-right: 3rem;
  overflow-y: scroll;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .transaction-history-box {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .transaction-history-box .text-value-box {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 4rem;
  background-color: rgba(0, 192, 19, 0.15);
  border-radius: 1rem;
  margin-bottom: 3rem;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .transaction-history-box .text-value-box .value {
  color: #00C013;
  font-size: 2vw;
  font-weight: 600;
  margin-top: 1rem;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .transaction-history-box .btn-reuse {
  align-self: flex-start;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .transaction-history-box .reset-text-box {
  margin: 2rem 0rem -2rem 0rem;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .transaction-history-box .reset-text-box .text span {
  font-weight: 800;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .transaction-history-box .reset-text-box .reset {
  margin: 0rem auto;
  font-weight: 800;
  color: #ffa72c;
  border-bottom: 0.1rem solid currentColor;
  cursor: pointer;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .transaction-history-box .subtitle {
  font-size: 1.3rem;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .transaction-history-box .calender-box {
  display: flex;
  align-items: center;
  margin: 2rem 0rem;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .transaction-history-box .calender-box .text {
  margin-right: 1.3rem;
  margin-bottom: unset;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .transaction-history-box .calender-box .form-group {
  display: flex;
  align-items: center;
  padding: 0.2rem 1rem;
  border: 0.1rem solid rgba(66, 66, 66, 0.5);
  border-radius: 0.8rem;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .transaction-history-box .calender-box .form-group .label {
  margin-right: 0.7rem;
  cursor: pointer;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .transaction-history-box .calender-box .form-group .input-calender {
  border: unset;
  padding: 1rem 0rem;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .transaction-history-box .calender-box .form-group .input-calender:focus {
  border: none;
  outline: none;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .appointment-box .switch-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-self: center;
  width: 100%;
  border-bottom: 0.1rem solid #bbbbbb;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .appointment-box .switch-box .switch {
  padding: 1rem;
  color: #bbbbbb;
  font-weight: 400;
  position: relative;
  text-align: center;
  cursor: pointer;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .appointment-box .switch-box .switch::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.4rem;
  background-color: currentColor;
  transform: scale(0) translateY(50%);
}
.common-parent-detail-modal-wrap .content-wrap .right-box .appointment-box .switch-box .switch-active {
  color: #ffa72c;
  font-weight: 700;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .appointment-box .switch-box .switch-active::after {
  transform: scale(1) translateY(50%);
}
.common-parent-detail-modal-wrap .content-wrap .right-box .history-box {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .history-box .btn-reuse {
  align-self: flex-start;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .history-box .reset-text-box {
  margin: 2rem 0rem -2rem 0rem;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .history-box .reset-text-box .text span {
  font-weight: 800;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .history-box .reset-text-box .reset {
  margin: 0rem auto;
  font-weight: 800;
  color: #ffa72c;
  border-bottom: 0.1rem solid currentColor;
  cursor: pointer;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .history-box .subtitle {
  font-size: 1.3rem;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .history-box .calender-box {
  display: flex;
  align-items: center;
  margin: 2rem 0rem;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .history-box .calender-box .text {
  margin-right: 1.3rem;
  margin-bottom: unset;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .history-box .calender-box .form-group {
  display: flex;
  align-items: center;
  padding: 0.2rem 1rem;
  border: 0.1rem solid rgba(66, 66, 66, 0.5);
  border-radius: 0.8rem;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .history-box .calender-box .form-group .label {
  margin-right: 0.7rem;
  cursor: pointer;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .history-box .calender-box .form-group .input-calender {
  border: unset;
  padding: 1rem 0rem;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .history-box .calender-box .form-group .input-calender:focus {
  border: none;
  outline: none;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .profile-box .bottom-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem;
  margin-top: 4rem;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .profile-box .bottom-box .box {
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .profile-box .bottom-box .box .light {
  opacity: 0.5;
  margin-bottom: 0.5rem;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .profile-box .bottom-box .box .bold {
  font-weight: 700;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .profile-box .bottom-box .box .bold-green {
  color: #00c013;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .profile-box .picture-box {
  display: flex;
  align-items: center;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .profile-box .picture-box > *:not(:last-child) {
  margin-right: 2rem;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .profile-box .picture-box .box {
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .profile-box .picture-box .box .light {
  opacity: 0.5;
  margin-bottom: 0.5rem;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .profile-box .picture-box .box .bold {
  font-weight: 700;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .profile-box .picture-box .active {
  background-color: rgba(0, 192, 19, 0.15);
  color: #00c013;
  padding: 1rem 2rem;
  border-radius: 3rem;
  font-weight: 700;
  font-size: 1.3rem;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .profile-box .picture-box .disabled {
  background-color: rgba(222, 11, 11, 0.15);
  color: #de0b0b;
}
.common-parent-detail-modal-wrap .content-wrap .right-box .profile-box .picture-box .img-show-wrap {
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}/*# sourceMappingURL=CommonParentDetailModal.css.map */