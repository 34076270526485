.add-new-user-modal-wrap {
  width: 80rem;
  min-height: 55rem;
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
}
.add-new-user-modal-wrap .close-icon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 0.1rem solid var(--black-color);
  display: grid;
  place-items: center;
  transition: all 0.3s;
  cursor: pointer;
}
.add-new-user-modal-wrap .close-icon:hover {
  transform: scale(1.04);
}
.add-new-user-modal-wrap .close-icon .icon {
  width: 1.5rem;
  height: 1.5rem;
}
.add-new-user-modal-wrap .title {
  align-self: center;
  margin-top: 4rem;
  font-weight: 500;
}
.add-new-user-modal-wrap .form {
  width: 80%;
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
}
.add-new-user-modal-wrap .form > *:not(:last-child) {
  margin-bottom: 2.5rem;
}
.add-new-user-modal-wrap .form .input-border-select {
  padding: 0.7rem 1rem;
  border: 0.1rem solid var(--border-color);
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
}
.add-new-user-modal-wrap .form .input-border-select > * {
  border: none !important;
  background-color: #F5F7F7;
}
.add-new-user-modal-wrap .form .three-group-boxes {
  display: grid;
  grid-template-columns: 1.5fr 1.5fr;
  grid-column-gap: 4rem;
}
.add-new-user-modal-wrap .form .two-group-boxes {
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  grid-column-gap: 4rem;
}
.add-new-user-modal-wrap .form .btn-reuse {
  align-self: center;
  margin-top: 3rem;
}
.add-new-user-modal-wrap .form .picture-detail-box {
  width: 100%;
  display: flex;
}
.add-new-user-modal-wrap .form .form-group {
  display: flex;
}
.add-new-user-modal-wrap .form .form-group .input-wrap {
  border: 0.1rem solid var(--border-color);
  border-radius: 0.5rem;
  padding: 0.3rem 1rem;
}
.add-new-user-modal-wrap .form .form-group .input {
  margin: 1rem 0rem 3rem 0rem;
  background-color: #F5F7F7;
  border: none;
  outline: none;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  width: 100%;
}
.add-new-user-modal-wrap .form .form-group .input::-moz-placeholder {
  color: #bbbbbb;
}
.add-new-user-modal-wrap .form .form-group .input::placeholder {
  color: #bbbbbb;
}
.add-new-user-modal-wrap .form .form-group .input:focus {
  border: none;
  outline: none;
}
.add-new-user-modal-wrap .form .form-group .label {
  margin-right: 0.7rem;
  cursor: pointer;
}
.add-new-user-modal-wrap .form .form-group .label-calendar {
  margin-left: 0.7rem;
  margin-right: unset;
}
.add-new-user-modal-wrap .form .form-group .input-calender {
  border: unset;
  padding: 1.2rem 0rem;
  color: rgba(66, 66, 66, 0.5);
  background-color: #F5F7F7;
  order: -1;
}
.add-new-user-modal-wrap .form .form-group .input-calender:focus {
  border: none;
  outline: none;
}
.add-new-user-modal-wrap .form .form-group-text-area {
  width: 100%;
}
.add-new-user-modal-wrap .form .form-group {
  display: flex;
  flex-direction: column;
}
.add-new-user-modal-wrap .form .form-group .input-wrap {
  border: 0.1rem solid var(--border-color);
  border-radius: 0.8rem;
  padding: 0.5rem 1rem;
  width: 100%;
}
.add-new-user-modal-wrap .form .form-group .input-wrap .input {
  margin: unset;
}
.add-new-user-modal-wrap .form .form-group .label {
  margin-bottom: 0.5rem;
}
.add-new-user-modal-wrap .form .form-group .group-two {
  display: flex;
  flex-direction: row;
  border: 0.1rem solid var(--border-color);
  border-radius: 0.5rem;
  padding: 0.3rem 1rem;
  align-items: center;
}
.add-new-user-modal-wrap .form .form-group .group-two > :last-child {
  flex: 1;
}
.add-new-user-modal-wrap .form .form-group .input-border {
  border: 0.1rem solid var(--border-color);
  padding: 1rem 1rem;
  width: 100%;
  border-radius: 0.5rem;
}
.add-new-user-modal-wrap .form .form-group .input-border:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}
.add-new-user-modal-wrap .form .distinguish-box .form-group {
  display: flex;
  flex-direction: column;
  border: 0.1rem solid var(--border-color);
  padding: 0.8rem 1rem;
  border-radius: 0.8rem;
  margin-top: 1rem;
}
.add-new-user-modal-wrap .form .distinguish-box .form-group .input {
  margin: unset;
}
.add-new-user-modal-wrap .form .distinguish-box .form-group .input-border-describtion {
  border: 0.1rem solid var(--border-color);
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
}
.add-new-user-modal-wrap .form .distinguish-box .form-group .input-border-describtion:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}
.add-new-user-modal-wrap .form .distinguish-box .form-group .label {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}
.add-new-user-modal-wrap .form .distinguish-box .form-group .input-border {
  border: 0.1rem solid var(--border-color);
  padding: 0.8rem 2rem;
  border-radius: 0.5rem;
}
.add-new-user-modal-wrap .form .distinguish-box .form-group .input-border:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}
.add-new-user-modal-wrap .form .form-group {
  display: flex;
  flex-direction: column;
}
.add-new-user-modal-wrap .form .form-group .input-border-describtion {
  border: 0.1rem solid var(--border-color);
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
}
.add-new-user-modal-wrap .form .form-group .input-border-describtion:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}
.add-new-user-modal-wrap .form .form-group .label {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}
.add-new-user-modal-wrap .form .form-group .input-border {
  border: 0.1rem solid var(--border-color);
  padding: 0.8rem 2rem;
  border-radius: 0.5rem;
}
.add-new-user-modal-wrap .form .form-group .input-border:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}/*# sourceMappingURL=AddNewUserModal.css.map */