.dashboard-home-wrap {
  width: 100%;
  height: calc(100vh - 9rem);
  display: flex;
  position: relative;
  padding-right: 0rem !important;
  padding-bottom: 0rem !important;

  .left-box {
    flex: 0 0 70%;
    margin-right: 4rem;
    // background-color: red;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    padding-bottom: 5rem;

    & > *:not(:last-child) {
      margin-bottom: 3rem;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    .card-wrap-box {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 4rem;

      .card {
        min-height: 22rem;
        border-radius: 1.5rem;
        background-repeat: no-repeat;
        background-position: right 8.5rem;
        background-size: 60%;
        color: #ffffff;

        .wrap {
          width: 100%;
          height: 100%;
          padding: 2rem 3rem;
          position: relative;
          //   filter: drop-shadow(0.1rem 0.1rem 4rem 0.5rem rgb(179, 152, 255));
          //   background-color: red;
          display: flex;
          flex-direction: column;
        }

        .value {
          font-size: 3.2vw;
          font-weight: 700;
          //   margin-bottom: 1rem;
        }

        .label {
          font-size: 1.6rem;
        }

        .btn {
          margin-top: auto;
          align-self: flex-start;
          padding: 0.9rem 1.1rem;
          border-radius: 1.5rem;
          border: none;
          outline: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          color: #ffffff;
          justify-content: center;
          transform: translateX(-1rem);

          &:hover .icon {
            transform: translateX(0.5rem);
          }

          span {
            font-size: 1.25rem;
          }

          .icon {
            width: 1.2rem;
            height: 1.2rem;
            transition: all 0.5s;
            margin-left: 0.7rem;
          }
        }
      }

      .green {
        background-color: #a7e888;
        background-image: url("../../../assets/home-wallet-balance-bg-img.png");
        background-position: right 10rem;

        .wrap {
          .btn {
            background-color: #5c9f3b;
          }
        }
      }

      .blue {
        background-color: #abc3f3;
        background-image: url("../../../assets/home-service-provider-bg-img.png");
        background-size: 75%;
        background-position: right 10.7rem;

        .wrap {
          .btn {
            background-color: #3d80ff;
          }
        }
      }

      .purple {
        background-color: #b398ff;
        background-image: url("../../../assets/home-pet-bg-img.png");

        .wrap {
          .btn {
            background-color: #7849fe;
            color: rgba(204, 9, 5, 0.3);
          }
        }
      }

      //   .purple {
      //     &::before {
      //       content: "";
      //       position: absolute;
      //       z-index: 2;
      //       top: 0;
      //       left: 0;
      //       border-radius: 1.5rem;
      //       height: 100%;
      //       width: 100%;
      //       background-color: #b398ff;
      //       background-image: url("../../../assets/home-pet-bg-img.png");
      //       background-repeat: no-repeat;
      //       background-position: right 8.5rem;
      //       background-size: 60%;
      //     //   overflow: hidden;
      //     }

      //     &::after {
      //       content: "";
      //       position: absolute;
      //       z-index: 1;
      //       top: 0;
      //       left: 0;
      //       border-radius: 1.5rem;
      //       height: 100%;
      //       width: 100%;
      //       background-color: #b398ff;
      //       filter: blur(2rem);
      //     }
      //   }
    }

    .appointment-stat-box {
      display: flex;
      flex-direction: column;
      padding-right: 0.5rem;
      margin-top: 3rem;

      .appointment-title {
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 1.5rem;
      }

      .chart-detail-box {
        height: 30rem;
        width: 100%;
        display: flex;
        padding-left: 0.5rem;

        .chart-wrap {
          flex: 0 0 80%;
          background-color: #ffffff;
          box-shadow: 0.1rem 0rem 2rem 0.2rem rgba(0, 0, 0, 0.1);
          border-radius: 1.5rem;
          margin-right: 3rem;
          padding: 1rem 0rem;
          background-image: url("../../../assets/chart-line-bg.png");
          background-repeat: no-repeat;
          background-size: contain;
          //   background-position: bottom;
        }

        .detail-wrap {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          //   color: rgba(255, 167, 44, .5);

          .select-input {
            & > *:nth-child(3) {
              box-shadow: 0rem 0.4rem 1rem rgba(0, 0, 0, 0.15);
              background-color: #ffffff;
              border-radius: 0.6rem;
              cursor: pointer;
            }
          }

          .box {
            // margin-top: 2rem;
            color: #424242;
            text-align: center;

            .bold {
              font-weight: 700;
              font-size: 3.5rem;
              margin-bottom: 0.7rem;
              // text-align: center;
            }

            .light {
              opacity: 0.45;
              font-size: 1.6rem;
            }
          }
        }
      }
    }

    .service-transaction-box {
      display: flex;
      margin-top: 3rem;
      align-items: stretch;
      //   min-height: 35rem;

      .service-box {
        flex: 0 0 50%;
        margin-right: 3rem;
        display: flex;
        flex-direction: column;

        .service-title {
          color: #424242;
          font-size: 1.5rem;
          font-weight: 700;
        }

        .see-all-request-box {
          display: flex;
          align-items: center;
          margin-top: 2rem;
          align-self: center;
          color: #424242;
          cursor: pointer;

          &:hover .icon {
            transform: translateX(0.7rem);
          }

          .text {
            font-size: 1.35rem;
            color: #424242;

            span {
              font-weight: 700;
            }
          }

          .icon {
            margin-left: 0.4rem;
            width: 1.5rem;
            height: 1.5rem;
            transition: all 0.4s;
          }
        }

        .detail-box {
          display: flex;
          flex-direction: column;
          margin-top: 2.5rem;
          background-color: #f5f7f7;
          border-radius: 1.5rem;
          padding: 3rem 2.5rem;
          flex: 1;

          & > *:not(:last-child) {
            margin-bottom: 2.5rem;
          }

          .card-box {
            display: flex;
            align-items: center;
            background-color: #ffffff;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
            padding: 2rem;
            border-radius: 1rem;

            .name-text-box {
              display: flex;
              flex-direction: column;
              margin-left: 1rem;

              & > *:not(:last-child) {
                margin-bottom: 0.8rem;
              }

              .name {
                font-weight: 700;
                font-size: 1.55rem;
              }

              .appointment {
                font-size: 1.25rem;

                span {
                  font-weight: 700;
                }
              }
            }

            .rate-stat-box {
              margin-left: auto;
              align-self: stretch;
              display: flex;
              flex-direction: column;

              .rate-box {
                display: flex;
                align-items: center;

                span {
                  font-weight: 700;
                  font-size: 1.25rem;
                  margin-left: 0.4rem;
                  display: inline-block;
                }
              }

              .status-box {
                margin-bottom: auto;
                padding: 0.8rem 1rem;
                border-radius: 0.6rem;
                background-color: rgba(0, 192, 19, 0.15);
                color: #00c013;
                font-weight: 700;
                font-size: 1rem;
                display: grid;
                place-items: center;

                .status {
                  text-transform: capitalize;
                }
              }

              .status-busy {
                background-color: rgba(222, 11, 11, 0.15);
                color: #de0b0b;
              }
            }

            .img-wrap {
              margin-right: 1rem;

              .img-box {
                width: 7.5rem;
                height: 7.5rem;
                border-radius: 50%;
                overflow: hidden;
              }
            }
          }
        }
      }

      .transaction-box {
        flex: 1;

        .transaction-title {
          color: #424242;
          font-size: 1.5rem;
          font-weight: 700;
        }

        .detail-box {
          display: flex;
          flex-direction: column;
          margin-top: 2.5rem;
          background-color: #f5f7f7;
          border-radius: 1.5rem;
          padding: 3rem 2.5rem;

          & > *:not(:last-child) {
            margin-bottom: 2.5rem;
          }

          .see-all-request-box {
            display: flex;
            align-items: center;
            margin-top: 3rem;
            align-self: center;
            color: #424242;
            cursor: pointer;

            &:hover .icon {
              transform: translateX(0.7rem);
            }

            .text {
              font-size: 1.5rem;
              color: #424242;

              span {
                font-weight: 700;
              }
            }

            .icon {
              margin-left: 0.4rem;
              width: 1.5rem;
              height: 1.5rem;
              transition: all 0.4s;
            }
          }

          .card-wrap {
            display: flex;
            align-items: center;
            background-color: #ffffff;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
            padding: 2rem;
            border-radius: 1rem;

            .status-view-box {
              align-self: stretch;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              font-size: 1.2rem;

              .view {
                cursor: pointer;
                padding: 0.8rem 1rem;
                border-radius: 0.6rem;
                background-color: rgba(69, 93, 95, 0.15);
                color: #455d5f;
                font-weight: 700;
                font-size: 1.15rem;
                display: grid;
                place-items: center;
                transition: all .3s;

                &:hover{
                    transform: translateY(-.1rem);
                }

                p {
                  text-transform: capitalize;
                }
              }

              .status {
                margin-bottom: auto;
                padding: 0.8rem 1rem;
                border-radius: 0.6rem;
                background-color: rgba(0, 192, 19, 0.15);
                color: #00c013;
                font-weight: 700;
                font-size: 1rem;
                display: grid;
                place-items: center;

                .stat {
                  text-transform: capitalize;
                }
              }

              .status-debit {
                background-color: rgba(222, 11, 11, 0.15);
                color: #de0b0b;
              }
            }

            .text-box {
              display: flex;
              flex-direction: column;
              margin-right: auto;

              & > *:not(:last-child) {
                margin-bottom: 1rem;
              }

              p {
                font-size: 1rem;
                color: #424242;

                span {
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
    }
  }

  .right-box {
    flex: 1;
    position: relative;

    .content-box {
      width: 100%;
      position: absolute;
    //   height: 95%;
      background-color: rgba(69, 93, 95, 0.05);
      border-top-left-radius: 2rem;
      padding: 2.5rem;
      display: flex;
      flex-direction: column;
    }

    .content-title {
      font-size: 1.65rem;
      font-weight: 700;
      color: #424242;
    }

    .see-all-request-box {
      display: flex;
      align-items: center;
      margin-top: 3rem;
      align-self: center;
      color: #424242;
      cursor: pointer;

      &:hover .icon {
        transform: translateX(0.7rem);
      }

      .text {
        font-size: 1.5rem;
        color: #424242;

        span {
          font-weight: 700;
        }
      }

      .icon {
        margin-left: 0.4rem;
        width: 1.5rem;
        height: 1.5rem;
        transition: all 0.4s;
      }
    }

    .detail-wrap-box {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 2.5rem;
      margin-top: 3.5rem;

      .card-box {
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
        border-radius: 1rem;
        padding: 3rem 2rem;
        padding-bottom: 1.5rem;
        color: #424242;

        .btn {
          align-self: center;
          padding: 0.8rem 1.3rem;
          border-radius: 1rem;
          border: none;
          outline: none;
          color: #00c013;
          background-color: rgba(0, 192, 19, 0.15);
          cursor: pointer;
          font-size: 1.3rem;
          margin-top: 2rem;
          transition: all 0.3s;

          &:hover {
            transform: translateY(-0.2rem);
          }
        }

        .date {
          font-size: 1.25rem;
          color: #424242;
          opacity: 0.45;
          margin-bottom: 1.5rem;
        }

        .clinic-name {
          margin-bottom: 1.5rem;
          font-size: 1.3rem;
        }

        .amount {
          font-weight: 700;
          font-size: 1.6rem;
          margin-bottom: 1.4rem;
        }

        .underline {
          width: 100%;
          height: 0.1rem;
          background-color: rgba(66, 66, 66, 0.25);
          margin-bottom: 1.5rem;
        }

        .bank-details {
          display: flex;
          align-items: center;
          font-size: 1.25rem;
          margin-bottom: 0.7rem;

          .bank-num {
            opacity: 0.45;
          }

          .bank-name {
            margin-right: 1rem;
          }
        }

        .small-name {
          opacity: 0.45;
          font-size: 1.25rem;
        }
      }
    }
  }
}
