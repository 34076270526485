.content-box-shop-settings {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
}
.content-box-shop-settings .metrics-box {
  width: 80%;
}
.content-box-shop-settings .metrics-box .title {
  font-weight: 600;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  text-transform: capitalize;
}
.content-box-shop-settings .metrics-box .detail-box {
  position: relative;
  min-height: 15rem;
  border: 0.1rem solid var(--border-color);
  border-radius: 1rem;
  padding: 2rem;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.content-box-shop-settings .metrics-box .detail-box > *:not(:last-child) {
  margin-right: 1rem;
}
.content-box-shop-settings .metrics-box .detail-box .info {
  color: #bbbbbb;
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: 1;
}
.content-box-shop-settings .metrics-box .detail-box .input-btn-box {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
}
.content-box-shop-settings .metrics-box .detail-box .input-btn-box .metrics-input {
  padding: 1rem;
  border: 0.1rem solid var(--dark-yellow-color);
  border-radius: 0.8rem;
}
.content-box-shop-settings .metrics-box .detail-box .input-btn-box .metrics-input:focus {
  outline: none;
  border: 0.1rem solid var(--dark-yellow-color);
}
.content-box-shop-settings .metrics-box .detail-box .input-btn-box .icon {
  color: var(--dark-yellow-color);
  cursor: pointer;
  transform: translateY(0.7rem);
  width: 2rem;
  height: 2rem;
}
.content-box-shop-settings .metrics-box .detail-box .input-btn-box-active {
  padding: 1rem;
  border-radius: 0.6rem;
  box-shadow: var(--box-shadow-one);
  background-color: var(--white-color);
}/*# sourceMappingURL=ShopSetting.css.map */