.success-modal-wrap {
  width: 55rem;
  background-color: var(--white-color);
  border-radius: 1rem;
  padding: 3rem 4rem 7rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.success-modal-wrap .close-icon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 0.1rem solid var(--black-color);
  display: grid;
  place-items: center;
  transition: all 0.3s;
  cursor: pointer;
}
.success-modal-wrap .close-icon:hover {
  transform: scale(1.04);
}
.success-modal-wrap .close-icon .icon {
  width: 1.5rem;
  height: 1.5rem;
}
.success-modal-wrap .img-box {
  width: 25rem;
  height: 20rem;
}
.success-modal-wrap .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
}
.success-modal-wrap .text-one {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}
.success-modal-wrap .text-two {
  color: rgba(66, 66, 66, 0.5);
  font-size: 1.3rem;
  text-align: center;
  margin-bottom: 1.5rem;
}
.success-modal-wrap .btn {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.2rem 3rem;
  margin-top: 2rem;
  border-radius: 0.8rem;
  background-color: #84bfcd;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  color: var(--white-color);
  font-weight: 600;
  font-size: 1.3rem;
}
.success-modal-wrap .btn:hover {
  transform: translateY(-0.15rem);
}/*# sourceMappingURL=SuccessModal.css.map */